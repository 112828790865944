import React, { useEffect, useState } from "react";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import NonTermForm from "../../../Page/Desktop/NonTerm/NonTermForm/NonTermForm";
import MNonTermForm from "../../../Page/Mobile/NonTerm/MNonTermForm/MNonTermForm";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { useNavigate } from "react-router-dom";
import { NonTermSlice } from "../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import {
  calculateAge,
  isEmpty,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import { TNonTermAddForm } from "../../../types/NonTerm/TNonTermAddForm";
import { AddNonTermDTO } from "../../../DTO/NonTermDTO/AddNonTermDTO";
import { UserProfileSlice } from "../../../Store/Slice_Reducer/Account/UserProfileSlice";
import moment from "moment";
import { NON_TERM_SERVICES } from "../../../Services/NonTerm/NonTermFormService";
import { NON_TERM_ROUTES } from "../../../Router/Path/NonTermRoute";

const NonTermFormContainer = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  const isMobile = useIsMobile();
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page_status, setPageStatus] = useState<number>(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [inputValue, setInputValue] = useState(0);
  const { ADD_FORM, DROPDOWN_DATA, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.NonTerm
  );
  const { NonTerm } = useAppSelector((state) => state);
  const [validateInvestAmount, setValidateInvestAmount] = useState();
  const [validateInvestmentAmount, setValidateInvestmentAmount] =
    useState(false);

  const [minInvestmentAmount, setMinInvestmentAmount] = useState(500);
  const [maxInvestmentAmount, setMaxInvestmentAmount] = useState(500000);

  useEffect(() => {
    if (ADD_FORM.investmentMode.value == 12) {
      setMinInvestmentAmount(500);
      setMaxInvestmentAmount(500000);
    } else if (ADD_FORM.investmentMode.value == 4) {
      setMinInvestmentAmount(1500);
      setMaxInvestmentAmount(1500000);
    } else if (ADD_FORM.investmentMode.value == 2) {
      setMinInvestmentAmount(2500);
      setMaxInvestmentAmount(2500000);
    } else {
      setMinInvestmentAmount(5000);
      setMaxInvestmentAmount(5000000);
    }
  }, [ADD_FORM.investmentMode.value]);

  useEffect(() => {
    if (inputValue === 0) {
      setValidateInvestmentAmount(false);
    } else if (inputValue < minInvestmentAmount) {
      setValidateInvestmentAmount(true);
    } else if (inputValue > maxInvestmentAmount) {
      setValidateInvestmentAmount(true);
    } else {
      setValidateInvestmentAmount(false);
    }
  }, [inputValue, minInvestmentAmount, maxInvestmentAmount]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setSliderValue(newValue);
      setInputValue(newValue);
      dispatch(
        NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
          ...ADD_FORM,
          investAmount: {
            value: `${newValue}`,
            warning: isEmpty(newValue),
          },
        })
      );
    }
  };
  const handleInputChange = (event: any) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    dispatch(
      NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
        ...ADD_FORM,
        investAmount: {
          value: newValue,
          warning: isEmpty(newValue),
        },
      })
    );
    // Validate input value (optional)
    if (!isNaN(newValue) && newValue !== "") {
      setSliderValue(parseInt(newValue));
    }
  };

  const updateMasterState = (attrName: string, value: any) => {
    switch (attrName) {
      case "investmentMode":
        dispatch(
          NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
            ...ADD_FORM,
            [attrName]: { value: value, warning: false },
          })
        );
        break;
      case "annualIncome":
        dispatch(
          NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
            ...ADD_FORM,
            [attrName]: { value: value, warning: false },
          })
        );
        break;

      case "lifeStage":
        dispatch(
          NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
            ...ADD_FORM,
            [attrName]: { value: value, warning: false },
          })
        );
        break;
      case "pincode":
        dispatch(
          NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
            ...ADD_FORM,
            [attrName]: { value: value, warning: !validatePincode(value) },
          })
        );
        break;
      case "goals":
        dispatch(
          NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
            ...ADD_FORM,
            [attrName]: { value: value, warning: false },
          })
        );
        break;
      default:
        dispatch(
          NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
            ...ADD_FORM,
            [attrName]: { value: value, warning: false },
          })
        );
        break;
    }
  };

  const validateNonTermAddForm = () => {
    let data: TNonTermAddForm = { ...NonTerm.ADD_FORM };
    data = {
      ...data,
      investAmount: {
        value: inputValue,
        warning: inputValue === 0 ? true : false,
      },
      pincode: {
        ...data.pincode,
        warning: !validatePincode(data.pincode.value),
      },
      investmentMode: {
        ...data.investmentMode,
        warning: isEmpty(data.investmentMode.value),
      },
      annualIncome: {
        ...data.annualIncome,
        warning: isEmpty(data.annualIncome.value),
      },
      lifeStage: {
        ...data.lifeStage,
        warning: isEmpty(data.lifeStage.value),
      },
    };
    dispatch(NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA(data));
    if (
      !data.investAmount.warning &&
      !data.annualIncome.warning &&
      !data.lifeStage.warning &&
      !data.pincode.warning &&
      !data.investmentMode.warning &&
      !validateInvestmentAmount
    ) {
      setPageStatus(1);
    }
  };

  const add_form = (data: TNonTermAddForm) => {
    const onSuccess = (res: any) => {
      dispatch(
        NonTermSlice.actions.BULK_UPDATE_DATA({
          ...NonTerm,
          ADD_FORM: { ...data },
          ADD_FORM_RESPONSE: { ...res?.response },
        })
      );
      if (!res?.error) {
        navigate(`${NON_TERM_ROUTES.QUOTE}?id=${res?.response?.quote_no}`);
      }
      setLoader(false);
      setPageStatus(1);
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    };
    const onError = (err: any) => {
      console.log("err", err);
      setLoader(false);
    };

    // let params: AddNonTermDTO = {
    //   investment_module: "InvestmentPlan",
    //   investment_type: "Investment Plan",
    //   investmentAmount: `${data.investAmount.value}`,
    //   investmentMode: `${data.investmentMode.value}`,
    //   investmentGender: `${data.gender.value}`,
    //   investmentDob: `${data.age.value}`,
    //   investmentName: "",
    //   investmentMobile: `${data.mobile.value}`,
    //   risk_profile: `${data.riskProfile.value}`,
    //   life_stage: `${data.lifeStage.value}`,
    //   annual_income: Number(data.annualIncome.value),
    //   goal: `${data.goals.value}`,
    //   investmentPincode: `${data.pincode.value}`,
    // };

    let params: any = {
      module: "InvestmentPlan",
      insurance_type: "Investment Plan",
      gender: `${data.gender.value}`,
      investmentDOB: `${data.age.value}`,
      pincode: `${data.pincode.value}`,
      goal: `${data.goals.value}`,
      mobile: `${data.mobile.value}`,
      dob: `${data.age.value}`,
      risk_profile: `${data.riskProfile.value}`,
      investmentMode: `${data.investmentMode.value}`,
      investmentAmount: `${data.investAmount.value}`,
      life_stage: `${data.lifeStage.value}`,
      annual_income: Number(data.annualIncome.value),
    };

    setLoader(true);
    NON_TERM_SERVICES.addNonTermFormData(onSuccess, onError, params);
  };

  const validateNonTermForm = () => {
    let data: TNonTermAddForm = { ...NonTerm.ADD_FORM };
    console.log("datatata", !data.riskProfile.warning && !data.goals.warning);
    data = {
      ...data,
      goals: {
        ...data.goals,
        warning: isEmpty(data.goals.value),
      },
      riskProfile: {
        ...data.riskProfile,
        warning: isEmpty(data.riskProfile.value),
      },
    };
    if (!data.riskProfile.warning && !data.goals.warning) {
      add_form(data);
    }
  };

  return (
    <>
      {" "}
      {isMobile ? (
        <MNonTermForm
          open={open}
          setOpen={setOpen}
          loader={loader}
          updateMasterState={updateMasterState}
          validateNonTermAddForm={validateNonTermAddForm}
          validateNonTermForm={validateNonTermForm}
          validateInvestmentAmount={validateInvestmentAmount}
          handleInputChange={handleInputChange}
          handleSliderChange={handleSliderChange}
          inputValue={inputValue}
          sliderValue={sliderValue}
          page_status={page_status}
          setPageStatus={setPageStatus}
          minInvestmentAmount={minInvestmentAmount}
          maxInvestmentAmount={maxInvestmentAmount}
        />
      ) : (
        <NonTermForm
          open={open}
          setOpen={setOpen}
          loader={loader}
          updateMasterState={updateMasterState}
          validateNonTermAddForm={validateNonTermAddForm}
          validateNonTermForm={validateNonTermForm}
          validateInvestmentAmount={validateInvestmentAmount}
          handleInputChange={handleInputChange}
          handleSliderChange={handleSliderChange}
          inputValue={inputValue}
          sliderValue={sliderValue}
          page_status={page_status}
          setPageStatus={setPageStatus}
          minInvestmentAmount={minInvestmentAmount}
          maxInvestmentAmount={maxInvestmentAmount}
        />
      )}{" "}
    </>
  );
};

export default NonTermFormContainer;
