export enum COMMON_ROUTES {
  HOME = "/",
  HEALTH_FORM = "/health-insurance-form",
  CAR_FORM = "/car-insurance-form",
  TW_FORM = "/two-wheeler-form",
  TERM_FORM = "/term-insurance-form",
  PRIVACY_POLICY = "/privacy-policy",
  TERM_AND_CONDTION = "/terms-and-conditions",
  NEED_HELP = "/need-help",
  DISCLAIMER = "/disclaimer",
  REFUND_AND_CANCELLATION = "/refund-and-cancellation",
  ABOUT_US = "/about-us",
  CONTACT_US = "/contact-us",
  FREE_CLAIM_ASSISTANCE = "/free-claim-assistance",
  MCONTACT_US = "/mcontact-us",
  MCOMPLAINT = "/mcomplaint",
  MCAREER = "/mcareer",
  PRODUCT_HEALTH = "/health-insurance",
  LANDING_PRODUCT_CAR = "/landing-page/car-insurance",
  LANDING_PRODUCT_TW = "/landing-page/two-wheeler-insurance",
  PRODUCT_CAR = "/car-insurance",
  PRODUCT_TW = "/two-wheeler-insurance",
  PRODUCT_TERM = "/term-insurance",
  PRODUCT_NON_TERM = "/non-term-insurance",
  CLAIMS = "/claims",
  BLOGS = "/blogs",
  PAYMENT_CONFIRMATION = "/payment-confirmation",
  CONTACT = "/contact-us",
  CAREER = "/career",
  BLOG_FACTORS_TO_BE_CONSD = "/blog/factors-to-be-considered-while-buying-personal-accident-insurance-in-india",
  BLOG_EVERYTHING_YOU_NEED = "/blog/everything-you-need-to-know-about-personal-accident-cover",
  BLOG_ALL_YOU_NEED = "/blog/all-you-need-to-know-about-car-insurance",
  BLOG_WHY_DOES_COMP = "/blog/why-does-a-comprehensive-car-insurance-cost-more-than-a-third-party-liability-car-insurance",
  BLOG_MAKING_UNDERSTANDING = "/blog/making-understanding-of-idv-easy-for-you",
  BLOG_UNDERSTANDING_DEDUCTIBLE = "/blog/understanding-deductibles-and-co-pays",
  BLOG_HEALTH_INSURANCE = "/blog/health-insurance-top-up-plans",
  BLOG_CORPORATE_HEALTH = "/blog/do-you-have-a-corporate-health-insurance-policy-find-out-if-it-is-enough",
  BLOG_ULIP = "/blog/unit-linked-insurance-plan-ulip",
  BLOG_BENEFITS_OF_CAR = "/blog/benefits-of-car-insurance-renewal",
  CAR_QUOTE = "/car-quote",
  TW_QUOTE = "/tw-quote",
  HEALTH_QUOTE = "/health-quote",
  TERM_QUOTE = "/term-quote",
  PLAN_FEATURES_HEALTH = "/health-quote/plan-features",
  PLAN_FEATURES_TERM = "/term-quote/plan-features",
}
