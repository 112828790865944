import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Grid from "@mui/material/Unstable_Grid2";
import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import BackLeft from "../../../../Component/InputFields/BackLeft";
import BorderRadioButton from "../../../../Component/InputFields/BorderRadioButton/BorderRadioButton";
import DatePicker from "../../../../Component/InputFields/DatePicker/DatePicker";
import JDSButtons from "../../../../Component/InputFields/JDSButtons/JDSButtons";
import RadioText from "../../../../Component/InputFields/RadioText/RadioText";
import SearchAutocomplete from "../../../../Component/InputFields/SearchAutocomplete/SearchAutocomplete";
import SearchSelectDropdown from "../../../../Component/InputFields/SearchSelectDropdown/SearchSelectDropdown";
import TextInputField from "../../../../Component/InputFields/TextInputField/TextInputField";
import "../../../../SCSS/ProductForms.scss";
import { CarSlice } from "../../../../Store/Slice_Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  FORMAT_DD_MM_YYYY,
  calculateAgeInDays,
  isEmpty,
  validateMobileNumber,
} from "../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../SupportingFiles/colors";

function MCarForm({
  pageStatus,
  backFunction,
  updateMasterState,
  validateForm,
  vehicleInfoStatus,
  setPageStatus,
  setVehicleInfoStatus,
  open,
  setOpen,
  loader,
}: {
  pageStatus: number;
  backFunction: Function;
  updateMasterState: Function;
  validateForm: Function;
  vehicleInfoStatus: boolean;
  setPageStatus: any;
  setVehicleInfoStatus: any;
  open: boolean;
  setOpen: Function;
  loader: boolean;
}) {
  const dispatch = useAppDispatch();
  const { DROPDOWN_DATA, ADD_FORM, REDIRECTED } = useAppSelector(
    (state) => state.Car
  );
  const new_steps = ["RTO Details", "Car Details", "Additional Details"];
  const [activeStep, setActiveStep] = useState<number>(0);
  const rollover_steps = ["Car Brand", "Car Details", "Additional Details"];
  useEffect(() => {
    if (ADD_FORM.business_type === "New") {
      if (pageStatus === 1) {
        setActiveStep(0);
      } else if (
        pageStatus === 2 ||
        pageStatus === 3 ||
        pageStatus === 4 ||
        pageStatus === 5
      ) {
        setActiveStep(1);
      } else if (pageStatus === 8) {
        setActiveStep(2);
      }
    } else {
      if (pageStatus === 2) {
        setActiveStep(0);
      } else if (pageStatus === 3 || pageStatus === 4 || pageStatus === 5) {
        setActiveStep(1);
      } else if (pageStatus === 6 || pageStatus === 7) {
        setActiveStep(2);
      }
    }
  }, [pageStatus]);

  return (
    <Modal open={open}>
      <Box className="modalFormsMobile">
        <Box className="modalContent">
          <Box className="topbar">
            <Grid container className="row" alignItems="center">
              <Grid xs={12} display={"flex"} gap={"12px"} alignItems={"center"}>
                {pageStatus === 1 ||
                (pageStatus === 2 &&
                  ADD_FORM.business_type !== "New") ? null : (
                  <>
                    <BackLeft onClick={() => backFunction()} />
                    {REDIRECTED ? (
                      <h6>Car Insurance</h6>
                    ) : (
                      <img
                        src="/images/jio_insurance_logo.svg"
                        alt="jio-insurance-logo"
                        height="14px"
                      />
                    )}
                  </>
                )}
                {pageStatus === 2 && ADD_FORM.business_type !== "New" ? (
                  <>
                    <BackLeft
                      onClick={() => {
                        setOpen(false);
                        dispatch(
                          CarSlice.actions.SET_CAR_SLICE({
                            ...CarSlice.getInitialState(),
                            ADD_FORM: {
                              ...CarSlice.getInitialState().ADD_FORM,
                              mobile: ADD_FORM.mobile,
                              reg_no: ADD_FORM.reg_no,
                            },
                            REDIRECTED: REDIRECTED,
                          })
                        );
                      }}
                    />
                    {REDIRECTED ? (
                      <h6>Car Insurance</h6>
                    ) : (
                      <img
                        src="/images/jio_insurance_logo.svg"
                        alt="jio-insurance-logo"
                        height="14px"
                      />
                    )}
                  </>
                ) : null}
                {pageStatus === 1 && ADD_FORM.business_type === "New" ? (
                  <>
                    <BackLeft
                      onClick={() => {
                        setOpen(false);
                        dispatch(
                          CarSlice.actions.SET_CAR_SLICE({
                            ...CarSlice.getInitialState(),
                            ADD_FORM: {
                              ...CarSlice.getInitialState().ADD_FORM,
                              mobile: ADD_FORM.mobile,
                              reg_no: ADD_FORM.reg_no,
                            },
                            REDIRECTED: REDIRECTED,
                          })
                        );
                      }}
                    />
                    {REDIRECTED ? (
                      <h6>Car Insurance</h6>
                    ) : (
                      <img
                        src="/images/jio_insurance_logo.svg"
                        alt="jio-insurance-logo"
                        height="14px"
                      />
                    )}
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Box>

          <Box className="scrollable-area">
            {/* first step (RTO in new case)*/}
            <Grid
              container
              className="row"
              columnSpacing={3}
              marginBottom={"24px"}
              display=""
            >
              <Grid xs={12} className="mform_steps pt-1 pl-0 pr-0">
                <Stepper activeStep={activeStep} alternativeLabel>
                  {ADD_FORM.business_type === "New"
                    ? new_steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))
                    : rollover_steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                </Stepper>
              </Grid>
            </Grid>

            {pageStatus === 1 ? (
              <Grid
                container
                columnSpacing={3}
                rowSpacing={2}
                alignItems="flex-start"
              >
                <Grid xs={12}>
                  <h4 style={{ fontWeight: "900" }} className="mb-6">
                    Select RTO or city for your car
                  </h4>
                  <SearchAutocomplete
                    placeholder="Search RTO or City"
                    value={`${ADD_FORM.rto.value}`}
                    attrName={"rto"}
                    value_update={updateMasterState}
                    options={DROPDOWN_DATA.RTO_LIST}
                    warn_status={ADD_FORM.rto.warning}
                    error_message={"Select RTO"}
                  />
                </Grid>
              </Grid>
            ) : null}

            {pageStatus === 2 ? (
              <>
                {/* Step Search brand */}
                <Grid
                  container
                  columnSpacing={3}
                  rowSpacing={2}
                  alignItems="flex-start"
                >
                  <Grid xs={12}>
                    <h4 style={{ fontWeight: "900" }} className="mb-6">
                      Select your vehicle brand
                    </h4>
                    <SearchAutocomplete
                      placeholder="Search car brand"
                      value={`${ADD_FORM.make.value}`}
                      attrName={"make"}
                      value_update={updateMasterState}
                      options={DROPDOWN_DATA.MAKE_LIST}
                      warn_status={ADD_FORM.make.warning}
                      error_message={"Select Make"}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <p
                      style={{
                        color: COLORS.lightgrey,
                        fontWeight: "bold",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "4px",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#E0E0E0",
                          height: "1px",
                          width: "100%",
                          borderRadius: "4px",
                        }}
                      ></span>
                      <span
                        style={{
                          minWidth: "152px",
                        }}
                      >
                        Popular companies
                      </span>
                      <span
                        style={{
                          backgroundColor: "#E0E0E0",
                          height: "1px",
                          width: "100%",
                          borderRadius: "4px",
                        }}
                      ></span>
                    </p>
                  </Grid>
                  <Grid xs={12}>
                    <ul className="mfgLogo">
                      {DROPDOWN_DATA.POPULAR_MAKE_LIST.map((data, index) => (
                        <li
                          className={`${data.value} ${
                            data.label === ADD_FORM.make.value ? "active" : ""
                          } `}
                          onClick={() => updateMasterState("make", data.label)}
                        >
                          {data.label}
                        </li>
                      ))}
                    </ul>
                  </Grid>
                  {/* <Grid xs={12}>
                      <JDSButtons
                        className="secondaryBtn"
                        text="Others"
                        variant="outlined"
                        onClick={() => {}}
                      />
                    </Grid> */}
                </Grid>
              </>
            ) : pageStatus === 3 ? (
              <>
                {/* Step Search model */}
                <Grid
                  container
                  columnSpacing={3}
                  rowSpacing={2}
                  alignItems="flex-start"
                >
                  <Grid xs={12}>
                    <h4 style={{ fontWeight: "900" }} className="mb-6">
                      Select {ADD_FORM.make.value} model
                    </h4>
                    <SearchAutocomplete
                      placeholder={`Select ${ADD_FORM.make.value} model`}
                      value={`${ADD_FORM.model.value}`}
                      attrName={"model"}
                      value_update={updateMasterState}
                      options={DROPDOWN_DATA.MODEL_LIST}
                      warn_status={ADD_FORM.model.warning}
                      error_message={"Select Model"}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <p
                      style={{
                        color: COLORS.lightgrey,
                        fontWeight: "bold",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "4px",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#E0E0E0",
                          height: "1px",
                          width: "100%",
                          borderRadius: "4px",
                        }}
                      ></span>
                      <span
                        style={{
                          minWidth: "112px",
                        }}
                      >
                        Popular model
                      </span>
                      <span
                        style={{
                          backgroundColor: "#E0E0E0",
                          height: "1px",
                          width: "100%",
                          borderRadius: "4px",
                        }}
                      ></span>
                    </p>
                  </Grid>
                  <Grid
                    xs={12}
                    sx={{
                      // maxHeight: "236px",
                      height: "calc(100vh - 330px)",
                      overflowY: "auto",
                      ".MuiFormGroup-root": {
                        justifyContent: "center",
                        gap: "16px",
                      },
                    }}
                  >
                    <RadioText
                      count="AUTO"
                      orientation="row"
                      variant="large"
                      attrName="model"
                      options={DROPDOWN_DATA.MODEL_LIST.slice(0, 15)}
                      onChange={updateMasterState}
                      value={`${ADD_FORM.model.value}`}
                    />
                    {/* <JDSButtons
                      className="secondaryBtn mt-4"
                      text="Others"
                      variant="outlined"
                      onClick={() => {}}
                    /> */}
                  </Grid>
                </Grid>
              </>
            ) : pageStatus === 4 ? (
              <>
                {/* Step Fuel type */}

                <Grid
                  container
                  columnSpacing={3}
                  rowSpacing={2}
                  alignItems="flex-start"
                >
                  <Grid xs={12}>
                    <h4 style={{ fontWeight: "900" }} className="mb-2">
                      Select {ADD_FORM.model.value} fuel type
                    </h4>
                  </Grid>
                  <Grid
                    xs={12}
                    sx={{
                      ".MuiFormGroup-root": {
                        justifyContent: "center",
                        gap: "16px",
                        ".MuiFormControlLabel-root": {
                          minWidth: "120px",
                        },
                      },
                    }}
                  >
                    <BorderRadioButton
                      count="AUTO"
                      attrName="fuel_type"
                      onChange={updateMasterState}
                      value={ADD_FORM.fuel_type.value}
                      options={DROPDOWN_DATA.FUEL_TYPE_LIST}
                    />
                  </Grid>
                </Grid>
              </>
            ) : pageStatus === 5 ? (
              <>
                {/* Step Search variant */}
                <Grid
                  container
                  columnSpacing={3}
                  rowSpacing={2}
                  alignItems="flex-start"
                >
                  <Grid xs={12}>
                    <h4 style={{ fontWeight: "900" }} className="mb-6">
                      Select {ADD_FORM.model.value} variant
                    </h4>
                    <SearchAutocomplete
                      placeholder={`Select ${ADD_FORM.model.value} Variant`}
                      value={`${ADD_FORM.variant_cc.value}`}
                      attrName={"variant_cc"}
                      value_update={updateMasterState}
                      options={DROPDOWN_DATA.VARIANT_LIST}
                      warn_status={ADD_FORM.variant_cc.warning}
                      error_message={"Select Variant"}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <p
                      style={{
                        color: COLORS.lightgrey,
                        fontWeight: "bold",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "4px",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#E0E0E0",
                          height: "1px",
                          width: "100%",
                          borderRadius: "4px",
                        }}
                      ></span>
                      <span
                        style={{
                          minWidth: "80px",
                        }}
                      >
                        All variant
                      </span>
                      <span
                        style={{
                          backgroundColor: "#E0E0E0",
                          height: "1px",
                          width: "100%",
                          borderRadius: "4px",
                        }}
                      ></span>
                    </p>
                  </Grid>
                  <Grid
                    xs={12}
                    sx={{
                      height: "calc(100vh - 330px)",
                      overflowY: "auto",
                      ".MuiFormGroup-root": {
                        justifyContent: "center",
                        gap: "16px",
                      },
                    }}
                  >
                    <RadioText
                      count="AUTO"
                      orientation="row"
                      variant="large"
                      attrName="variant_cc"
                      options={DROPDOWN_DATA.VARIANT_LIST}
                      onChange={updateMasterState}
                      value={`${ADD_FORM.variant_cc.value}`}
                    />
                  </Grid>
                </Grid>
              </>
            ) : pageStatus === 6 ? (
              <>
                {/* Step Previous insurance details */}
                <Box marginBottom={"24px"}>
                  <Grid
                    container
                    columnSpacing={3}
                    rowSpacing={2}
                    alignItems="flex-start"
                  >
                    <Grid xs={12}>
                      <h4 style={{ fontWeight: "900" }} className="mb-2">
                        Previous insurance details
                      </h4>
                    </Grid>
                    <Grid xs={12} className="mb-3">
                      <DatePicker
                        title={"Reg. Date"}
                        value={ADD_FORM.reg_date.value}
                        attrName={"reg_date"}
                        onChange={updateMasterState}
                        warn_status={ADD_FORM.reg_date.warning}
                        error_message={"Select Reg. Date"}
                        min_date={15 * 12}
                        max_date={3}
                        date_validation_type="MONTHS"
                        default_date={subDays(new Date(), 90)}
                      />
                    </Grid>
                    <Grid xs={12} className="mb-3">
                      <DatePicker
                        title={"Policy Expiry"}
                        value={ADD_FORM.policy_expiry_date.value}
                        attrName={"policy_expiry_date"}
                        onChange={updateMasterState}
                        warn_status={ADD_FORM.policy_expiry_date.warning}
                        error_message={"Select Policy Expiry Date"}
                        date_validation_type="DAYS"
                        min_date={
                          calculateAgeInDays(`${ADD_FORM.reg_date.value}`) - 90
                        }
                        max_date={
                          calculateAgeInDays(
                            FORMAT_DD_MM_YYYY(`${new Date()}`)
                          ) - 60
                        }
                      />
                    </Grid>

                    <Grid xs={12} className="mb-12">
                      <SearchSelectDropdown
                        title="Previous Insurer"
                        value={`${ADD_FORM.previous_insurer.value}`}
                        attrName={"previous_insurer"}
                        value_update={updateMasterState}
                        options={DROPDOWN_DATA.PREVIOUS_INSURER_LIST}
                        warn_status={ADD_FORM.previous_insurer.warning}
                        error_message={"Select Previous Insurer"}
                      />
                    </Grid>
                  </Grid>
                  {/* <Box
                    className="mt-6"
                    sx={{
                      maxWidth: "312px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <JDSButtons
                      className="primaryBtn large"
                      text="Next"
                      variant="text"
                      onClick={validateForm}
                    />
                  </Box> */}
                </Box>
              </>
            ) : pageStatus === 7 ? (
              <>
                {/* Step Claim details */}
                <Grid
                  container
                  columnSpacing={3}
                  rowSpacing={2}
                  alignItems="flex-start"
                >
                  <Grid xs={12}>
                    <h4 style={{ fontWeight: "900" }} className="mb-2">
                      Claim history
                    </h4>
                  </Grid>
                  <Grid
                    xs={12}
                    sx={{
                      ".MuiFormControl-root": {
                        alignItems: "center",
                        ".MuiFormControlLabel-root": {
                          minWidth: "120px",
                        },
                      },
                      ".MuiFormGroup-root": {
                        gap: "16px",
                      },
                    }}
                  >
                    <h5 className="mb-2">
                      Did you make a claim in your existing policy?
                    </h5>
                    <BorderRadioButton
                      count="2"
                      attrName="claimed"
                      onChange={updateMasterState}
                      value={ADD_FORM.claimed}
                      options={[
                        {
                          value: "Yes",
                          label: "Yes",
                        },
                        { value: "No", label: "No" },
                      ]}
                    />
                  </Grid>
                  {ADD_FORM.claimed === "Yes" ? null : (
                    <Grid
                      xs={12}
                      sx={{
                        ".MuiFormGroup-root": {
                          justifyContent: "center",
                          gap: "16px",
                        },
                      }}
                    >
                      <h5 className="my-2">Select your existing NCB</h5>
                      <p
                        className="mb-4"
                        style={{
                          fontSize: "10px",
                          lineHeight: "14px",
                          color: COLORS.lightgrey,
                        }}
                      >
                        NCB is a reward given by insurance companies to
                        policyholders for not making any claims during the
                        policy term. It's a discount on the premium of the next
                        year's policy and serves as an incentive for safe
                        driving and maintaining a claim-free record.
                      </p>
                      <Box
                        sx={{
                          label: { minWidth: "68px" },
                          ".MuiFormGroup-root": {
                            justifyContent: "flex-start",
                          },
                        }}
                      >
                        <RadioText
                          count="AUTO"
                          orientation="row"
                          variant="large"
                          attrName="prev_ncb"
                          options={DROPDOWN_DATA.NCB}
                          onChange={updateMasterState}
                          value={ADD_FORM.prev_ncb}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : pageStatus === 8 ? (
              <>
                {/* Step Contact details */}
                <Grid
                  container
                  columnSpacing={3}
                  rowSpacing={2}
                  alignItems="flex-start"
                >
                  <Grid xs={12}>
                    <h4 style={{ fontWeight: "900" }} className="mb-2">
                      Contact details
                    </h4>
                  </Grid>

                  <Grid
                    xs={12}
                    textAlign="center"
                    sx={{
                      ".MuiFormGroup-root": {
                        justifyContent: "center",
                        gap: "16px",
                      },
                    }}
                  >
                    <Box className="mobileNumber">
                      <Box
                        position="absolute"
                        left="12px"
                        bottom="13px"
                        zIndex="2"
                        fontSize="18px"
                        fontWeight="bold"
                        sx={{ color: COLORS.lightgrey }}
                      >
                        +91
                      </Box>
                      <TextInputField
                        title="Enter mobile number"
                        placeholder="XXXXXXXXXX"
                        validation_type="NUMBER"
                        value={ADD_FORM.mobile.value}
                        attrName={"mobile"}
                        value_update={(a: any, v: any) => {
                          dispatch(
                            CarSlice.actions.UPDATE_FORM_DATA([
                              {
                                key: "mobile",
                                value: {
                                  value: v,
                                  warning: !validateMobileNumber(v),
                                },
                              },
                            ])
                          );
                        }}
                        warn_status={ADD_FORM.mobile.warning}
                        error_message={
                          isEmpty(ADD_FORM.mobile.value)
                            ? "Enter Mobile Number"
                            : "Enter valid mobile number"
                        }
                        max_length={10}
                        disabled={REDIRECTED}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  className="mt-6"
                  sx={{
                    maxWidth: "312px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <JDSButtons
                    loader={loader}
                    className="primaryBtn large"
                    text="Submit"
                    variant="text"
                    onClick={validateForm}
                  />
                </Box>
              </>
            ) : null}
          </Box>

          {pageStatus === 6 ? (
            <Box className="footerbar">
              <JDSButtons
                className="primaryBtn large"
                text="Next"
                variant="text"
                onClick={validateForm}
                fullWidth={true}
              />
            </Box>
          ) : null}
          {pageStatus === 7 ? (
            <Box className="footerbar">
              <JDSButtons
                className="primaryBtn large"
                text="View Quotes"
                variant="text"
                onClick={validateForm}
                loader={loader}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Modal>
  );
}

export default MCarForm;
