import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import BlogCard from "../../../Component/Desktop/Common/BlogCard/BlogCard";
import ContactusPopup from "../../../Component/Desktop/Common/ContactUsPopup/ContactusPopup";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import TestimonialCard from "../../../Component/Desktop/Common/TestimonialCard/TestimonialCard";
import FAQ from "../../../Component/Global/FAQ/FAQ";
import GenderRadio from "../../../Component/Global/GenderRadio/GenderRadio";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../Component/InputFields/DatePicker/DatePicker";
import JDSButtons from "../../../Component/InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../Component/InputFields/TextInputField/TextInputField";
import CarFormContainer from "../../../Container/Car/Form/CarFormContainer";
import HealthFormContainer from "../../../Container/Health/Form/HealthFormContainer";
import NonTermFormContainer from "../../../Container/Non-term/Form/NonTermFormContainer";
import TWFormContainer from "../../../Container/TW/Form/TWFormContainer";
import TermFormContainer from "../../../Container/Term/Form/TermFormContainer";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import { CarSlice } from "../../../Store/Slice_Reducer/Car/CarSlice";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { NonTermSlice } from "../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { TWSlice } from "../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";
import {
  isEmpty,
  validateMobileNumber,
  validatePincode,
  validateRegno,
} from "../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../SupportingFiles/colors";
import { pushDataLayer, sendEventToNativeApp } from "../../../utils/analytics";
import CarPrefilledData from "../Car/CarPrefilledDataPopup/CarPrefilledDataPopup";
import TWPrefilledData from "../TW/Form/TWPrefilledDataPopup";
import styles from "./Home.scss";

function Home({
  loader,
  openCarForm,
  openCarPrefilledData,
  openHealthForm,
  openNonTermForm,
  openTermForm,
  openTWForm,
  openTWPrefilledData,
  validateCarRollover,
  validateHealth,
  validateNonTerm,
  validateTerm,
  validateTWRollover,
  continuePrefilledCar,
  denyPrefilledCar,
  continuePrefilledTW,
  denyPrefilledTW,
  pageStatus,
  openContactusPopup,
  setOpenContactusPopup,
  setOpenCarPrefilledData,
  setOpenHealthForm,
  setOpenNonTermForm,
  setOpenTermForm,
  setOpenTWForm,
  setOpenTWPrefilledData,
  setOpenCarForm,
  setPageStatus,
  handleFormTabsChange,
  activeformTabs,
  headerSliderArray,
  whySectionArray,
  responsive,
  partersLogos,
  testimonialsSlider,
  partnersSlider,
  gifaqs,
  carfaqs,
  bikefaqs,
  healthfaqs,
  termfaqs,
  blogCardsArray,
}: {
  loader: boolean;
  openCarForm: boolean;
  openCarPrefilledData: boolean;
  openHealthForm: boolean;
  openNonTermForm: boolean;
  openTermForm: boolean;
  openTWForm: boolean;
  openTWPrefilledData: boolean;
  validateCarRollover: Function;
  validateHealth: Function;
  validateNonTerm: Function;
  validateTerm: Function;
  validateTWRollover: Function;
  continuePrefilledCar: Function;
  denyPrefilledCar: Function;
  continuePrefilledTW: Function;
  denyPrefilledTW: Function;
  pageStatus: any;
  openContactusPopup: boolean;
  setOpenContactusPopup: Function;
  setOpenCarPrefilledData: Function;
  setOpenHealthForm: Function;
  setOpenNonTermForm: Function;
  setOpenTermForm: Function;
  setOpenTWForm: Function;
  setOpenTWPrefilledData: Function;
  setOpenCarForm: Function;
  setPageStatus: Function;
  handleFormTabsChange: Function;
  activeformTabs: any;
  headerSliderArray: any[];
  whySectionArray: any[];
  responsive: any;
  partersLogos: any;
  testimonialsSlider: any[];
  partnersSlider: any[];
  gifaqs: any[];
  carfaqs: any[];
  bikefaqs: any[];
  healthfaqs: any[];
  termfaqs: any[];
  blogCardsArray: any[];
}) {
  const dispatch = useAppDispatch();
  const { Car, TW, Term, Health, NonTerm } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [settings] = useState({
    className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "200px",
    slidesToShow: 1,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 3000,
    // cssEase: "linear",
    arrows: false,
    dots: true,
  });

  return (
    <>
      <HelmetComponent
        title="Get Car, Bike, Health & Life Insurance with JioInsure"
        description="Get your car, bike, health, or Life insurance online. Comprehensive coverage across India. Book now and get the right quotes! JioInsure - Jio General Insurance"
      />
      <Box className="homeWrapper">
        {openContactusPopup ? (
          <ContactusPopup
            open={openContactusPopup}
            setOpen={setOpenContactusPopup}
          />
        ) : null}
        {openCarForm ? (
          <CarFormContainer
            open={openCarForm}
            setOpen={setOpenCarForm}
            pageStatus={pageStatus}
            setPageStatus={setPageStatus}
          />
        ) : null}
        <TermFormContainer open={openTermForm} setOpen={setOpenTermForm} />
        <CarPrefilledData
          open={openCarPrefilledData}
          setOpen={setOpenCarPrefilledData}
          continuePrefilledCar={continuePrefilledCar}
          denyPrefilledCar={denyPrefilledCar}
        />
        {openTWForm ? (
          <TWFormContainer
            open={openTWForm}
            setOpen={setOpenTWForm}
            pageStatus={pageStatus}
            setPageStatus={setPageStatus}
          />
        ) : null}
        <TWPrefilledData
          open={openTWPrefilledData}
          setOpen={setOpenTWPrefilledData}
          continuePrefilledTW={continuePrefilledTW}
          denyPrefilledTW={denyPrefilledTW}
        />
        {openHealthForm ? (
          <HealthFormContainer
            loader={loader}
            open={openHealthForm}
            setOpen={setOpenHealthForm}
          />
        ) : null}
        <NavBar />

        {/* Hero Banner */}
        <Box
          sx={{ marginTop: "-40px", marginBottom: "64px" }}
          className="desktopBannerSlider"
        >
          <Slider {...settings}>
            {headerSliderArray?.map((data, index) => (
              <Box
                sx={{
                  width: "100%",
                  position: "relative",
                  marginBottom: "12px",
                }}
              >
                <img
                  alt=""
                  src={data.slider_img}
                  width="100%"
                  style={{
                    minHeight: "650px",
                    maxHeight: "62vh",
                    height: "62vh",
                    objectPosition: "center top",
                    objectFit: "cover",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    width: "100%",
                    color: "#fff",
                    transform: "translateY(-50%)",
                    zIndex: "9",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid
                      xs={12}
                      md={9}
                      display="flex"
                      gap="16px"
                      flexDirection="column"
                    >
                      <h5>{data.INS_Type_tag}</h5>
                      <h2 style={{ fontSize: "64px", lineHeight: "64px" }}>
                        {data.tagline}
                      </h2>

                      <JDSButtons
                        text="Know more"
                        variant={"text"}
                        className={"primaryBtn mt-4"}
                        onClick={() => {
                          pushDataLayer(
                            "jioinsure_home_promo",
                            data.INS_Type_tag,
                            `click`,
                            "Insure website"
                          );

                          switch (data.INS_Type_tag) {
                            case "Two-Wheeler Insurance":
                              navigate(COMMON_ROUTES.PRODUCT_TW);
                              break;

                            case "Health Insurance":
                              navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                              break;

                            case "Car Insurance":
                              navigate(COMMON_ROUTES.PRODUCT_CAR);
                              break;

                            case "Term Insurance":
                              navigate(COMMON_ROUTES.PRODUCT_TERM);
                              break;

                            default:
                              break;
                          }
                        }}
                        fullWidth={false}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
        {/* Hero Banner End */}

        <Grid container className="row" columnSpacing={3} paddingTop={"24px"}>
          <Grid xs={12} textAlign={"center"}>
            <h3
              style={{
                fontSize: "40px",
                fontWeight: "900",
                letterSpacing: "1.2px",
                marginBottom: "24px",
              }}
            >
              Smart insurances for a smarter generation
            </h3>
            <h5
              style={{
                fontSize: "24px",
                lineHeight: "32px",
                color: COLORS.lightgrey,
              }}
            >
              I'm looking to insure
            </h5>
          </Grid>
        </Grid>

        <Box className="form_section">
          <Grid container className="row" columnSpacing={3}>
            <Grid
              xs={12}
              textAlign={"center"}
              display={"flex"}
              justifyContent="center"
            >
              <Box className="form_box">
                <Box
                  sx={{
                    borderRadius: "100px",
                    backgroundColor: COLORS.white,
                    padding: "4px",
                    display: "flex",
                    // gap: "4px",
                    justifyContent: "space-between",
                    marginBottom: "24px",
                  }}
                >
                  <Button
                    disableRipple
                    className={
                      activeformTabs === "CAR"
                        ? `tabButton active`
                        : "tabButton"
                    }
                    onClick={() => handleFormTabsChange("CAR")}
                  >
                    Car
                  </Button>
                  <Button
                    sx={{ minWidth: "152px" }}
                    disableRipple
                    className={
                      activeformTabs === "TW" ? `tabButton active` : "tabButton"
                    }
                    onClick={() => handleFormTabsChange("TW")}
                  >
                    Two-wheeler
                  </Button>
                  <Button
                    disableRipple
                    className={
                      activeformTabs === "HEALTH"
                        ? `tabButton active`
                        : "tabButton"
                    }
                    onClick={() => handleFormTabsChange("HEALTH")}
                  >
                    Health
                  </Button>

                  <Button
                    disableRipple
                    className={
                      activeformTabs === "TERM"
                        ? `tabButton active`
                        : "tabButton"
                    }
                    onClick={() => handleFormTabsChange("TERM")}
                  >
                    Term
                  </Button>
                  {process.env.REACT_APP_ENV === "DEV" ? (
                    <Button
                      sx={{ minWidth: "140px" }}
                      disableRipple
                      className={
                        activeformTabs === "INVESTMENT"
                          ? `tabButton active`
                          : "tabButton"
                      }
                      onClick={() => handleFormTabsChange("INVESTMENT")}
                    >
                      Investment
                    </Button>
                  ) : null}
                </Box>

                {activeformTabs === "TW" ? (
                  <>
                    <h4
                      style={{
                        marginBottom: "24px",
                        fontSize: "32px",
                        fontWeight: "900",
                        lineHeight: "32px",
                        textAlign: "left",
                        letterSpacing: "-0.96px",
                      }}
                    >
                      Buy two-wheeler insurance and save upto 80%*
                    </h4>
                    <Box display="flex" flexDirection="column" gap="16px">
                      <Box>
                        <TextInputField
                          title="Enter two-wheeler registration number"
                          class_name=""
                          value={TW.ADD_FORM.reg_no.value}
                          attrName={"reg_no"}
                          value_update={(a: any, v: any) => {
                            dispatch(
                              TWSlice.actions.UPDATE_FORM_DATA([
                                {
                                  key: "reg_no",
                                  value: {
                                    value: v,
                                    warning: !validateRegno(v),
                                  },
                                },
                              ])
                            );
                          }}
                          warn_status={TW.ADD_FORM.reg_no.warning}
                          error_message={
                            !isEmpty(TW.ADD_FORM.reg_no.value)
                              ? "Enter Valid Vehicle number"
                              : "Enter Vehicle number"
                          }
                          placeholder={"Eg: MH00XX0000"}
                          validation_type="ALPHANUMERIC"
                          max_length={11}
                        />
                      </Box>
                      <Box className="mobileNumber">
                        <Box
                          position="absolute"
                          left="12px"
                          bottom="13px"
                          zIndex="2"
                          fontSize="18px"
                          fontWeight="bold"
                          sx={{ color: COLORS.lightgrey }}
                        >
                          +91
                        </Box>
                        <TextInputField
                          title="Enter mobile number"
                          validation_type="NUMBER"
                          placeholder="XXXXXXXXXX"
                          value={TW.ADD_FORM.mobile.value}
                          attrName={"mobile"}
                          value_update={(a: any, v: any) => {
                            dispatch(
                              TWSlice.actions.UPDATE_FORM_DATA([
                                {
                                  key: "mobile",
                                  value: {
                                    value: v,
                                    warning: !validateMobileNumber(v),
                                  },
                                },
                              ])
                            );
                          }}
                          warn_status={TW.ADD_FORM.mobile.warning}
                          error_message={
                            isEmpty(TW.ADD_FORM.mobile.value)
                              ? "Enter Mobile Number"
                              : "Enter valid mobile number"
                          }
                          max_length={10}
                        />
                      </Box>
                      <CustomCheckbox
                        label={
                          <>
                            I agree to{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                              }}
                            >
                              Terms & Conditions
                            </Link>
                            ,{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.PRIVACY_POLICY);
                              }}
                            >
                              Privacy Policy
                            </Link>
                            {` and `}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.DISCLAIMER);
                              }}
                            >
                              Disclaimer
                            </Link>
                          </>
                        }
                        attrName="termsandconditions"
                        value={TW.ADD_FORM.term_cond}
                        defaultChecked={TW.ADD_FORM.term_cond}
                        variant="large"
                        value_update={(attrName: string, value: boolean) => {
                          dispatch(
                            TWSlice.actions.UPDATE_FORM_DATA([
                              {
                                key: "term_cond",
                                value: value,
                              },
                            ])
                          );
                        }}
                      />
                      <Grid container spacing={0}>
                        <Grid xs={6}>
                          <JDSButtons
                            className="secondaryBtn large mr-2"
                            text="Bought a new two-wheeler?"
                            variant="text"
                            onClick={() => {
                              dispatch(
                                TWSlice.actions.BULK_UPDATE({
                                  ...TW,
                                  ADD_FORM: {
                                    ...TWSlice.getInitialState().ADD_FORM,
                                    business_type: "New",
                                  },
                                })
                              );
                              setPageStatus(1);
                              if (TW.ADD_FORM.term_cond) {
                                pushDataLayer(
                                  "jioinsure_home_cards",
                                  "two wheeler insurance",
                                  "Bought a new two-wheeler?",
                                  "Insure website"
                                );
                                const eventProps = {
                                  action: "two wheeler insurance",
                                  click: "Bought a new two-wheeler?",
                                  EntrySource: "Insure website",
                                };
                                sendEventToNativeApp(
                                  "jioinsure_home_cards",
                                  eventProps
                                );
                                setOpenTWForm(true);
                              } else {
                                toast.error(
                                  "Please accept terms and conditions"
                                );
                              }
                            }}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <JDSButtons
                            onClick={() => {
                              if (TW.ADD_FORM.term_cond) {
                                validateTWRollover();
                              } else {
                                toast.error(
                                  "Please accept terms and conditions"
                                );
                              }
                            }}
                            className="primaryBtn large ml-2"
                            text="Get free quotes"
                            variant="text"
                            loader={loader}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : activeformTabs === "CAR" ? (
                  <>
                    <h4
                      style={{
                        marginBottom: "24px",
                        fontSize: "32px",
                        fontWeight: "900",
                        lineHeight: "32px",
                        textAlign: "left",
                        letterSpacing: "-0.96px",
                      }}
                    >
                      Buy car insurance and save up to 70%*
                    </h4>
                    <Box display="flex" flexDirection="column" gap="16px">
                      <TextInputField
                        title="Enter car registration number"
                        value={Car.ADD_FORM.reg_no.value}
                        attrName={"reg_no"}
                        value_update={(a: any, v: any) => {
                          dispatch(
                            CarSlice.actions.UPDATE_FORM_DATA([
                              {
                                key: "reg_no",
                                value: { value: v, warning: !validateRegno(v) },
                              },
                            ])
                          );
                        }}
                        warn_status={Car.ADD_FORM.reg_no.warning}
                        error_message={
                          !isEmpty(Car.ADD_FORM.reg_no.value)
                            ? "Enter Valid Vehicle number"
                            : "Enter Vehicle number"
                        }
                        placeholder={"Eg: MH00XX0000"}
                        validation_type="ALPHANUMERIC"
                        max_length={11}
                      />

                      <Box className="mobileNumber">
                        <Box
                          position="absolute"
                          left="12px"
                          bottom="13px"
                          zIndex="2"
                          fontSize="18px"
                          fontWeight="bold"
                          sx={{ color: COLORS.lightgrey }}
                        >
                          +91
                        </Box>
                        <TextInputField
                          title="Enter mobile number"
                          validation_type="NUMBER"
                          value={Car.ADD_FORM.mobile.value}
                          placeholder="XXXXXXXXXX"
                          attrName={"mobile"}
                          value_update={(a: any, v: any) => {
                            dispatch(
                              CarSlice.actions.UPDATE_FORM_DATA([
                                {
                                  key: "mobile",
                                  value: {
                                    value: v,
                                    warning: !validateMobileNumber(v),
                                  },
                                },
                              ])
                            );
                          }}
                          warn_status={Car.ADD_FORM.mobile.warning}
                          error_message={
                            isEmpty(Car.ADD_FORM.mobile.value)
                              ? "Enter Mobile Number"
                              : "Enter valid mobile number"
                          }
                          max_length={10}
                        />
                      </Box>

                      <CustomCheckbox
                        label={
                          <>
                            I agree to{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                              }}
                            >
                              Terms & Conditions
                            </Link>
                            ,{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.PRIVACY_POLICY);
                              }}
                            >
                              Privacy Policy
                            </Link>
                            {` and `}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.DISCLAIMER);
                              }}
                            >
                              Disclaimer
                            </Link>
                          </>
                        }
                        attrName="termsandconditions"
                        value={Car.ADD_FORM.term_cond}
                        defaultChecked={Car.ADD_FORM.term_cond}
                        variant="large"
                        value_update={(attrName: string, value: boolean) => {
                          dispatch(
                            CarSlice.actions.UPDATE_FORM_DATA([
                              {
                                key: "term_cond",
                                value: value,
                              },
                            ])
                          );
                        }}
                      />

                      <Grid container spacing={0}>
                        <Grid xs={6}>
                          <JDSButtons
                            className="secondaryBtn large mr-2"
                            text="Bought a new car?"
                            variant="text"
                            onClick={() => {
                              dispatch(
                                CarSlice.actions.UPDATE_FORM_DATA([
                                  { key: "business_type", value: "New" },
                                  {
                                    key: "reg_no",
                                    value: { value: "", warning: false },
                                  },
                                  {
                                    key: "mobile",
                                    value: { value: "", warning: false },
                                  },
                                ])
                              );
                              setPageStatus(1);
                              if (Car.ADD_FORM.term_cond) {
                                pushDataLayer(
                                  "jioinsure_home_cards",
                                  "car insurance",
                                  "Bought a new car?",
                                  "Insure website"
                                );
                                const eventProps = {
                                  action: "car insurance",
                                  click: "Bought a new car?",
                                  EntrySource: "Insure website",
                                };
                                sendEventToNativeApp(
                                  "jioinsure_home_cards",
                                  eventProps
                                );
                                setOpenCarForm(true);
                              } else {
                                toast.error(
                                  "Please accept terms and conditions"
                                );
                              }
                            }}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <JDSButtons
                            onClick={() => {
                              if (Car.ADD_FORM.term_cond) {
                                validateCarRollover();
                              } else {
                                toast.error(
                                  "Please accept terms and conditions"
                                );
                              }
                            }}
                            className="primaryBtn large ml-2"
                            text="Get free quotes"
                            variant="text"
                            loader={loader}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : activeformTabs === "HEALTH" ? (
                  <>
                    <h4
                      style={{
                        marginBottom: "24px",
                        fontSize: "32px",
                        fontWeight: "900",
                        lineHeight: "32px",
                        textAlign: "left",
                        letterSpacing: "-0.96px",
                      }}
                    >
                      Healthy choices start here: compare health insurance plans
                    </h4>
                    <Box display="flex" flexDirection="column" gap="16px">
                      <Box>
                        <p
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            marginBottom: "8px",
                          }}
                        >
                          Select your gender
                        </p>
                        <GenderRadio
                          value={Health.ADD_FORM.gender.value}
                          attrName={"gender"}
                          defaultValue={Health.ADD_FORM.gender.value}
                          value_update={(a: any, v: any) => {
                            dispatch(
                              HealthSlice.actions.setAddFormData({
                                ...Health.ADD_FORM,
                                gender: {
                                  value: v,
                                  warning: isEmpty(v),
                                },
                              })
                            );
                          }}
                          warn_status={Health.ADD_FORM.gender.warning}
                        />
                      </Box>
                      <Box className="mobileNumber">
                        <Box
                          position="absolute"
                          left="12px"
                          bottom="13px"
                          zIndex="2"
                          fontSize="18px"
                          fontWeight="bold"
                          sx={{ color: COLORS.lightgrey }}
                        >
                          +91
                        </Box>
                        <TextInputField
                          title="Enter mobile number"
                          placeholder="XXXXXXXXXX"
                          value={Health.ADD_FORM.mobile.value}
                          attrName={"mobile"}
                          value_update={(a: any, v: any) => {
                            dispatch(
                              HealthSlice.actions.setAddFormData({
                                ...Health.ADD_FORM,
                                mobile: {
                                  value: v,
                                  warning: !validateMobileNumber(v),
                                },
                              })
                            );
                          }}
                          validation_type="NUMBER"
                          max_length={10}
                          warn_status={Health.ADD_FORM.mobile.warning}
                          error_message={
                            isEmpty(Health.ADD_FORM.mobile.value)
                              ? "Enter Mobile Number"
                              : "Enter valid mobile number"
                          }
                        />
                      </Box>

                      <TextInputField
                        title="Enter your pincode"
                        placeholder="XXXXXX"
                        value={Health.ADD_FORM.pincode.value}
                        attrName={"pincode"}
                        validation_type="NUMBER"
                        value_update={(a: any, v: any) => {
                          dispatch(
                            HealthSlice.actions.setAddFormData({
                              ...Health.ADD_FORM,
                              pincode: {
                                value: v,
                                warning: !validatePincode(v),
                              },
                            })
                          );
                        }}
                        max_length={6}
                        warn_status={Health.ADD_FORM.pincode.warning}
                        error_message={
                          isEmpty(Health.ADD_FORM.pincode.value)
                            ? "Enter Pincode"
                            : "Enter valid pincode"
                        }
                      />

                      <CustomCheckbox
                        label={
                          <>
                            I agree to{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                              }}
                            >
                              Terms & Conditions
                            </Link>
                            ,{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.PRIVACY_POLICY);
                              }}
                            >
                              Privacy Policy
                            </Link>
                            {` and `}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.DISCLAIMER);
                              }}
                            >
                              Disclaimer
                            </Link>
                          </>
                        }
                        attrName="termsandconditions"
                        variant="large"
                        value={Health?.ADD_FORM?.term_cond?.value}
                        defaultChecked={Health?.ADD_FORM?.term_cond?.value}
                        value_update={(a: any, v: boolean) => {
                          dispatch(
                            HealthSlice.actions.setAddFormData({
                              ...Health.ADD_FORM,
                              term_cond: {
                                value: v,
                                warning: isEmpty(v),
                              },
                            })
                          );
                        }}
                      />

                      <Box width="260px" margin="0px auto">
                        <JDSButtons
                          onClick={() => {
                            if (Health?.ADD_FORM?.term_cond?.value) {
                              validateHealth();
                            } else {
                              toast.error("Please accept terms and conditions");
                            }
                          }}
                          className="primaryBtn large"
                          text="Get Free Quotes"
                          variant="text"
                          // loader="true"
                        />
                      </Box>
                    </Box>
                    {/*
                  <HealthForm
                    open={openHealthForm}
                    setOpen={setOpenHealthForm}
                  /> */}
                  </>
                ) : activeformTabs === "TERM" ? (
                  <>
                    <h4
                      style={{
                        marginBottom: "24px",
                        fontSize: "32px",
                        fontWeight: "900",
                        lineHeight: "32px",
                        textAlign: "left",
                        letterSpacing: "-0.96px",
                      }}
                    >
                      Buy term insurance with up to 20%* discount
                    </h4>
                    <Box display="flex" flexDirection="column" gap="16px">
                      <Box>
                        <p
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            marginBottom: "8px",
                          }}
                        >
                          Select your gender
                        </p>
                        <GenderRadio
                          value={Term.ADD_FORM.gender.value}
                          value_update={(a: string, v: string) => {
                            dispatch(
                              TermSlice.actions.UPDATE_ADD_FORM_DATA({
                                ...Term.ADD_FORM,
                                gender: { value: v, warning: false },
                              })
                            );
                          }}
                        />
                      </Box>
                      <Box className="mobileNumber">
                        <Box
                          position="absolute"
                          left="12px"
                          bottom="13px"
                          zIndex="2"
                          fontSize="18px"
                          fontWeight="bold"
                          sx={{ color: COLORS.lightgrey }}
                        >
                          +91
                        </Box>
                        <TextInputField
                          title="Enter mobile number"
                          placeholder="XXXXXXXXXX"
                          validation_type="NUMBER"
                          value={Term.ADD_FORM.mobile.value}
                          attrName={"mobile"}
                          value_update={(a: any, v: any) => {
                            dispatch(
                              TermSlice.actions.UPDATE_ADD_FORM_DATA({
                                ...Term.ADD_FORM,
                                mobile: {
                                  value: v,
                                  warning: !validateMobileNumber(v),
                                },
                              })
                            );
                          }}
                          warn_status={Term.ADD_FORM.mobile.warning}
                          error_message={
                            isEmpty(Term.ADD_FORM.mobile.value)
                              ? "Enter Mobile Number"
                              : "Enter valid mobile number"
                          }
                          max_length={10}
                        />
                      </Box>
                      <DatePicker
                        title={"Date of Birth"}
                        value={Term.ADD_FORM.age.value}
                        attrName={"age"}
                        onChange={(a: any, v: any) => {
                          dispatch(
                            TermSlice.actions.UPDATE_ADD_FORM_DATA({
                              ...Term.ADD_FORM,
                              age: { value: v, warning: false },
                            })
                          );
                        }}
                        warn_status={Term.ADD_FORM.age.warning}
                        error_message="Select DOB"
                        min_date={65}
                        max_date={18}
                        default_date={subYears(new Date(), 18)}
                      />
                      <CustomCheckbox
                        label={
                          <>
                            I agree to{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                              }}
                            >
                              Terms & Conditions
                            </Link>
                            ,{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.PRIVACY_POLICY);
                              }}
                            >
                              Privacy Policy
                            </Link>
                            {` and `}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                navigate(COMMON_ROUTES.DISCLAIMER);
                              }}
                            >
                              Disclaimer
                            </Link>
                          </>
                        }
                        attrName="termsandconditions"
                        value={true}
                        defaultChecked={true}
                        disabled={true}
                        variant="large"
                      />
                      <Box width="260px" margin="0px auto">
                        <JDSButtons
                          className="primaryBtn large"
                          text="Get Free Quotes"
                          variant="text"
                          onClick={() => {
                            validateTerm();
                          }}
                        />
                      </Box>
                    </Box>
                  </>
                ) : activeformTabs === "INVESTMENT" ? (
                  <>
                    <h4
                      style={{
                        marginBottom: "16px",
                        fontSize: "32px",
                        fontWeight: "900",
                        lineHeight: "32px",
                        textAlign: "left",
                        letterSpacing: "-0.96px",
                      }}
                    >
                      Buy an investment plan
                    </h4>
                    <Box display="flex" flexDirection="column" gap="16px">
                      <Box>
                        <p
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            marginBottom: "8px",
                          }}
                        >
                          Select your gender
                        </p>
                        <GenderRadio
                          value={NonTerm.ADD_FORM.gender.value}
                          value_update={(a: string, v: string) => {
                            dispatch(
                              NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA(
                                {
                                  ...NonTerm.ADD_FORM,
                                  gender: { value: v, warning: false },
                                }
                              )
                            );
                          }}
                        />
                      </Box>
                      <Box className="mobileNumber">
                        <Box
                          position="absolute"
                          left="12px"
                          bottom="13px"
                          zIndex="2"
                          fontSize="18px"
                          fontWeight="bold"
                          sx={{ color: COLORS.lightgrey }}
                        >
                          +91
                        </Box>
                        <TextInputField
                          title="Enter mobile number"
                          placeholder="XXXXXXXXXX"
                          validation_type="NUMBER"
                          value={NonTerm.ADD_FORM.mobile.value}
                          attrName={"mobile"}
                          value_update={(a: any, v: any) => {
                            dispatch(
                              NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA(
                                {
                                  ...NonTerm.ADD_FORM,
                                  mobile: {
                                    value: v,
                                    warning: !validateMobileNumber(v),
                                  },
                                }
                              )
                            );
                          }}
                          warn_status={NonTerm.ADD_FORM.mobile.warning}
                          error_message={
                            isEmpty(NonTerm.ADD_FORM.mobile.value)
                              ? "Enter Mobile Number"
                              : "Enter Valid Mobile Number"
                          }
                          max_length={10}
                        />
                      </Box>
                      <DatePicker
                        title={"Date of Birth"}
                        value={NonTerm.ADD_FORM.age.value}
                        attrName={"age"}
                        onChange={(a: any, v: any) => {
                          dispatch(
                            NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
                              ...NonTerm.ADD_FORM,
                              age: { value: v, warning: false },
                            })
                          );
                        }}
                        warn_status={NonTerm.ADD_FORM.age.warning}
                        error_message="Select DOB"
                        min_date={65}
                        max_date={18}
                        default_date={subYears(new Date(), 18)}
                      />
                      <CustomCheckbox
                        label={
                          <>
                            I agree to{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              href="./pdfs/terms_of_use.pdf"
                              target="_blank"
                            >
                              Terms & Conditions
                            </Link>
                            ,{" "}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              href="./pdfs/privacy_policy.pdf"
                              target="_blank"
                            >
                              Privacy Policy
                            </Link>
                            {` and `}
                            <Link
                              style={{
                                color: COLORS.primary,
                                fontWeight: "bold",
                              }}
                              href="./pdfs/legal_disclaimer.pdf"
                              target="_blank"
                            >
                              Disclaimer
                            </Link>
                          </>
                        }
                        attrName="termsandconditions"
                        value={true}
                        defaultChecked={true}
                        disabled={true}
                        variant="large"
                      />
                      <Box width="260px" margin="0px auto">
                        <JDSButtons
                          className="primaryBtn large"
                          text="Get Free Quotes"
                          variant="text"
                          onClick={() => {
                            validateNonTerm();
                          }}
                        />
                        <NonTermFormContainer
                          open={openNonTermForm}
                          setOpen={setOpenNonTermForm}
                        />
                      </Box>
                    </Box>
                  </>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="why_jio_section"
          sx={{
            background: COLORS.lightMariGold20,
            padding: "64px 0px",
            marginBottom: "64px",
          }}
        >
          <Grid container className="row" columnSpacing={3} alignItems="center">
            <Grid xs={12} textAlign={"center"}>
              <h4
                className="mb-6"
                style={{
                  color: COLORS.lightgrey,
                  fontWeight: 700,
                }}
              >
                The Jio Advantage
              </h4>
              <h2 className="mb-6">Benefits of Jio Insurance</h2>
              <h5
                style={{
                  color: "#595959",
                  maxWidth: "824px",
                  margin: "24px auto 32px",
                }}
              >
                At Jio Insurance Broking, we understand that finding the right
                insurance can be a complex and time-consuming process. That's
                why we've reimagined the insurance-buying experience, creating a
                seamless marketplace where protection meets convenience.
              </h5>
            </Grid>
            <Grid xs={6}>
              <img
                src="../images/jio-advantage.webp"
                width="100%"
                style={{ borderRadius: "24px" }}
                alt="img"
              />
            </Grid>
            <Grid xs={6}>
              <ul className="listView">
                {whySectionArray?.map((data, index) => (
                  <li key={index}>
                    <img src={data.iconUrl} alt="icon" />
                    <Box>
                      <h5
                        className="mb-1 mb-0"
                        style={{
                          color: COLORS.darkgrey,
                          fontWeight: "bold",
                        }}
                      >
                        {data.heading}
                      </h5>
                      <p
                        style={{
                          color: COLORS.lightgrey,
                        }}
                      >
                        {data.desc}
                      </p>
                    </Box>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box className="blogs_section mb-24">
          <Grid
            container
            className="row"
            columnSpacing={3}
            marginBottom={"32px"}
          >
            <Grid xs={12} textAlign={"center"}>
              {/* <h4
              className="mb-6"
              style={{
                color: "#595959",
              }}>
              Resource Hub
            </h4> */}
              <h2>Blogs</h2>
              <h5
                style={{
                  color: "#595959",
                  maxWidth: "824px",
                  margin: "24px auto 0px",
                }}
              >
                At Jio Insurance Broking, we aim to help you make an informed
                decision. Consider following scenarios before selecting an
                insurance policy that suits your needs.
              </h5>
            </Grid>
            <Grid xs={12}>
              <ul>
                {blogCardsArray?.map((data, index: any) => (
                  <BlogCard data={data} />
                ))}
              </ul>
            </Grid>
            <Grid xs={12} display={"flex"} justifyContent={"center"}>
              <Box maxWidth={"384px"} width={"100%"}>
                <JDSButtons
                  text="Read more blogs"
                  variant={"text"}
                  className={"secondaryBtn large"}
                  onClick={() => navigate(COMMON_ROUTES.BLOGS)}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="testimonial_section" marginBottom={"100px"}>
          <Grid container className="row" columnSpacing={3}>
            <Grid xs={12} textAlign={"center"}>
              <h2 style={{ maxWidth: "520px", margin: "0px auto" }}>
                Meet some of our happy customers
              </h2>
              <h5 className="mt-2" style={{ color: COLORS.lightgrey }}>
                Words from our satisfied customers
              </h5>
            </Grid>
          </Grid>
          <Grid container className="row" columnSpacing={0}>
            <Grid xs={12} className="testimonial-carousel">
              <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                // dotListClass={`custom-dot-list-style ${styles.customDotListStyle}`}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {testimonialsSlider.map((data, index) => (
                  <TestimonialCard data={data} />
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        <Box className="partners_section" marginBottom={"100px"}>
          <Grid
            container
            className="row"
            columnSpacing={3}
            marginBottom={"42px"}
          >
            <Grid xs={12} textAlign={"center"}>
              <h2 style={{ color: "#001E2B" }}>Our partners</h2>
            </Grid>
          </Grid>
          <Grid
            container
            className="row"
            columnSpacing={3}
            justifyContent={"center"}
          >
            <Grid xs={12} className="partners-carousel">
              <Carousel
                responsive={partersLogos}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {partnersSlider.map((data, index) => {
                  return (
                    <Box className="partner_box">
                      <img src={data.imgUrl} alt="" />
                    </Box>
                  );
                })}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        <Box className="faq_section" marginBottom={"72px"}>
          <Grid
            container
            className="row"
            columnSpacing={3}
            marginBottom={"32px"}
          >
            <Grid xs={12} textAlign={"center"}>
              <h2 style={{ color: "#001E2B" }}>Frequently asked questions</h2>
            </Grid>
          </Grid>
          <Grid
            container
            className="row"
            columnSpacing={3}
            justifyContent={"center"}
          >
            <Grid xs={12} className="faqTabs">
              <TabContext value={value}>
                <Box>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    className={styles.faqTablist}
                  >
                    <Tab label="General" value="1" />
                    <Tab label="Two-Wheeler" value="2" />
                    <Tab label="Car" value="3" />
                    <Tab label="Health" value="4" />
                    <Tab label="Term" value="5" />
                    {/* <Tab label="Investment" value="6" /> */}
                  </TabList>
                </Box>
                <Box>
                  <TabPanel value="1">
                    <FAQ data={gifaqs} section_name={value} />
                  </TabPanel>
                  <TabPanel value="2">
                    <FAQ data={bikefaqs} section_name={value} />
                  </TabPanel>
                  <TabPanel value="3">
                    <FAQ data={carfaqs} section_name={value} />
                  </TabPanel>
                  <TabPanel value="4">
                    <FAQ data={healthfaqs} section_name={value} />
                  </TabPanel>
                  <TabPanel value="5">
                    <FAQ data={termfaqs} section_name={value} />
                  </TabPanel>
                  <TabPanel value="6">
                    <FAQ data={termfaqs} section_name={value} />
                  </TabPanel>
                </Box>
              </TabContext>
            </Grid>
          </Grid>
        </Box>

        <Box className="career_section mb-16">
          <Grid container className="row" columnSpacing={3}>
            <Grid xs={12}>
              <Box className="career_openingBox">
                <Box flex={"1 0 0"} maxWidth="484px">
                  <img src="./images/work-with-us.jpg" width="100%" />
                </Box>
                <Box flex={"1 0 0"}>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "20px",
                    }}
                  >
                    CAREERS @ JIO
                  </p>
                  <h1 className="mb-5">Work with us</h1>
                  <h5
                    style={{
                      marginBottom: "48px",
                      color: COLORS.lightgrey,
                    }}
                  >
                    Join us in reshaping the insurance landscape and making a
                    difference in people's lives.
                  </h5>
                  <Box width="184px">
                    <JDSButtons
                      text="Apply now"
                      variant={"text"}
                      className={"primaryBtn large"}
                      onClick={() => navigate(COMMON_ROUTES.CAREER)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Footer />
      </Box>
    </>
  );
}

export default Home;
