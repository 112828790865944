import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./../../../../SCSS/Amaze/CommonSCSS/ProposalForms.scss";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import TermRiderQuestion from "../../../../Component/Amaze/Term/TermRiderQuestion/TermRiderQuestion";
import { formatToCurrency } from "../../../../SupportingFiles/HelpingFunction";
import SelectDropdown from "../../../../Component/Amaze/FieldTypes/SelectDropdown/SelectDropdown";
import { NonTermSlice } from "../../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import NonTermFooter from "../../../../Component/Amaze/NonTerm/NonTermFooter/NonTermFooter";

const NonTermRidersPage = ({
  forward,
  tf002Data,
  tf003Data,
  tf004Data,
  loaderTf002,
  loaderTf003,
  loaderTf004,
}: {
  forward: Function;
  tf002Data: any;
  tf003Data: any;
  tf004Data: any;
  loaderTf002: any;
  loaderTf003: any;
  loaderTf004: any;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { RIDERS_BENEFITS, QUOTE_FIELDS_DATA, ADD_FORM, UPDATE_RESPONSE } =
    useAppSelector((state) => state.NonTerm);
  return (
    <Box>
      <h5 className="sectionTitle">Addon Detail</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <TermRiderQuestion
            loader={loaderTf003}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF003?.premium}`}
            main_class="addonQuestion borderBottom"
            icon_class={"hospital"}
            question_name={`Accidental Death`}
            policy_term=""
            desc={
              RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF003
                ?.description
            }
            attrName={"tf003Value"}
            toggle_status={RIDERS_BENEFITS.local_value.tf003Status}
            value_update={(attrName: any, value: any) => {
              if (value) {
                dispatch(
                  NonTermSlice.actions.UPDATE_LOCAL_VALUE_ICICI_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf004Status",
                        value: false,
                      },
                    ],
                  })
                );
              }
              dispatch(
                NonTermSlice.actions.UPDATE_LOCAL_VALUE_ICICI_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf003Status",
                      value: value,
                    },
                  ],
                })
              );
            }}
          />
        </Grid>
      </Grid>

      {UPDATE_RESPONSE.age > 55 ||
      (UPDATE_RESPONSE.age <= 45 && ADD_FORM.investAmount.value > 500000) ||
      ADD_FORM.investAmount.value < 100000 ||
      (UPDATE_RESPONSE.age > 45 && ADD_FORM.investAmount.value > 250000) ||
      ADD_FORM.investAmount.value < 100000 ? null : (
        <Grid container spacing={3}>
          <Grid xs={12}>
            <TermRiderQuestion
              loader={loaderTf004}
              addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF004?.premium}`}
              main_class="addonQuestion borderBottom"
              icon_class={"hospital"}
              question_name={`Waiver of Premium`}
              policy_term=""
              desc={
                RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF004
                  ?.description
              }
              attrName={"tf004Value"}
              toggle_status={RIDERS_BENEFITS.local_value.tf004Status}
              value_update={(attrName: any, value: any) => {
                console.log("EFsdfsd", typeof value);
                if (value) {
                  dispatch(
                    NonTermSlice.actions.UPDATE_LOCAL_VALUE_ICICI_RIDERS_BENEFIT(
                      {
                        updates: [
                          {
                            key: "tf003Status",
                            value: false,
                          },
                          {
                            key: "tf002Status",
                            value: false,
                          },
                        ],
                      }
                    )
                  );
                }
                dispatch(
                  NonTermSlice.actions.UPDATE_LOCAL_VALUE_ICICI_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf004Status",
                        value: value,
                      },
                    ],
                  })
                );
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3}>
        <Grid xs={12}>
          <TermRiderQuestion
            loader={loaderTf002}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF002?.premium}`}
            main_class="addonQuestion borderBottom"
            icon_class={"accident"}
            policy_term=""
            desc={
              RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF002
                ?.description
            }
            question_name={`Accidental Disability`}
            toggle_status={RIDERS_BENEFITS.local_value.tf002Status}
            value_update={(attrName: any, value: any) => {
              if (value) {
                dispatch(
                  NonTermSlice.actions.UPDATE_LOCAL_VALUE_ICICI_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf004Status",
                        value: false,
                      },
                    ],
                  })
                );
              }
              dispatch(
                NonTermSlice.actions.UPDATE_LOCAL_VALUE_ICICI_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf002Status",
                      value: value,
                    },
                  ],
                })
              );
            }}
            attrName={"tf002Value"}
          />
        </Grid>
      </Grid>

      <NonTermFooter
        forward={() => {
          forward();
        }}
      />
    </Box>
  );
};

export default NonTermRidersPage;
