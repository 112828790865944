import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Box, Button, Collapse, Link, Menu, MenuItem } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginContainer from "../../../../Container/MyAccountContainer/LoginContainer/LoginContainer";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";
import { MY_ACCOUNT_ROUTES } from "../../../../Router/Path/MyAccountRoutes";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { UserProfileSlice } from "../../../../Store/Slice_Reducer/Account/UserProfileSlice";
import { COLORS } from "../../../../SupportingFiles/colors";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";
import BackLeft from "../../../InputFields/BackLeft";
import JDSButtons from "../../../InputFields/JDSButtons/JDSButtons";
import "./MNavbar.scss";

const MNavBar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { Car, TW, Health, Term } = useAppSelector((state) => state);
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: "left", open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const [loginPopup, setLoginPopup] = useState(false);
  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);
  const [timer, setTimer] = useState(30);
  useEffect(() => {
    let intervalId: any;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  const [openProductMenu, setOpenProductMenu] = useState(false);
  const [isRedirected, setIsRedirected] = useState<boolean>(false);
  useEffect(() => {
    if (
      Car.REDIRECTED ||
      TW.REDIRECTED ||
      Term.REDIRECTED ||
      Health.REDIRECTED
    ) {
      setIsRedirected(true);
    } else {
      setIsRedirected(false);
    }
  }, [Car.REDIRECTED, TW.REDIRECTED, Term.REDIRECTED, Health.REDIRECTED]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    navigate("/");
  };
  // Check if the current path is the root domain (i.e., home page)
  const isHomePage = window.location.pathname === "/";

  return (
    <Box
      sx={{
        backgroundColor: COLORS.lightMariGold20,
        height: "64px",
        position: "fixed",
        top: "0px",
        width: "100%",
        zIndex: "91",
        display: "flex",
        alignItems: "center",
        padding: "0px 12px",
      }}>
      <Grid container className="row" spacing={3} alignItems="center">
        <Grid xs={8} display={"flex"} alignItems={"center"} gap={"12px"}>
          {isRedirected ? (
            <Box className="topbar">
              <Grid container className="row" alignItems="center">
                <Grid
                  xs={12}
                  display={"flex"}
                  gap={"12px"}
                  alignItems={"center"}>
                  <BackLeft
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  {isRedirected ? (
                    TW.REDIRECTED ? (
                      <h6> Two-Wheeler Insurance</h6>
                    ) : Car.REDIRECTED ? (
                      <h6> Car Insurance</h6>
                    ) : Term.REDIRECTED ? (
                      <h6> Life Insurance</h6>
                    ) : Health.REDIRECTED ? (
                      <h6> Health Insurance</h6>
                    ) : null
                  ) : (
                    <img
                      src="/images/jio_insurance_logo.svg"
                      alt="jio-insurance-logo"
                      height="14px"
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>
              <Link
                sx={{ display: "inline-block", height: "24px" }}
                onClick={toggleDrawer("left", true)}>
                <MenuRoundedIcon style={{ color: "#000000" }} />
              </Link>
              <Link
                sx={{ display: "inline-block", padding: "6px 0px" }}
                onClick={() => {
                  if (window.location.pathname === COMMON_ROUTES.HOME) {
                    window.location.reload();
                  } else {
                    navigate(COMMON_ROUTES.HOME);
                  }
                }}>
                <img
                  src="../images/jio_insurance_logo.svg"
                  alt="Jio Insurance Brokers"
                  height="16px"
                />
              </Link>
            </>
          )}
        </Grid>
        <Grid xs={4} textAlign={"right"} className="loginBtns">
          {isHomePage ? (
            USER_DATA?.mobile?.value ? (
              <>
                <Button
                  className="userIcon"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}>
                  <img src="../images/user-icon.svg" alt="user" />
                </Button>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 4px 12px rgba(0,0,0,0.2))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${USER_DATA.mobile.value}`;
                    }}>
                    Dashboard
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : (
              <JDSButtons
                onClick={() => setLoginPopup(true)}
                className="primaryBtn small"
                text="Login"
                variant="text"
                fullWidth={false}
              />
            )
          ) : null}
        </Grid>
      </Grid>
      <LoginContainer open={loginPopup} setOpen={setLoginPopup} />
      {isRedirected ? null : (
        <SwipeableDrawer
          anchor="left"
          open={state.left}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}>
          <Box className="menuWrapper">
            <Box className="navSec pt-6">
              <img
                src="./images/jio_insurance_logo.svg"
                alt="Jio Insurance Brokers"
                height="16px"
              />
            </Box>
            <Box className="menu-section">
              <ul>
                <li>
                  <Link
                    className="nav-link"
                    onClick={() => navigate(COMMON_ROUTES.HOME)}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ justifyContent: "space-between" }}
                    className="nav-link"
                    onClick={() => setOpenProductMenu(!openProductMenu)}>
                    Products <KeyboardArrowDownIcon sx={{ color: "#9B6817" }} />
                  </Link>
                  <Collapse in={openProductMenu} timeout="auto" unmountOnExit>
                    <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                      Car Insurance
                    </Link>
                    <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                      Two-Wheeler Insurance
                    </Link>
                    <Link
                      onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}>
                      Health Insurance
                    </Link>

                    <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                      Term Insurance
                    </Link>
                    {/* <Link>Non-Term Insurance</Link> */}
                  </Collapse>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    onClick={() => navigate(COMMON_ROUTES.CONTACT)}>
                    Contact
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    onClick={() => navigate(COMMON_ROUTES.CAREER)}>
                    Career
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    onClick={() => navigate(COMMON_ROUTES.BLOGS)}>
                    Blogs
                  </Link>
                </li>
              </ul>
            </Box>
          </Box>
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default MNavBar;
