import { Box, Grid } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import MNonTermNavbar from "../../../../Component/Amaze/NonTerm/MNonTermNavbar/MNonTermNavbar";
import NonTermLeftSidebar from "../../../../Component/Amaze/NonTerm/NonTermLeftSidebar/NonTermLeftSidebar";
import NonTermNavbar from "../../../../Component/Amaze/NonTerm/NonTermNavbar/NonTermNavbar";
import NonTermRidersContainer from "../../../../Container/Non-term/Proposal/HDFC/NonTermRidersContainer";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { NON_TERM_ROUTES } from "../../../Path/NonTermRoute";
import "./../../../../SCSS/Amaze/CommonSCSS/ProposalForms.scss"

const HDFC_Routes = () => {
  return (
    <Routes>
      <Route
        path={NON_TERM_ROUTES.RIDERS_BENEFITS}
        element={<NonTermRidersContainer />}
      />
    </Routes>
  );
};
const HDFCRoutes = () => {
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile ? (
        <Box className="proposalWrapperMobile">
          <MNonTermNavbar />
          <Grid container spacing={0} className="d-non">
            <Grid xs={12} className="proposalContentSection">
              {HDFC_Routes()}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="proposalWrapper">
          <NonTermNavbar />
          <Grid container spacing={3} className="pb-0">
            <NonTermLeftSidebar />
            <Grid xs className="proposalContentSection">
              {HDFC_Routes()}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default HDFCRoutes;


