import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import DatePicker from "../../../../Component/Amaze/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../Component/Amaze/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/Amaze/FieldTypes/SelectDropdown/SelectDropdown";
import MNonTermFooter from "../../../../Component/Amaze/NonTerm/MNonTermFooter/MNonTermFooter";
import { useAppSelector } from "../../../../Store/hooks";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { TNonTermProposerData } from "../../../../types/TNonTermSlice";
const MNonTermProposal = ({
  proposerFields,
  fieldsUpdateState,
  validateForm,
  loader,
}: {
  proposerFields: TNonTermProposerData;
  fieldsUpdateState: Function;
  validateForm: Function;
  loader: boolean;
}) => {
  const { DROPDOWN_DATA, ADD_FORM, QUOTE_LOADER } = useAppSelector(
    (state) => state.NonTerm
  );

  return (
    <Box>
      <h5 className="sectionTitle">Proposer Details</h5>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <h6>Prposer / Insured Details</h6>
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Full Name"}
            validation_type="NAME"
            value={proposerFields.full_name.value}
            attrName={["full_name"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields.full_name.warning}
            error_message={
              isEmpty(proposerFields.full_name.value)
                ? "Enter Full Name"
                : "Enter Valid Full Name"
            }
          />
        </Grid>
        <Grid xs={6}>
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={ADD_FORM.age.value}
            attrName={["dob"]}
            disabled={true}
            value_update={fieldsUpdateState}
            error_message="Enter DOB"
            warn_status={ADD_FORM.age.warning}
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField mb-2"
            title="Gender"
            value={proposerFields.gender.value}
            attrName={["gender"]}
            value_update={fieldsUpdateState}
            data={[
              { value: "M", label: "Male" },
              { value: "F", label: "Female" },
              { value: "O", label: "Other" },
            ]}
            warn_status={proposerFields.gender.warning}
            error_message="Select Gender"
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            disabled
            class_name="inputField mb-2"
            title={"Mobile"}
            value={ADD_FORM.mobile.value}
            attrName={["mobile"]}
            value_update={fieldsUpdateState}
            warn_status={ADD_FORM.mobile.warning}
            max_length={10}
            validation_type="NUMBER"
            error_message={!ADD_FORM.mobile.value ? "Enter mobile number" : ""}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Email"}
            value={proposerFields.email.value}
            attrName={["email"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields.email.warning}
            error_message={
              isEmpty(proposerFields.email.value)
                ? "Enter Email"
                : "Enter Valid Email"
            }
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Pincode"}
            value={ADD_FORM.pincode.value}
            attrName={["pincode"]}
            value_update={fieldsUpdateState}
            warn_status={ADD_FORM.pincode.warning}
            validation_type="NUMBER"
            max_length={12}
            disabled={true}
            error_message={!ADD_FORM.pincode.warning ? "Enter Pincode" : ""}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Emergency Phone"}
            value={proposerFields.emergency_number.value}
            attrName={["emergency_number"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields.emergency_number.warning}
            max_length={10}
            validation_type="NUMBER"
            error_message={
              isEmpty(proposerFields.emergency_number.value)
                ? "Enter Emergency Phone Number "
                : "Enter Valid Emergency Phone Number"
            }
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField mb-2"
            title="Occupation"
            value={proposerFields.occupation.value}
            attrName={["occupation"]}
            value_update={fieldsUpdateState}
            data={DROPDOWN_DATA.OCCUPATION_DATA}
            warn_status={proposerFields.occupation.warning}
            error_message="Select Proposer Occupation"
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField mb-2"
            title="Qualification"
            value={proposerFields?.qualification?.value}
            attrName={["qualification"]}
            value_update={fieldsUpdateState}
            data={DROPDOWN_DATA.QUALIFICATION_DATA}
            warn_status={proposerFields.qualification.warning}
            error_message="Select Proposer Occupation"
          />
        </Grid>
      </Grid>
      <MNonTermFooter loader={QUOTE_LOADER} forward={validateForm} />
    </Box>
  );
};

export default MNonTermProposal;
