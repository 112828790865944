import React, { ReactNode, useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import CommonRoutes from "./Router/Routes/CommonRoutes/CommonRoutes";
import NonTermRoutes from "./Router/Routes/NonTerm/NonTermRoutes";
import { logPageView } from "./utils/analytics";

function App() {
  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      // Prevent the default install prompt
      event.preventDefault();
      console.log("Install prompt disabled");
    };

    // Listen for the beforeinstallprompt event
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Cleanup the event listener
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  return (
    <BrowserRouter>
      <GAListener>
        <CommonRoutes />
        <NonTermRoutes />
      </GAListener>
    </BrowserRouter>
  );
}

interface GAListenerProps {
  children: ReactNode;
}

const GAListener: React.FC<GAListenerProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return <>{children}</>;
};

export default App;
