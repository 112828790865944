import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { blog_data } from "../../../Component/Desktop/Common/BlogArray/BLogArray";
import BlogCard from "../../../Component/Desktop/Common/BlogCard/BlogCard";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import TestimonialCard from "../../../Component/Desktop/Common/TestimonialCard/TestimonialCard";
import FAQ from "../../../Component/Global/FAQ/FAQ";
import JDSButtons from "../../../Component/InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../Component/InputFields/TextInputField/TextInputField";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import styles from "../../../SCSS/CMSPages.module.scss";
import { HOME_SERVICES } from "../../../Services/Home/HomeServices";
import { COLORS } from "../../../SupportingFiles/colors";
import {
  isEmpty,
  validateEmail,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { pushDataLayer } from "../../../utils/analytics";

function NonTermProductPage() {
  type BlogCard = {
    id: string;
    img_url: string;
    blogtitle: string;
    blog_desc: string;
  };
  const [blogCards, setBlogCards] = useState<BlogCard[]>([]);
  useEffect(() => {
    setBlogCards(blog_data);
  }, []);

  const blogCardsArray = blogCards.slice(0, 3);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const defaultShow = 5;
  const [showAll, setShowAll] = useState(false);

  const whySectionArray = [
    {
      iconUrl: "../images/right-cover.svg",
      heading: "Curated selection",
      desc: "For all insurance plans available online",
    },
    {
      iconUrl: "../images/choose-best.svg",
      heading: "Expert guidance",
      desc: "For all insurance plans available online",
    },
    {
      iconUrl: "../images/tax-benefit.svg",
      heading: "Faster claims",
      desc: "Support built in with every policy for help",
    },
    {
      iconUrl: "../images/tax-benefit.svg",
      heading: "Secure & safe",
      desc: "For all insurance plans available online",
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const testimonialsSlider = [
    {
      review:
        "“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. Thank You!”",
      reviewer_name: "Amit Khandewal",
      location: "Delhi",
    },
    {
      review:
        " “Buying car insurance has never been so easy. It just took me 5 minutes to buy the policy. Very easy and quick. Will recommend it to everyone.”",
      reviewer_name: "Dr. Rupa Jha",
      location: "Mumbai",
    },
    {
      review: `“The multiple options from various Insurers under one roof was helpful and gave me the confidence that I am opting for the right product as required. Excellent support provided by your advisor.”`,
      reviewer_name: "Kunal Sharma",
      location: "Banglore",
    },
    {
      review: `“Renewing my Car insurance from Jio Insurance Broking was a great experience. Overall experience was really good and I was able to complete my transaction in just 5 minutes. I recommend everyone to buy from Jio Insurance Broking Limited.”`,
      reviewer_name: "Jamnadas Bhalani",
      location: "Delhi",
    },
    {
      review: `“Buying ICICI Lombard car insurance from Jio Insurance Broking is great. It just took me 5 minutes to buy a policy online. Very easy and user friendly. Will recommend it to everyone.”`,
      reviewer_name: "Vikas Priyadarshi",
      location: "Indore",
    },
    {
      review: `“The team handled my renewal query and process very smoothly. They also convinced me to go for a personal cover policy which I am happy to opt for. They are a good asset to the team.”`,
      reviewer_name: "Kunal Chavda",
      location: "Chandigarh",
    },
    {
      review: `“The online quote system was simple to use with clear options. The additional options are well laid out and explained on the website, so I knew exactly what I could buy and compare to what I want. Hence renewed my two-wheeler policy online without any trouble. Excellent website”`,
      reviewer_name: "Chirag Lukka",
      location: "Delhi",
    },
    {
      review: `“I renewed my health and motor policy on icicilombard.com and it was a seamless experience. On my previous policy, I had registered a claim last month and I was pleasantly surprised with the claim settlement process. It was extremely hassle free and quick.”`,
      reviewer_name: "Abhishek Gupta",
      location: "Mumbai",
    },
    {
      review: `“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. THANKS ICICI LOMBARD”`,
      reviewer_name: "Leena Vaddella",
      location: "Delhi",
    },
  ];
  const partersLogos = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const partnersSlider = [
    {
      imgUrl:
        "../images/insurerlogo/ICICI-Lombard-General-Insurance-Co-Ltd.svg",
    },
    { imgUrl: "./images/insurerlogo/HDFC-ERGO-General-Insurance-Co-Ltd.svg" },
    {
      imgUrl: "./images/insurerlogo/Bajaj-Allianz-General-Insurance-Co-Ltd.svg",
    },
    {
      imgUrl: "./images/insurerlogo/Royal_Sundaram.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Magma-HDI-General-Insurance-Company-Limited.svg",
    },
    {
      imgUrl: "./images/insurerlogo/Tata-AIG-General-Insurance-Co-Ltd.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/HDFC-ERGO-Health-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Religare-Health-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Niva-Max-Bupa-Health-Insurance-Company-Ltd.svg",
    },
    {
      imgUrl: "./images/insurerlogo/aditya_birla_capital.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Star-Health-and-Allied-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Tata-AIA-Life-Insurance-Company-Limited.svg",
    },
    { imgUrl: "./images/insurerlogo/HDFC-Standard-Life-Insurance-Co-Ltd.svg" },
    {
      imgUrl: "./images/insurerlogo/ICICI-Prudential-Life-Insurance-Co-Ltd.svg",
    },
    {
      imgUrl: "./images/insurerlogo/Max-Life-Insurance-Co-Ltd.svg",
    },
  ];
  const howtoBuyData = [
    {
      image: "./images/ic_form_add.svg",
      title: "Fill in your details",
      description:
        "Just fill in your details and get insurance policy premium quotes from top insurers in a click",
    },
    {
      image: "./images/ic_comparison.svg",
      title: "Compare quotes, select insurance",
      description:
        "Select from available quotes that suits your requirements and budget",
    },
    {
      image: "./images/ic_quick_payment.svg",
      title: "Fill in proposal & make the payment",
      description:
        "Complete the fields in the proposal form and continue with the payments",
    },
    {
      image: "./images/ic_sofa.svg",
      title: "Sit back & relax",
      description: "Relax as your policy will reach right away in your inbox!",
    },
  ];

  const faqs = [
    {
      title: "Is there an age limit to buy life insurance policy?",
      desc: "Any person who is 18 years or more can purchase life insurance policy, which has to be as per the conditions of the Indian Contract Act 1872",
    },
    {
      title: "What is premium-paying term?",
      desc: "Premium paying term is the number of years for the insured/policyholder to pay the premium",
    },
    {
      title: "Why should I buy life insurance?",
      desc: "Life Insurance policies act as a safety net for the family. You can get a huge amount of life cover (i.e. the sum assured) at a comparatively low premium",
    },
    {
      title: "What are riders in Life Insurance?",
      desc: "Riders are optional, extra terms that go into effect along with your basic policy, at an additional cost. Simply put, a rider provides additional coverage and added protection against risks.",
    },
    {
      title: "How much Life Insurance coverage is recommended?",
      desc: "The life insurance coverage amount should be enough to support your family financially after you. It is recommended to have life cover of at least ten times of your annual income.",
    },
    {
      title: "Is life insurance mandatory?",
      desc: "No, life Insurance is not mandatory but when you have dependents like children, parents & spouse, then life insurance becomes an integral aspect of your life. It provides you and your family financial protection even after your death.",
    },
    {
      title: "What types of riders are available in Life Insurance?",
      desc: (
        <>
          Accidental death benefit rider
          <br /> Critical illness rider
          <br /> Permanent disability rider
          <br /> Income benefit rider Waiver of premium rider
        </>
      ),
    },
  ];

  type TContactUs = {
    name: { value: string; warn_status: boolean };
    contact: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    lookingForIns: { value: string; warn_status: boolean };
  };
  const [contactformFields, setContactFormFields] = useState<TContactUs>({
    name: { value: "", warn_status: false },
    contact: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    lookingForIns: { value: "", warn_status: false },
  });

  const updateMasterState = (attrName: string, value: string) => {
    setContactFormFields((prev) => ({
      ...prev,
      [attrName]: {
        value: value,
        warn_status:
          attrName === "contact"
            ? !validateMobileNumber(value)
            : attrName === "email"
            ? !validateEmail(value)
            : isEmpty(value),
      },
    }));
  };

  const validate_form = () => {
    let data: TContactUs = { ...contactformFields };

    data = {
      ...data,
      contact: {
        ...data.contact,
        warn_status: !validateMobileNumber(data.contact.value),
      },
      name: {
        ...data.name,
        warn_status: isEmpty(data.name.value),
      },
      email: {
        ...data.email,
        warn_status: !validateEmail(data.email.value),
      },
    };
    setContactFormFields({ ...data });
    let hasError: boolean = false;
    if (
      data.contact.warn_status ||
      data.name.warn_status ||
      data.email.warn_status
    ) {
      hasError = true;
    }
    if (!hasError) {
      pushDataLayer(
        "jioinsure_products_internal",
        "Life Insurance",
        "submit",
        "Insure website"
      );
      UPLOAD_CONTACT_US_DATA(data);
    }
  };

  const UPLOAD_CONTACT_US_DATA = (data: TContactUs) => {
    const toastId = toast.loading("Submitting Data...");
    const onSuccess = (res: any) => {
      setContactFormFields({
        name: { value: "", warn_status: false },
        contact: { value: "", warn_status: false },
        email: { value: "", warn_status: false },
        lookingForIns: { value: "", warn_status: false },
      });
      toast.dismiss(toastId);
      toast.success(
        "Thank you for connecting with us, and we will get in touch with you shortly"
      );
    };
    const onError = (err: any) => {
      toast.error("Failed to submit data!");
    };
    HOME_SERVICES.CONTACT_US(onSuccess, onError, {
      email: data.email.value,
      username: data.name.value,
      number: data.contact.value,
      productName: "Life",
    });
  };

  const [redirected, setRedirected] = useState<boolean>(false);

  return (
    <Box className={`${styles.cmsWrapper} ${styles.sectionSpacing}`}>
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="heroBanner">
        <img
          alt=""
          src="../images/life-hero-banner.webp"
          width="100%"
          className="bgimg"
        />
        <Box className="innerWrapper">
          <Grid container spacing={3} alignItems="center">
            <Grid
              xs={12}
              md={7}
              display="flex"
              gap="16px"
              flexDirection="column"
              sx={{ color: COLORS.white }}
              className="contentBox">
              <h1>Life Insurance</h1>
              <h2>Buy investment plans</h2>
              <Box>
                <p>
                  <img alt="" src="../images/green-tick.svg" width="32px" />
                  Hassle-free buying process
                </p>
                <p>
                  <img alt="" src="../images/green-tick.svg" width="32px" />
                  Superfast claims
                </p>
                <p>
                  <img alt="" src="../images/green-tick.svg" width="32px" />
                  Fully online process
                </p>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                  }}>
                  *Subject to availability
                </p>
              </Box>
            </Grid>
            <Grid xs={12} md={5}>
              <Box className="formBox" gap="16px!important">
                <h3>We'd like to learn more about you!</h3>

                <TextInputField
                  title="Name"
                  placeholder={"Enter Full Name"}
                  value={contactformFields.name.value}
                  attrName={"name"}
                  value_update={updateMasterState}
                  warn_status={contactformFields.name.warn_status}
                  error_message={"Enter Name"}
                  validation_type="NAME"
                />

                <Box className="mobileNumber">
                  <Box
                    position="absolute"
                    left="12px"
                    bottom="13px"
                    zIndex="2"
                    fontSize="18px"
                    fontWeight="bold"
                    sx={{ color: COLORS.lightgrey }}>
                    +91
                  </Box>
                  <TextInputField
                    // disabled={redirected}
                    title="Phone number"
                    placeholder="XXXXXXXXXX"
                    validation_type="NUMBER"
                    value={contactformFields.contact.value}
                    attrName={"contact"}
                    value_update={updateMasterState}
                    warn_status={contactformFields.contact.warn_status}
                    error_message={
                      isEmpty(contactformFields.contact.value)
                        ? "Enter Phone Number"
                        : "Enter valid Phone number"
                    }
                    max_length={10}
                  />
                </Box>

                <TextInputField
                  title="Enter email"
                  placeholder="example@domain.com"
                  value={contactformFields.email.value}
                  attrName={"email"}
                  value_update={updateMasterState}
                  warn_status={contactformFields.email.warn_status}
                  error_message={
                    isEmpty(contactformFields.email.value)
                      ? "Enter email"
                      : "Enter valid email"
                  }
                  max_length={50}
                />

                {/* <SelectDropdown
                    title="Looking for"
                    data={lookingfroInsuranceData}
                    value={contactformFields.lookingForIns.value}
                    attrName={"lookingForIns"}
                    value_update={updateMasterState}
                    warn_status={contactformFields.lookingForIns.warn_status}
                    error_message={"Select Insurance"}
                  /> */}

                <JDSButtons
                  onClick={() => {
                    validate_form();
                  }}
                  className="primaryBtn large"
                  text="Submit"
                  variant="text"
                  // loader={loader}
                  fullWidth={true}
                />
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "300",
                    lineHeight: "14px",
                    color: COLORS.lightgrey,
                    display: "block",
                  }}>
                  By submitting the form, I have read and acknowledge the{" "}
                  <Link
                    href="./pdfs/terms_of_use.pdf"
                    target="_blank"
                    style={{ color: "#000", fontWeight: "600" }}>
                    Terms & Conditions
                  </Link>
                  ,{" "}
                  <Link
                    href="./pdfs/privacy_policy.pdf"
                    target="_blank"
                    style={{ color: "#000", fontWeight: "600" }}>
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="./pdfs/legal_disclaimer.pdf"
                    target="_blank"
                    style={{ color: "#000", fontWeight: "600" }}>
                    Disclaimer
                  </Link>{" "}
                  and agree that Jio Insurance Broking Ltd. may contact me at
                  the email address and/or phone number provided above.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className="why_jio_section">
        <Grid container className="row" columnSpacing={3} alignItems="center">
          <Grid xs={12}>
            <h2>Why Jio Insurance?</h2>
            <h5
              style={{
                color: "#595959",
                maxWidth: "824px",
              }}>
              Choose from a wide range of life coverage plans that offer
              complete financial protection to your loved ones. It covers daily
              expenses, education, also helps pay mortgages, ensuring that your
              family is financially secure even when you are not around.
            </h5>
          </Grid>
          <Grid xs={12} md={6}>
            <img
              src="../images/jio-advantage.webp"
              width="100%"
              style={{ borderRadius: "24px" }}
              alt="img"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <ul className="listView">
              {whySectionArray?.map((data, index) => (
                <li key={index}>
                  <img src={data.iconUrl} alt="icon" />
                  <Box>
                    <h5
                      className="mb-1 mt-0"
                      style={{
                        color: COLORS.darkgrey,
                        fontWeight: "bold",
                      }}>
                      {data.heading}
                    </h5>
                    <p
                      style={{
                        color: COLORS.lightgrey,
                      }}>
                      {data.desc}
                    </p>
                  </Box>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Grid container className="row" rowSpacing={3} columnSpacing={6}>
        <Grid xs={12} md={6} display="flex" flexDirection="column" gap="24px">
          <Box>
            <p
              style={{
                textTransform: "uppercase",
                lineHeight: "16px",
                marginBottom: "4px",
                fontWeight: "bold",
                fontSize: "12px",
              }}>
              Knowledge Hub
            </p>
            <h3>
              What to consider while buying a term insurance policy online?
            </h3>
          </Box>
          <p style={{ color: COLORS.lightgrey, lineHeight: "16px" }}>
            <small>
              There are some factors that you should look out for when
              purchasing car insurance policy online.
            </small>
          </p>
          <ol
            style={{
              paddingLeft: "24px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}>
            <li style={{ fontWeight: "900" }}>
              <h6 style={{ fontWeight: "900" }}>Scope of insurance coverage</h6>
              <p
                style={{
                  color: COLORS.lightgrey,
                  lineHeight: "16px",
                  marginTop: "8px",
                  marginLeft: "-24px",
                }}>
                <small>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  risus erat, volutpat eget interdum vel, rutrum ut arcu.
                  Phasellus at id neque tempor id ut orci.
                </small>
              </p>
            </li>
            <li style={{ fontWeight: "900" }}>
              <h6 style={{ fontWeight: "900" }}>
                Network and cashless garages
              </h6>
              <p
                style={{
                  color: COLORS.lightgrey,
                  lineHeight: "16px",
                  marginTop: "8px",
                  marginLeft: "-24px",
                }}>
                <small>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  risus erat, volutpat eget interdum vel, rutrum ut arcu.
                  Phasellus at id neque tempor id ut orci.
                </small>
              </p>
            </li>
            <li style={{ fontWeight: "900" }}>
              <h6 style={{ fontWeight: "900" }}>
                Add on covers / Rider options
              </h6>
              <p
                style={{
                  color: COLORS.lightgrey,
                  lineHeight: "16px",
                  marginTop: "8px",
                  marginLeft: "-24px",
                }}>
                <small>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  risus erat, volutpat eget interdum vel, rutrum ut arcu.
                  Phasellus at id neque tempor id ut orci.
                </small>
              </p>
            </li>
          </ol>
        </Grid>
        <Grid xs={12} md={6}>
          <h3>How to buy Insurance with us?</h3>
          <ul
            style={{
              display: "flex",
              gap: "24px",
              flexDirection: "column",
              margin: "24px 0px",
            }}>
            {howtoBuyData.map((item, index) => (
              <li
                key={index} // Use index as the key, but ideally, each item should have a unique identifier
                style={{
                  position: "relative",
                  paddingLeft: "56px",
                }}>
                {index === howtoBuyData.length - 1 ? null : (
                  <span
                    style={{
                      backgroundColor: "#B5B5B5",
                      position: "absolute",
                      left: "20px",
                      top: "40px",
                      height: "100%",
                      width: "1px",
                    }}></span>
                )}
                <Box
                  sx={{
                    backgroundColor: COLORS.lightMariGold20,
                    height: "40px",
                    width: "40px",
                    borderRadius: "40px",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    padding: "6px",
                  }}>
                  <img src={item.image} width="100%" alt="icon" />
                </Box>
                <h5>{item.title}</h5>
                <p
                  style={{
                    color: COLORS.lightgrey,
                    marginTop: "8px",
                  }}>
                  {item.description}
                </p>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>

      {/* Blog articles */}
      {redirected ? null : (
        <Box className="blogs_section">
          <Grid
            container
            className="row"
            columnSpacing={3}
            marginBottom={"32px"}>
            <Grid xs={12}>
              {/* <h4
              style={{
                color: "#595959",
              }}
            >
              Resource Hub
            </h4> */}
              <h2>Blogs</h2>
              <h5
                style={{
                  color: "#595959",
                  maxWidth: "824px",
                }}>
                Securing your family's finances in your absence is of paramount
                importance. Ensure to do a thorough comparison before you select
                a certain plan for yourself.
              </h5>
            </Grid>
            <Grid xs={12}>
              <ul>
                {blogCardsArray?.map((data, index: any) => (
                  <BlogCard data={data} />
                ))}
              </ul>
            </Grid>
            <Grid xs={12} display={"flex"} justifyContent={"center"}>
              <Box maxWidth={"384px"} width={"100%"}>
                <JDSButtons
                  text="Read more blogs"
                  variant={"text"}
                  className={"secondaryBtn large"}
                  onClick={() => navigate(COMMON_ROUTES.BLOGS)}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Testimonial */}
      <Box className="testimonial_section">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <h2 style={{ maxWidth: "520px", margin: "0px auto" }}>
              Meet some of our happy customers
            </h2>
            <h5 className="mt-2" style={{ color: COLORS.lightgrey }}>
              Words from our satisfied customers
            </h5>
          </Grid>
        </Grid>
        <Grid container className="row" columnSpacing={0}>
          <Grid xs={12} className="testimonial-carousel">
            <Carousel
              responsive={responsive}
              autoPlay={true}
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              partialVisible={false}
              removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
              containerClass={`custom-carousel-track customCarouselTrack`}
              customTransition="transform 0.5s ease-in-out">
              {testimonialsSlider.map((data, index) => (
                <TestimonialCard data={data} />
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>

      {/* Partners */}
      <Box className="partners_section">
        <Grid container columnSpacing={3} marginBottom={"42px"}>
          <Grid xs={12}>
            <h2 style={{ color: "#001E2B" }}>Our Partners</h2>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} justifyContent={"center"}>
          <Grid xs={12} className="partners-carousel">
            <Carousel
              responsive={partersLogos}
              autoPlay={true}
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              partialVisible={false}
              removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
              containerClass={`custom-carousel-track customCarouselTrack`}
              customTransition="transform 0.5s ease-in-out">
              {partnersSlider.map((data, index) => {
                return (
                  <Box className="partner_box">
                    <img src={data.imgUrl} alt="" />
                  </Box>
                );
              })}
            </Carousel>
          </Grid>
        </Grid>
      </Box>

      {/* faqs */}
      <Grid
        container
        className="row"
        rowSpacing={3}
        columnSpacing={6}
        justifyContent={"center"}>
        <Grid xs={12} className="textCenter">
          <h2>Frequently Asked Questions</h2>
        </Grid>
        <Grid xs={12}>
          <FAQ data={showAll ? faqs : faqs.slice(0, defaultShow)} />
        </Grid>
        <Grid xs={12}>
          <JDSButtons
            onClick={() => setShowAll(!showAll)}
            className="secondaryBtn"
            text={showAll ? "View less" : "View more"}
            variant="outlined"
            fullWidth={false}
            // loader="true"
          />
        </Grid>
      </Grid>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default NonTermProductPage;
