import { Box, Button, Link, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";
import { COLORS } from "../../../../SupportingFiles/colors";
import LoginPopup from "./LoginPopup/LoginPopup";
import "./NavBar.scss";
import {
  pushDataLayer,
  sendEventToNativeApp,
} from "../../../../utils/analytics";
import JDSButtons from "../../../InputFields/JDSButtons/JDSButtons";
import LoginContainer from "../../../../Container/MyAccountContainer/LoginContainer/LoginContainer";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { UserProfileSlice } from "../../../../Store/Slice_Reducer/Account/UserProfileSlice";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";
import { MY_ACCOUNT_ROUTES } from "../../../../Router/Path/MyAccountRoutes";

function NavBar() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [loginPopup, setLoginPopup] = useState(false);
  const [hasShadow, setHasShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setHasShadow(true);
      } else {
        setHasShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const PUSH_DATA_GA = (value: string) => {
    pushDataLayer("jioinsure_home_header", value, `click`, "Insure website");
    const eventProps = {
      action: "blogs",
      click: value,
      EntrySource: "Insure website",
    };
    sendEventToNativeApp("jioinsure_home_header", eventProps);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    navigate("/");
  };

  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);

  return (
    <Box className={`navbar ${hasShadow ? "shadow" : ""}`}>
      {/* <LoginPopup open={loginPopup} setOpen={setLoginPopup} /> */}
      <LoginContainer open={loginPopup} setOpen={setLoginPopup} />
      <Box
        sx={{ background: COLORS.white, height: "60px", paddingTop: "14px" }}
      >
        <Grid container spacing={3}>
          <Grid xs={6}>
            <img alt="" src="/images/jfslogofinal.svg" height="32px" />
          </Grid>
          <Grid xs={6} textAlign="right" display="flex" justifyContent="end">
            {USER_DATA.mobile.value ? (
              <>
                <Button
                  className="user"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <img src="../images/user-icon.svg" alt="user" />
                </Button>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 4px 12px rgba(0,0,0,0.2))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${USER_DATA.mobile.value}`;
                    }}
                  >
                    Dashboard
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : (
              <JDSButtons
                onClick={() => setLoginPopup(true)}
                className="primaryBtn small"
                text="Login"
                variant="text"
                fullWidth={false}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Grid container columnSpacing={3} alignItems="center">
        <Grid xs={4}>
          <Link
            onClick={() => {
              if (window.location.pathname === COMMON_ROUTES.HOME) {
                window.location.reload();
              } else {
                navigate(COMMON_ROUTES.HOME);
              }
            }}
            sx={{
              display: "inline-block",
              padding: "4px 0",
            }}
          >
            <img
              src="../images/jio_insurance_logo.svg"
              alt="jio-insurance-logo"
            />
          </Link>
        </Grid>
        <Grid xs={8} textAlign={"right"}>
          <ul>
            <li className="productLink">
              <Link>Products</Link>
              <ul className="dropdownMenu">
                <li>
                  <Link
                    onClick={() => {
                      pushDataLayer(
                        "jioinsure_home_header",
                        "products",
                        `Car Insurance`,
                        "Insure website"
                      );
                      const eventProps = {
                        action: "products",
                        click: "Car Insurance",
                        EntrySource: "Insure website",
                      };
                      sendEventToNativeApp("jioinsure_home_header", eventProps);
                      navigate(COMMON_ROUTES.PRODUCT_CAR);
                    }}
                  >
                    Car Insurance
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      pushDataLayer(
                        "jioinsure_home_header",
                        "products",
                        `Two-Wheeler Insurance`,
                        "Insure website"
                      );
                      const eventProps = {
                        action: "products",
                        click: "Two-Wheeler Insurance",
                        EntrySource: "Insure website",
                      };
                      sendEventToNativeApp("jioinsure_home_header", eventProps);
                      navigate(COMMON_ROUTES.PRODUCT_TW);
                    }}
                  >
                    Two-Wheeler Insurance
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      pushDataLayer(
                        "jioinsure_home_header",
                        "products",
                        `Health Insurance`,
                        "Insure website"
                      );
                      const eventProps = {
                        action: "products",
                        click: "Health Insurance",
                        EntrySource: "Insure website",
                      };
                      sendEventToNativeApp("jioinsure_home_header", eventProps);
                      navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                    }}
                  >
                    Health Insurance
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      pushDataLayer(
                        "jioinsure_home_header",
                        "products",
                        `Term Insurance`,
                        "Insure website"
                      );
                      const eventProps = {
                        action: "products",
                        click: "Term Insurance",
                        EntrySource: "Insure website",
                      };
                      sendEventToNativeApp("jioinsure_home_header", eventProps);
                      navigate(COMMON_ROUTES.PRODUCT_TERM);
                    }}
                  >
                    Term Insurance
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                onClick={() => {
                  PUSH_DATA_GA("blogs");
                  navigate(COMMON_ROUTES.BLOGS);
                }}
              >
                Blogs
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  PUSH_DATA_GA("about us");
                  navigate(COMMON_ROUTES.ABOUT_US);
                }}
              >
                About us
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  PUSH_DATA_GA("contact");
                  navigate(COMMON_ROUTES.CONTACT);
                }}
              >
                Contact
              </Link>
            </li>
          </ul>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NavBar;
