import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { toast } from "react-toastify";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import JDSButtons from "../../../Component/InputFields/JDSButtons/JDSButtons";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import TextInputField from "../../../Component/InputFields/TextInputField/TextInputField";
import styles from "../../../SCSS/CMSPages.module.scss";
import { HOME_SERVICES } from "../../../Services/Home/HomeServices";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";
import {
  isEmpty,
  validateEmail,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../SupportingFiles/colors";
import { Navigate, useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import { pushDataLayer, sendEventToNativeApp } from "../../../utils/analytics";
import SearchSelectDropdown from "../../../Component/InputFields/SearchSelectDropdown/SearchSelectDropdown";

function Contact() {
  const navigate = useNavigate();

  type TContactUs = {
    name: { value: string; warn_status: boolean };
    contact: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    lookingForIns: { value: string; warn_status: boolean };
  };
  const [contactformFields, setContactFormFields] = useState<TContactUs>({
    name: { value: "", warn_status: false },
    contact: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    lookingForIns: { value: "", warn_status: false },
  });
  const lookingfroInsuranceData = [
    {
      label: "Health Insurance",
      value: "Health",
    },
    {
      label: "Car Insurance",
      value: "Car",
    },
    {
      label: "Two-Wheeler Insurance",
      value: "Two Wheeler",
    },
    {
      label: "Life Insurance",
      value: "Life",
    },
  ];

  const updateMasterState = (attrName: string, value: string) => {
    setContactFormFields((prev: any) => ({
      ...prev,
      [attrName]: {
        value: value,
        warn_status:
          attrName === "contact"
            ? !validateMobileNumber(value)
            : attrName === "email"
            ? !validateEmail(value)
            : isEmpty(value),
      },
    }));
  };

  const validate_form = () => {
    let data: TContactUs = { ...contactformFields };

    data = {
      ...data,
      contact: {
        ...data.contact,
        warn_status: !validateMobileNumber(data.contact.value),
      },
      email: {
        ...data.email,
        warn_status: !validateEmail(data.email.value),
      },
      lookingForIns: {
        ...data.lookingForIns,
        warn_status: isEmpty(data.lookingForIns.value),
      },
      name: {
        ...data.name,
        warn_status: isEmpty(data.name.value),
      },
    };
    setContactFormFields({ ...data });
    let hasError: boolean = false;
    if (
      data.contact.warn_status ||
      data.email.warn_status ||
      data.lookingForIns.warn_status ||
      data.name.warn_status
    ) {
      hasError = true;
    }
    if (!hasError) {
      UPLOAD_CONTACT_US_DATA(data);
    }
  };

  const UPLOAD_CONTACT_US_DATA = (data: TContactUs) => {
    const toastId = toast.loading("Submitting Data...");
    const onSuccess = (res: any) => {
      setContactFormFields({
        name: { value: "", warn_status: false },
        contact: { value: "", warn_status: false },
        email: { value: "", warn_status: false },
        lookingForIns: { value: "", warn_status: false },
      });
      toast.dismiss(toastId);
      toast.success(
        "Thank you for connecting with us, and we will get in touch with you shortly"
      );
    };
    const onError = (err: any) => {
      toast.error("Failed to submit data!");
    };

    pushDataLayer(
      "jioinsure_home_header",
      "contact",
      "submit",
      "Insure website"
    );
    const eventProps = {
      action: "contact",
      click: "submit",
      EntrySource: "Insure website",
    };
    sendEventToNativeApp("Jioinsure_flow", eventProps);
    HOME_SERVICES.CONTACT_US(onSuccess, onError, {
      email: data.email.value,
      username: data.name.value,
      number: data.contact.value,
      productName: data.lookingForIns.value,
    });
  };
  return (
    <Box className={styles.cmsWrapper}>
      <HelmetComponent
        title="Contact details and Customer care details | JioInsure"
        description="Get the contact details, customer care details in case you require any assistance. You can write to us at customer.care@jioinsure.in for any help"
      />
      <NavBar />

      <Box className="heroBanner">
        <img
          alt=""
          src="../images/contact-banner.webp"
          width="100%"
          className="bgimg"
          style={{ minHeight: "700px" }}
        />
        <Box className="innerWrapper">
          <Grid container spacing={2} alignItems="center">
            <Grid
              xs={12}
              md={7}
              display="flex"
              gap="16px"
              flexDirection="column"
              sx={{ color: COLORS.white }}
              className="contentBox"
            >
              <h2>Connect with us for expert assistance and support!</h2>
            </Grid>
            <Grid xs={12} md={5}>
              <Box className="formBox">
                <h3>Submit your details, we will reach out shortly.</h3>
                <TextInputField
                  title="Enter your name"
                  value={contactformFields.name.value}
                  attrName={"name"}
                  value_update={updateMasterState}
                  warn_status={contactformFields.name.warn_status}
                  error_message={"Enter Name"}
                  validation_type="NAME"
                  placeholder="Eg: John Smith"
                />
                <TextInputField
                  title="Enter your email ID"
                  value={contactformFields.email.value}
                  attrName={"email"}
                  value_update={updateMasterState}
                  warn_status={contactformFields.email.warn_status}
                  error_message={
                    isEmpty(contactformFields.email.value)
                      ? "Enter email"
                      : "Enter valid email"
                  }
                  max_length={50}
                  placeholder="Eg: john.smith@email.com"
                />
                <TextInputField
                  title="Enter your mobile number"
                  value={contactformFields.contact.value}
                  attrName={"contact"}
                  value_update={updateMasterState}
                  warn_status={contactformFields.contact.warn_status}
                  error_message={
                    isEmpty(contactformFields.contact.value)
                      ? "Enter Contact"
                      : "Enter valid contact"
                  }
                  max_length={10}
                  validation_type="NUMBER"
                  placeholder="Eg: 7788778877"
                />
                <SearchSelectDropdown
                  title="Choose the subject"
                  options={lookingfroInsuranceData}
                  value={contactformFields.lookingForIns.value}
                  attrName={"lookingForIns"}
                  value_update={updateMasterState}
                  warn_status={contactformFields.lookingForIns.warn_status}
                  error_message={"Select Insurance"}
                />
                <CustomCheckbox
                  label={
                    <>
                      I agree to{" "}
                      <Link
                        style={{ color: COLORS.darkgold }}
                        onClick={() => {
                          navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                        }}
                        target="_blank"
                      >
                        Terms & Conditions
                      </Link>
                    </>
                  }
                  attrName="termsandconditions"
                  value={true}
                  defaultChecked={false}
                  disabled={false}
                  variant="large"
                />
                <JDSButtons
                  text={"Submit"}
                  variant={"text"}
                  className={"primaryBtn large"}
                  onClick={() => {
                    validate_form();
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ background: COLORS.lightMariGold20 }} padding={"64px 0px"}>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <h2 style={{ marginBottom: "48px" }}>Other contact points.</h2>
          </Grid>
          <Grid xs={12}>
            <ul style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
              {/* <li style={{ width: `calc(25% - 12px)`,}}>
                <Box
                  sx={{
                    background: COLORS.white,
                    padding: "24px",
                    borderRadius: "24px",
                    height: "100%",
                  }}
                >
                  <img
                    src="./images/ic_contact_us.svg"
                    height="40px"
                    alt="icon"
                    width={"auto"}
                  />
                  <h3
                    style={{
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: "bold",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    Helpdesk
                  </h3>
                  <h6 style={{ color: COLORS.lightgrey }}>090 99887 6654</h6>
                </Box>
              </li> */}
              <li style={{ width: `calc(33.3% - 12px)` }}>
                <Box
                  sx={{
                    background: COLORS.white,
                    padding: "24px",
                    borderRadius: "24px",
                    height: "100%",
                  }}
                >
                  <img
                    src="./images/ic_mail.svg"
                    height="40px"
                    alt="icon"
                    width={"auto"}
                  />
                  <h3
                    style={{
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: "bold",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    Email
                  </h3>
                  <Link
                    sx={{
                      color: COLORS.lightgrey,
                      fontSize: "16px",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                    href="mailto:customer.care@jioinsure.in"
                  >
                    customer.care@jioinsure.in
                  </Link>
                </Box>
              </li>
              <li style={{ width: `calc(33.3% - 12px)` }}>
                <Box
                  sx={{
                    background: COLORS.white,
                    padding: "24px",
                    borderRadius: "24px",
                    height: "100%",
                  }}
                >
                  <img
                    src="./images/ic_mail.svg"
                    height="40px"
                    alt="icon"
                    width={"auto"}
                  />
                  <h3
                    style={{
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: "bold",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    Careers
                  </h3>
                  <Link
                    sx={{
                      color: COLORS.lightgrey,
                      fontSize: "16px",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                    href="mailto:human.resource@jioinsure.in "
                  >
                    human.resource@jioinsure.in
                  </Link>
                </Box>
              </li>
              <li style={{ width: `calc(33.3% - 12px)` }}>
                <Box
                  sx={{
                    background: COLORS.white,
                    padding: "24px",
                    borderRadius: "24px",
                    height: "100%",
                  }}
                >
                  <img
                    src="./images/ic_location.svg"
                    height="40px"
                    alt="icon"
                    width={"auto"}
                  />
                  <h3
                    style={{
                      fontSize: "24px",
                      lineHeight: "28px",
                      fontWeight: "bold",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    Office Address
                  </h3>
                  <h6 style={{ color: COLORS.lightgrey }}>
                    1<sup>st</sup> Floor B Wing, Building 25, Dhirubhai Ambani
                    Knowledge City, MIDC, Kopar Khairane, Navi Mumbai - 400710,
                    India.
                  </h6>
                </Box>
              </li>
            </ul>
          </Grid>
          {/* <Grid xs={8}>
          <Box
            sx={{
              backgroundColor: COLORS.white,
              borderRadius: "24px",
              boxShadow: "0px 4px 16px rgba(0,0,0,0.08)",
              padding: "24px",
              marginTop: "60px",
            }}
          >
            <Grid container className="row" spacing={3} alignItems="center">
              <Grid xs={6} md={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="12px"
                  alignItems="center"
                >
                  <img
                    src="./images/ic_contact_us.svg"
                    height="30px"
                    alt="icon"
                  />
                  <h5 style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Helpdesk
                  </h5>
                  <h6 style={{ color: COLORS.lightgrey }}>090 99887 6654</h6>
                </Box>
              </Grid>
              <Grid xs={6} md={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="12px"
                  alignItems="center"
                >
                  <img src="./images/ic_mail.svg" height="30px" alt="icon" />
                  <h5 style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Email
                  </h5>
                  <Link
                    sx={{
                      color: COLORS.lightgrey,
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                    href="mailto:customer.care@jioinsure.in"
                  >
                    customer.care@jioinsure.in
                  </Link>
                </Box>
              </Grid>
              <Grid xs={6} md={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="12px"
                  alignItems="center"
                >
                  <img src="./images/ic_mail.svg" height="30px" alt="icon" />
                  <h5 style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Careers
                  </h5>
                  <Link
                    sx={{
                      color: COLORS.lightgrey,
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                    href="mailto:human.resource@jioinsure.in "
                  >
                    human.resource@jioinsure.in
                  </Link>
                </Box>
              </Grid>
              <Grid xs={6} md={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="12px"
                  alignItems="center"
                >
                  <img
                    src="./images/ic_location.svg"
                    height="30px"
                    alt="icon"
                  />
                  <h5 style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Office Address
                  </h5>
                  <h6 style={{ color: COLORS.lightgrey, textAlign: "center" }}>
                    1st Floor B Wing, Building 25, Dhirubhai Ambani Knowledge
                    City, MIDC, Kopar Khairane, Navi Mumbai - 400710, India.
                  </h6>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={4}>
          <Box
            sx={{
              backgroundColor: COLORS.lightMariGold30,
              padding: "24px",
              borderRadius: "48px",
            }}
          >
            <Box
              sx={{
                backgroundColor: COLORS.white,
                borderRadius: "24px",
                padding: "16px",
              }}
            >
              <Grid container spacing={2}>
                <Grid xs={12} marginBottom={"8px"}>
                  <TextInputField
                    title="Name"
                    value={contactformFields.name.value}
                    attrName={"name"}
                    value_update={updateMasterState}
                    warn_status={contactformFields.name.warn_status}
                    error_message={"Enter Name"}
                    validation_type="NAME"
                  />
                </Grid>
                <Grid xs={12} marginBottom={"8px"}>
                  <TextInputField
                    title="Email"
                    value={contactformFields.email.value}
                    attrName={"email"}
                    value_update={updateMasterState}
                    warn_status={contactformFields.email.warn_status}
                    error_message={
                      isEmpty(contactformFields.email.value)
                        ? "Enter email"
                        : "Enter valid email"
                    }
                    max_length={50}
                  />
                </Grid>
                <Grid xs={12} marginBottom={"16px"}>
                  <Box className="mobileNumber">
                    <Box
                      sx={{
                        fontSize: "16px",
                        color: COLORS.lightgrey,
                        position: "relative",
                        padding: "21px 8px 12px 0px",
                      }}
                    >
                      +91
                    </Box>
                    <TextInputField
                      title="Mobile Number"
                      value={contactformFields.contact.value}
                      attrName={"contact"}
                      value_update={updateMasterState}
                      warn_status={contactformFields.contact.warn_status}
                      error_message={
                        isEmpty(contactformFields.contact.value)
                          ? "Enter Contact"
                          : "Enter valid contact"
                      }
                      max_length={10}
                      validation_type="NUMBER"
                    />
                  </Box>
                </Grid>
                <Grid xs={12} marginBottom={"8px"}>
                  <SelectDropdown
                    title="Looking for"
                    data={lookingfroInsuranceData}
                    value={contactformFields.lookingForIns.value}
                    attrName={"lookingForIns"}
                    value_update={updateMasterState}
                    warn_status={contactformFields.lookingForIns.warn_status}
                    error_message={"Select Insurance"}
                  />
                </Grid>
                <Grid xs={12}>
                  <CustomCheckbox
                    label={
                      <span style={{ fontSize: "14px" }}>
                        I agree to{" "}
                        <Link
                          style={{ color: COLORS.darkgold }}
                          href="./pdfs/terms_of_use.pdf"
                          target="_blank"
                        >
                          Terms and Conditions
                        </Link>
                      </span>
                    }
                    attrName="termsandconditions"
                    value={true}
                    defaultChecked={true}
                    disabled={true}
                  />
                </Grid>
                <Grid xs={12} className="mt-2">
                  <JDSButtons
                    text={"Submit"}
                    variant={"text"}
                    className={"primaryBtn large"}
                    onClick={() => {
                      validate_form();
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid> */}
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
}

export default Contact;
