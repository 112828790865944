import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import AddonCard from "../../../Component/Desktop/Common/AddonCard/AddonCard";
import AddonPopup from "../../../Component/Desktop/Common/AddonPopup/AddonPopup";
import BlogCard from "../../../Component/Desktop/Common/BlogCard/BlogCard";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import TestimonialCard from "../../../Component/Desktop/Common/TestimonialCard/TestimonialCard";
import FAQ from "../../../Component/Global/FAQ/FAQ";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import JDSButtons from "../../../Component/InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../Component/InputFields/TextInputField/TextInputField";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import CarFormContainer from "../../../Container/Car/Form/CarFormContainer";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import styles from "../../../SCSS/CMSPages.module.scss";
import { CAR_ADD_FORM_SERVICES } from "../../../Services/Car/CarAddFormServices";
import { CarSlice } from "../../../Store/Slice_Reducer/Car/CarSlice";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { TWSlice } from "../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";
import {
  FORMAT_DD_MM_YYYY,
  isEmpty,
  validateMobileNumber,
  validateRegno,
} from "../../../SupportingFiles/HelpingFunction";
import { verifyJwt } from "../../../SupportingFiles/JWTDecoder";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { changeHeaderFooterVisibility } from "../../../SupportingFiles/SendEventToApp";
import { COLORS } from "../../../SupportingFiles/colors";
import { TCarAddForm } from "../../../types/TCarSlice";
import MCarPreFilledData from "../../Mobile/Car/MCarPrefilledDataPopup/MCarPrefilledDataPopup";
import CarPrefilledData from "../Car/CarPrefilledDataPopup/CarPrefilledDataPopup";
import "./../../../SCSS/ProductForms.scss";
import { pushDataLayer, sendEventToNativeApp } from "../../../utils/analytics";
import { blog_data } from "../../../Component/Desktop/Common/BlogArray/BLogArray";

function CarProductPage() {
  type BlogCard = {
    id: string;
    img_url: string;
    blogtitle: string;
    blog_desc: string;
  };
  const [blogCards, setBlogCards] = useState<BlogCard[]>([]);
  useEffect(() => {
    setBlogCards(blog_data);
  }, []);

  const blogCardsArray = blogCards.slice(0, 3);
  const { REDIRECTED } = useAppSelector((state) => state.Car);

  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const defaultShow = 5;
  const [showAll, setShowAll] = useState(false);
  const [openAddonPopup, setOpenAddonPopup] = useState(false);
  const [dataAddonPopup, setDataAddonPopup] = useState();
  const whySectionArray = [
    {
      iconUrl: "../images/list-icon.svg",
      heading: "Choose from the best",
      desc: "Get options to select best motor insurance from the top insurance companies.",
    },
    {
      iconUrl: "../images/max-icon.svg",
      heading: "Maximum Insured Declared Value",
      desc: "Get options to select best motor insurance from the top insurance companies.",
    },
    {
      iconUrl: "../images/addon-icon.svg",
      heading: "Add-ons",
      desc: "Customise your motor insurance policy with a wide range of add-on covers.",
    },
    {
      iconUrl: "../images/instant-icon.svg",
      heading: "Instant Policy Issuance",
      desc: "Get your policy copy in just 4 simple steps.",
    },
  ];
  const chooseInsuranceArray = [
    {
      iconUrl: "../images/choose-plan.svg",
      heading: "Choosing the type of plan",
      desc: "Assess your requirements and needs for auto insurance before selecting a plan. While only third-party coverage is mandatory, it's advisable to opt for a comprehensive policy that offers all-around protection.",
    },
    {
      iconUrl: "../images/various-policy.svg",
      heading: "Evaluating various policies",
      desc: "Research and compare policies to determine the one that best suits your needs and budget. Avoid choosing a policy solely based on price. Opt for the one that offers the most features at a reasonable cost.",
    },
    {
      iconUrl: "../images/covers-icon.svg",
      heading: "Requesting add-On covers",
      desc: "Add-on covers provide additional protection for your vehicle beyond standard insurance coverage. By paying an extra premium, you can purchase these add-ons. They are typically available with comprehensive auto insurance.",
    },
    {
      iconUrl: "../images/analyse-idv.svg",
      heading: "Analysing premiums and IDV (Insured declared value)",
      desc: "Consider both the IDV and premiums. IDV represents the car's current market value and the maximum amount the insurance will pay out in case of theft, damage, or repair. While higher IDV extends coverage, it also increases costs. Make an informed decision based on your needs.",
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const testimonialsSlider = [
    {
      review:
        "“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. Thank You!”",
      reviewer_name: "Amit Khandewal",
      location: "Delhi",
    },
    {
      review:
        " “Buying car insurance has never been so easy. It just took me 5 minutes to buy the policy. Very easy and quick. Will recommend it to everyone.”",
      reviewer_name: "Dr. Rupa Jha",
      location: "Mumbai",
    },
    {
      review: `“The multiple options from various Insurers under one roof was helpful and gave me the confidence that I am opting for the right product as required. Excellent support provided by your advisor.”`,
      reviewer_name: "Kunal Sharma",
      location: "Banglore",
    },
    {
      review: `“Renewing my Car insurance from Jio Insurance Broking was a great experience. Overall experience was really good and I was able to complete my transaction in just 5 minutes. I recommend everyone to buy from Jio Insurance Broking Limited.”`,
      reviewer_name: "Jamnadas Bhalani",
      location: "Delhi",
    },
    {
      review: `“Buying ICICI Lombard car insurance from Jio Insurance Broking is great. It just took me 5 minutes to buy a policy online. Very easy and user friendly. Will recommend it to everyone.”`,
      reviewer_name: "Vikas Priyadarshi",
      location: "Indore",
    },
    {
      review: `“The team handled my renewal query and process very smoothly. They also convinced me to go for a personal cover policy which I am happy to opt for. They are a good asset to the team.”`,
      reviewer_name: "Kunal Chavda",
      location: "Chandigarh",
    },
    {
      review: `“The online quote system was simple to use with clear options. The additional options are well laid out and explained on the website, so I knew exactly what I could buy and compare to what I want. Hence renewed my two-wheeler policy online without any trouble. Excellent website”`,
      reviewer_name: "Chirag Lukka",
      location: "Delhi",
    },
    {
      review: `“I renewed my health and motor policy on icicilombard.com and it was a seamless experience. On my previous policy, I had registered a claim last month and I was pleasantly surprised with the claim settlement process. It was extremely hassle free and quick.”`,
      reviewer_name: "Abhishek Gupta",
      location: "Mumbai",
    },
    {
      review: `“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. THANKS ICICI LOMBARD”`,
      reviewer_name: "Leena Vaddella",
      location: "Delhi",
    },
  ];
  const partersLogos = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const partnersSlider = [
    {
      imgUrl:
        "../images/insurerlogo/ICICI-Lombard-General-Insurance-Co-Ltd.svg",
    },
    { imgUrl: "./images/insurerlogo/HDFC-ERGO-General-Insurance-Co-Ltd.svg" },
    {
      imgUrl: "./images/insurerlogo/Bajaj-Allianz-General-Insurance-Co-Ltd.svg",
    },
    {
      imgUrl: "./images/insurerlogo/Royal_Sundaram.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Magma-HDI-General-Insurance-Company-Limited.svg",
    },
    {
      imgUrl: "./images/insurerlogo/Tata-AIG-General-Insurance-Co-Ltd.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/HDFC-ERGO-Health-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Religare-Health-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Niva-Max-Bupa-Health-Insurance-Company-Ltd.svg",
    },
    {
      imgUrl: "./images/insurerlogo/aditya_birla_capital.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Star-Health-and-Allied-Insurance-Company-Limited.svg",
    },
    {
      imgUrl:
        "./images/insurerlogo/Tata-AIA-Life-Insurance-Company-Limited.svg",
    },
    { imgUrl: "./images/insurerlogo/HDFC-Standard-Life-Insurance-Co-Ltd.svg" },
    {
      imgUrl: "./images/insurerlogo/ICICI-Prudential-Life-Insurance-Co-Ltd.svg",
    },
    {
      imgUrl: "./images/insurerlogo/Max-Life-Insurance-Co-Ltd.svg",
    },
  ];
  // const blogCardsArray = [
  //   // {
  //   //   img_url: "./images/blog1.webp",
  //   //   blogtitle:
  //   //     "Factors to be considered while buying personal accident insurance in India",
  //   //   blog_desc:
  //   //     "Health insurance has become a vital aspect of our lives because a medical emergency might occur at any time. One such emergency that can occur without prior notice is an accident. Sadly, if the incidence is severe, the cost of therapy may be expensive. ",
  //   // },
  //   {
  //     img_url: "./images/blog10.jpg",
  //     blogtitle: "Benefits of Car insurance renewal ",
  //     blog_desc:
  //       "Car insurance renewal is a must for vehicle owners as it ensures continued protection against financial liabilities arising from accidents, theft, or damage of the vehicle. However, while choosing the right plan for you there are a lot of important factors that need to be considered. It is imperative to compare and check car insurance renewal price along with the benefits that are available.",
  //   },
  //   {
  //     img_url: "./images/blog2.webp",
  //     blogtitle: "Everything you need to know about Personal Accident Cover",
  //     blog_desc:
  //       "Regardless of how unpleasant it sounds; unfortunate events cause all happiness to vanish in a couple of seconds. A sudden accident might cause you to suffer from physical agony, emotional stress and financial burdens when you are wanting to enjoy a good and carefree life",
  //   },
  //   {
  //     img_url: "./images/blog3.webp",
  //     blogtitle: "All you need to know about Car Insurance!",
  //     blog_desc:
  //       "A policy that covers all the hazards connected to cars is called car insurance. It also goes by the name Motor Insurance and offers protection against any monetary losses brought on by accidents, including collisions with other vehicles or objects, theft of the car or its components, ",
  //   },
  // ];
  const addonCards = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const addonCardsArray = [
    {
      img_url: "./images/zerodep-cover.webp",
      addontitle: "Zero depreciation",
      addon_desc:
        "This supplementary coverage compensates for the car's depreciation in value. Policyholders do not have to bear the cost of the car's diminished value when they have this coverage. Several benefits are associated with this add-on. Even with a zero depreciation add-on cover, the optional deductible feature remains valid. ",
    },
    {
      img_url: "./images/pa-cover.webp",
      addontitle: "Personal Accident Rider",
      addon_desc:
        "This add-on provides protection against personal injuries, fatalities, disabilities, and damage to property caused by accidents.",
    },
    {
      img_url: "./images/ncb-cover.webp",
      addontitle: "No Claim Bonus Protection",
      addon_desc:
        "The insurance provider offers a discount on the premium that must be paid at the time of the insurance policy's renewal for each claim-free year. This incentive is cumulative and has the potential for annual growth. No Claim Bonus is safeguarded by this protection plan. When you file a claim, your No Claim Bonus remains intact. This add-on to your insurance allows you to preserve your No Claim Bonus after making a claim.",
    },
    {
      img_url: "./images/engine-cover.webp",
      addontitle: "Engine Protection Cover ",
      addon_desc:
        "This insurance covers the insured and pays for any engine damage caused by leaks or water intrusion. It safeguards the engine, gearbox, and differential components of the insured vehicle.",
    },
    // {
    //   img_url: "../images/invoice-cover.webp",
    //   addontitle: "Additional medical costs",
    //   addon_desc:
    //     "Under this add-on, the medical expenses which you face if you are hospitalised following a bike accident are covered.",
    // },
    {
      img_url: "./images/key-cover.webp",
      addontitle: "Key Protection Cover",
      addon_desc:
        "This add-on covers the policyholder's expenses for key repair or replacement. In the event of theft, this fixed-number claim add-on policy requires filing a First Information Report (FIR). If the key or lock is lost or stolen, the insurance covers the replacement costs.",
    },
    {
      img_url: "./images/returnto-invoice.webp",
      addontitle: "Return to invoice cover",
      addon_desc:
        "The car insurance policy pays the insured declared worth of the car, which is the market value of the car after depreciation, if the car is stolen or beyond economical repair. However, if this add-on is chosen, the claim would be paid out at the car's invoice value.",
    },
    {
      img_url: "./images/consumable-cover.webp",
      addontitle: "Consumables Cover",
      addon_desc:
        "The expenditures associated with consumables are not covered by the bike insurance coverage in the event that the car needs to be repaired. These consumables, which the mechanic uses to fix the car, include engine oil, nuts, bolts, screws, lubricants, etc. However, the consumables cover add-ons make sure that the car insurance policy also covers the cost of consumables.",
    },
    {
      img_url: "./images/roadside-assistance.webp",
      addontitle: "Breakdown assistance",
      addon_desc:
        "A roadside help add-on comes in handy if your car breaks down in the middle of the road and cannot be transported to the closest garage. If there is a breakdown, this add-on offers you support around-the-clock. The insurance provider only needs to be notified, and help is sent wherever you are. There is help available for flat tires, gasoline leaks, electrical problems, jump-starting the car, etc.",
    },
  ];
  const boxCardsArray = [
    {
      img_url: "./images/comprehensive-icon.svg",
      cardtitle: "Comprehensive",
      desc: "Comprehensive vehicle insurance covering theft, damage, third-party liabilities, and natural calamities. Includes Own Damage (OD) and Third-Party Liability (TP) for complete protection.",
    },
    {
      img_url: "./images/tp-icon.svg",
      cardtitle: "Third-party",
      desc: "Mandatory motor insurance covering third-party damages and legal liabilities from accidents involving the insured vehicle. Provides compensation for injuries, death, and property damage.",
    },
    {
      img_url: "./images/od-icon.svg",
      cardtitle: "Own Damage",
      desc: "Manage costly vehicle repairs with Standalone Own Damage (SOD) insurance, covering repairs and replacements due to accidents, theft, or fire.",
    },
  ];

  const faqs = [
    {
      title: "Why do I need car insurance?",
      desc: "In India, having third-party car insurance is mandatory. Additionally, you need a protective shield for yourself and your loved ones so that any financial loss or damages caused by accidents are compensated. In such cases, the insurance provider will cover your damages",
    },
    {
      title: "What are the benefits of buying car insurance online?",
      desc: "One of the main benefits of purchasing auto insurance online is that there is no paperwork or proof required. Additionally, you have a variety of payment alternatives.",
    },
    {
      title: "How quickly can I buy car insurance policy online?",
      desc: "It takes only a few minutes to purchase a car insurance online. Simply enter the necessary information, get quotes as per your needs from multiple insurers and make the payment.A copy of your car insurance will be sent to your email address immediately.",
    },
    {
      title: "Are offline and online car insurance policies the same? ",
      desc: "Yes, both are same. One of the benefits of online insurance is that the copy of the policy is sent directly to your registered email address after the payment is successfully done.",
    },
    {
      title: "What happens to my car insurance if I change jobs or locations?",
      desc: "The premium may alter based on the city you are moving to, but the policy will essentially stay the same. This is due to the fact that insurance costs vary depending on where an automobile is registered. Typically, metro areas charge a bigger premium than the rest of the nation. Simply notify the insurer about the job/location change to have the information updated. In any case, it is suggested that you update your contact information, including your new phone number and address, which can also be done online.",
    },
    {
      title: "What happens to the 4-wheeler insurance policy if I sell my car?",
      desc: (
        <>
          On selling your car, you must change the insurance policy owner's name
          from the current owner to the new owner. Below mentioned documents are
          needed for the same: <br />• Sale deed
          <br /> • Forms 29 and 30
          <br /> • Seller's NOC
          <br /> • NCB Recovery Amount
          <br /> However, the No Claim Bonus accrued as a result of your
          insurance may be transferred into your name and can be applied to your
          new vehicle. Additionally, you have the choice to revoke the current
          policy as well.
        </>
      ),
    },
    {
      title:
        "How do I get a copy of my car insurance policy online? Will the printout of the soft copy serve as the original document?",
      desc: (
        <>
          Choose the option to download an electronic copy of your policy by
          visiting the insurance company's official website. Your registered
          mobile number will receive an OTP for verification when you enter your
          policy number. Your registered email address and the OTP must both be
          entered. Your mail ID will receive a PDF copy of your auto insurance
          policy. The policy may then be downloaded.
          <br /> IIn addition to the actual physical documents, the Ministry of
          Road Transport and Highways has announced that digital copies of
          documents like a driver's licence and those for a vehicle's
          registration, insurance, etc., that are kept in Digilocker or the
          mParivahan mobile app will now be accepted legally. Original documents
          or reproductions of the same are not acceptable.
        </>
      ),
    },
    {
      title:
        "Am I eligible for a car insurance discount if I install an anti-theft alarm and locking system?",
      desc: "Yes, you will receive a discount if you install an additional layer of security because it will reduce the danger of theft for the insurer who supports this.",
    },
    {
      title:
        "What are electrical and non-electrical accessories? How do you calculate their value?",
      desc: "People install both electrical and non-electrical gadgets in their cars. Typical electrical attachments include LCD TVs, fog lights, music systems, and so forth. The interior fittings, such as seat covers, wheel covers, and CNG kits, are non-electrical extras. After applying the depreciation rate, their value is determined based on its initial market value.",
    },
  ];
  const [openCarForm, setOpenCarForm] = useState(false);
  const [pageStatus, setPageStatus] = useState<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8>(
    1
  );
  const dispatch = useAppDispatch();

  const [redirected, setRedirected] = useState<boolean>(false);

  useEffect(() => {
    changeHeaderFooterVisibility(0);

    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const token = params.get("token");

    if (token) {
      verifyJwt(token).then((result) => {
        console.log(result);
        if (!isEmpty(result?.payload?.data)) {
          const mobile_no = result?.payload?.data;
          setRedirected(true);
          dispatch(TWSlice.actions.BULK_UPDATE(TWSlice.getInitialState()));
          dispatch(
            HealthSlice.actions.BULK_UPLOAD(HealthSlice.getInitialState())
          );
          dispatch(TermSlice.actions.BULK_UPDATE(TermSlice.getInitialState()));
          dispatch(
            CarSlice.actions.BULK_UPDATE({
              ...CarSlice.getInitialState(),
              ADD_FORM: {
                ...CarSlice.getInitialState().ADD_FORM,
                mobile: { value: mobile_no.slice(2), warning: false },
              },
              REDIRECTED: true,
            })
          );
        } else {
          setRedirected(false);

          dispatch(
            CarSlice.actions.BULK_UPDATE({ ...CarSlice.getInitialState() })
          );
        }
      });
    }
  }, []);

  const { Car } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);
  const [openCarPrefilledData, setOpenCarPrefilledData] = useState(false);
  const continuePrefilledCar = () => {
    setOpenCarPrefilledData(false);
    setOpenCarForm(true);
    setPageStatus(6);
    pushDataLayer(
      "Jioinsure_flow ",
      "car details",
      `Continue`,
      REDIRECTED ? "JFS app" : "Insure website"
    );
    const eventProps = {
      action: "car details",
      click: "Continue",
      EntrySource: REDIRECTED ? "JFS app" : "Insure website",
    };
    sendEventToNativeApp("Jioinsure_flow", eventProps);
  };
  const denyPrefilledCar = () => {
    dispatch(
      CarSlice.actions.UPDATE_FORM_DATA([
        { key: "make", value: { value: "", warning: false } },
        { key: "model", value: { value: "", warning: false } },
        { key: "fuel_type", value: { value: "", warning: false } },
        { key: "variant_cc", value: { value: "", warning: false } },
      ])
    );
    setOpenCarPrefilledData(false);
    setOpenCarForm(true);
    setPageStatus(2);
    pushDataLayer(
      "Jioinsure_flow ",
      "car details",
      `edit`,
      REDIRECTED ? "JFS app" : "Insure website"
    );
    const eventProps = {
      action: "car details",
      click: "edit",
      EntrySource: REDIRECTED ? "JFS app" : "Insure website",
    };
    sendEventToNativeApp("Jioinsure_flow", eventProps);
  };
  const GET_VEHICLE_INFO_CAR = () => {
    const onSuccess = (res: any) => {
      setLoader(false);
      const results = res?.data;
      const response = results?.response;
      const error = results?.error;

      const make = response?.make;
      const model = response?.model;
      const make_model = response?.make_model;
      const variant_cc = `${response?.variant_cc}`;
      const fuel_type = response?.fuel_type;
      const registration_date = response?.registration_date;
      const policy_expiry_date = response?.policy_expiry_date;

      if (
        !isEmpty(make) &&
        !isEmpty(make_model) &&
        !isEmpty(variant_cc) &&
        !isEmpty(fuel_type)
      ) {
        dispatch(
          CarSlice.actions.UPDATE_FORM_DATA([
            { key: "make", value: { value: make, warning: false } },
            { key: "model", value: { value: model, warning: false } },
            { key: "make_model", value: { value: make_model, warning: false } },
            { key: "variant_cc", value: { value: variant_cc, warning: false } },
            { key: "fuel_type", value: { value: fuel_type, warning: false } },
            {
              key: "reg_date",
              value: {
                value: FORMAT_DD_MM_YYYY(registration_date),
                warning: false,
              },
            },
          ])
        );
        setOpenCarPrefilledData(true);
      } else {
        denyPrefilledCar();
      }
    };
    const onError = () => {
      setLoader(false);
    };

    setLoader(true);

    CAR_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
      onError,
      onSuccess,
      Car.ADD_FORM.reg_no.value
    );
  };
  const validateCarRollover = () => {
    let data: TCarAddForm = { ...Car.ADD_FORM };
    data = {
      ...data,
      reg_no: {
        ...data.reg_no,
        warning: !validateRegno(`${data.reg_no.value}`),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
    };

    if (!data.reg_no.warning && !data.mobile.warning) {
      pushDataLayer(
        "jioinsure_products_internal",
        "Car Insurance",
        `Get free quotes`,
        `${redirected ? "JFS app" : "Insure website"}`
      );
      const eventProps = {
        action: "Car Insurance",
        click: "Get free quotes",
        EntrySource: REDIRECTED ? "JFS app" : "Insure website",
      };
      sendEventToNativeApp("Jioinsure_flow", eventProps);
      GET_VEHICLE_INFO_CAR();
    }
    dispatch(
      CarSlice.actions.BULK_UPDATE({
        ...Car,
        ADD_FORM: {
          ...Car.ADD_FORM,
          reg_no: data.reg_no,
          mobile: data.mobile,
          business_type: "Rollover",
        },
      })
    );
  };

  return (
    <>
      <HelmetComponent
        title="Car Insurance Online @ 70% off* | JioInsure"
        description="Buy or renew your car insurance online. Enjoy the best prices and get upto 70% off* on your four-wheeler insurance premiums with riders today! JioInsure"
      />
      <Box
        className={`${styles.cmsWrapper} ${styles.sectionSpacing}`}
        sx={{ paddingTop: "60px" }}
      >
        <CarFormContainer
          open={openCarForm}
          setOpen={setOpenCarForm}
          pageStatus={pageStatus}
          setPageStatus={setPageStatus}
        />
        {isMobile ? (
          <MCarPreFilledData
            open={openCarPrefilledData}
            setOpen={setOpenCarPrefilledData}
            continuePrefilledCar={continuePrefilledCar}
            denyPrefilledCar={denyPrefilledCar}
          />
        ) : (
          <CarPrefilledData
            open={openCarPrefilledData}
            setOpen={setOpenCarPrefilledData}
            continuePrefilledCar={continuePrefilledCar}
            denyPrefilledCar={denyPrefilledCar}
          />
        )}

        <AddonPopup
          open={openAddonPopup}
          setOpen={setOpenAddonPopup}
          data={dataAddonPopup}
        />

        {isMobile ? <MNavBar /> : <NavBar />}

        <Box className="heroBanner">
          <img
            alt=""
            src="../images/car-banner.webp"
            width="100%"
            className="bgimg"
          />
          <Box className="innerWrapper">
            <Grid container spacing={2} alignItems="center">
              <Grid
                xs={12}
                md={7}
                display="flex"
                gap="16px"
                flexDirection="column"
                sx={{ color: COLORS.white }}
                className="contentBox"
              >
                <h1>Car Insurance</h1>
                <h2>Comprehensive Plans For 360° Protection</h2>
                <Box>
                  <p>
                    <img alt="" src="../images/green-tick.svg" />
                    Choice of multiple plans
                  </p>
                  <p>
                    <img alt="" src="../images/green-tick.svg" />
                    Fully online process
                  </p>
                </Box>
              </Grid>
              <Grid xs={12} md={5}>
                <Box className="formBox">
                  <h3>Buy car insurance and save up to 70%*</h3>

                  <TextInputField
                    title="Enter car registration number"
                    value={Car.ADD_FORM.reg_no.value}
                    attrName={"reg_no"}
                    value_update={(a: any, v: any) => {
                      dispatch(
                        CarSlice.actions.UPDATE_FORM_DATA([
                          {
                            key: "reg_no",
                            value: { value: v, warning: !validateRegno(v) },
                          },
                        ])
                      );
                    }}
                    warn_status={Car.ADD_FORM.reg_no.warning}
                    error_message={
                      !isEmpty(Car.ADD_FORM.reg_no.value)
                        ? "Enter Valid Vehicle number"
                        : "Enter Vehicle number"
                    }
                    placeholder={"Eg: MH00XX0000"}
                    validation_type="ALPHANUMERIC"
                    max_length={11}
                  />

                  <Box className="mobileNumber">
                    <Box
                      position="absolute"
                      left="12px"
                      bottom="13px"
                      zIndex="2"
                      fontSize="18px"
                      fontWeight="bold"
                      sx={{ color: COLORS.lightgrey }}
                    >
                      +91
                    </Box>
                    <TextInputField
                      disabled={redirected}
                      title="Enter your mobile number"
                      placeholder="XXXXXXXXXX"
                      validation_type="NUMBER"
                      value={Car.ADD_FORM.mobile.value}
                      attrName={"mobile"}
                      value_update={(a: any, v: any) => {
                        dispatch(
                          CarSlice.actions.UPDATE_FORM_DATA([
                            {
                              key: "mobile",
                              value: {
                                value: v,
                                warning: !validateMobileNumber(v),
                              },
                            },
                          ])
                        );
                      }}
                      warn_status={Car.ADD_FORM.mobile.warning}
                      error_message={
                        isEmpty(Car.ADD_FORM.mobile.value)
                          ? "Enter Mobile Number"
                          : "Enter valid mobile number"
                      }
                      max_length={10}
                    />
                  </Box>

                  <CustomCheckbox
                    label={
                      <>
                        I agree to{" "}
                        <Link
                          style={{
                            color: COLORS.primary,
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                          }}
                        >
                          Terms & Conditions
                        </Link>
                        ,{" "}
                        <Link
                          style={{
                            color: COLORS.primary,
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            navigate(COMMON_ROUTES.PRIVACY_POLICY);
                          }}
                        >
                          Privacy Policy
                        </Link>
                        {` and `}
                        <Link
                          style={{
                            color: COLORS.primary,
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            navigate(COMMON_ROUTES.DISCLAIMER);
                          }}
                        >
                          Disclaimer
                        </Link>
                      </>
                    }
                    attrName="termsandconditions"
                    value={Car.ADD_FORM.term_cond}
                    defaultChecked={Car.ADD_FORM.term_cond}
                    variant="large"
                    value_update={(attrName: string, value: boolean) => {
                      dispatch(
                        CarSlice.actions.UPDATE_FORM_DATA([
                          {
                            key: "term_cond",
                            value: value,
                          },
                        ])
                      );
                    }}
                  />
                  <Grid container rowSpacing={2} columnSpacing={0}>
                    <Grid xs={12} md={6} order={{ xs: 2, md: 1 }}>
                      <JDSButtons
                        className="secondaryBtn large"
                        text="Bought a new car?"
                        variant="text"
                        fullWidth={true}
                        onClick={() => {
                          dispatch(
                            CarSlice.actions.UPDATE_FORM_DATA([
                              { key: "business_type", value: "New" },
                              {
                                key: "reg_no",
                                value: { value: "", warning: false },
                              },
                            ])
                          );
                          setPageStatus(1);
                          if (Car.ADD_FORM.term_cond) {
                            pushDataLayer(
                              "jioinsure_products_internal",
                              "Car Insurance",
                              `Bought a new car`,
                              `${redirected ? "JFS app" : "Insure website"}`
                            );
                            const eventProps = {
                              action: "Car Insurance",
                              click: "Bought a new car",
                              EntrySource: REDIRECTED
                                ? "JFS app"
                                : "Insure website",
                            };
                            sendEventToNativeApp(
                              "jioinsure_products_internal",
                              eventProps
                            );
                            setOpenCarForm(true);
                          } else {
                            toast.error("Please accept terms and conditions");
                          }
                        }}
                      />
                    </Grid>
                    <Grid xs={12} md={6} order={{ xs: 1, md: 2 }}>
                      <JDSButtons
                        onClick={() => {
                          if (Car.ADD_FORM.term_cond) {
                            validateCarRollover();
                          } else {
                            toast.error("Please accept terms and conditions");
                          }
                        }}
                        className="primaryBtn large"
                        text="Get free quotes"
                        variant="text"
                        loader={loader}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Why Jio Advantage */}
        <Box className="why_jio_section">
          <Grid container className="row" columnSpacing={3} alignItems="center">
            <Grid xs={12}>
              {/* <h4
              style={{
                color: COLORS.lightgrey,
                fontWeight: 700,
              }}
            >
              The Jio Advantage
            </h4> */}
              <h2>Why Jio Insurance?</h2>
              <h5
                style={{
                  color: "#595959",
                  maxWidth: "824px",
                }}
              >
                At Jio Insurance Broking we have created a seamless marketplace
                for you to compare different insurance plans and choose the one
                that fits your requirements the best.
              </h5>
            </Grid>
            <Grid xs={12} md={6}>
              <img
                src="../images/jio-advantage.webp"
                width="100%"
                style={{ borderRadius: "24px" }}
                alt="img"
              />
            </Grid>
            <Grid xs={12} md={6}>
              <ul className="listView">
                {whySectionArray?.map((data, index) => (
                  <li key={index}>
                    <img src={data.iconUrl} alt="icon" />
                    <Box>
                      <h5
                        className="mb-1 mt-0"
                        style={{
                          color: COLORS.darkgrey,
                          fontWeight: "bold",
                        }}
                      >
                        {data.heading}
                      </h5>
                      <p
                        style={{
                          color: COLORS.lightgrey,
                        }}
                      >
                        {data.desc}
                      </p>
                    </Box>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box className="lightGoldBg px-3">
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid xs={12}>
              <h2 className="mb-4">
                Car insurance plans that suits your needs
              </h2>
            </Grid>
            {boxCardsArray.map((data, index) => (
              <Grid xs={12} md={4} display="flex">
                <Box className="cardBox">
                  <Box>
                    <img src={data.img_url} />

                    <h3>{data.cardtitle}</h3>
                  </Box>
                  <h5 style={{ color: COLORS.lightgrey }}>{data.desc}</h5>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Grid container rowSpacing={3} columnSpacing={{ xs: 6, md: 3 }}>
          <Grid xs={12} md={6}>
            <img
              src="../images/car-insurance.webp"
              style={{ borderRadius: "24px" }}
              width="100%"
              alt=""
            />
          </Grid>
          <Grid xs={12} md={6}>
            <h3 className="mb-4 mt-6">What is car insurance?</h3>
            <h5 style={{ color: COLORS.lightgrey }}>
              Car insurance is essential to ensure you don't face financial
              setbacks in the event your vehicle sustains damage from accidents,
              natural disasters, or if you become a victim of car theft.
              Additionally, it safeguards your finances against unforeseen
              circumstances. For many of us, cars hold both financial and
              emotional value. Therefore, it's crucial to secure your car with
              comprehensive car insurance or third-party liability cover, which
              is mandatory under the Motor Vehicle Act 1988. Don't leave your
              precious car vulnerable to risks.
            </h5>
          </Grid>
        </Grid>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 6, md: 3 }}>
          <Grid xs={12} md={6}>
            <h3 className="mb-4 mt-6">Car insurance premium calculator</h3>
            <h5 style={{ color: COLORS.lightgrey }}>
              The cost of the premium is a primary consideration when purchasing
              car insurance. You can calculate your car insurance premium online
              through a reliable website to get an accurate estimate of your
              coverage costs. Many online platforms allow you to determine your
              car insurance costs and compare various providers.
            </h5>
          </Grid>
          <Grid xs={12} md={6}>
            <img
              src="../images/premium-calculator.webp"
              style={{ borderRadius: "24px" }}
              width="100%"
              alt=""
            />
          </Grid>
        </Grid>

        {/* Blog articles */}
        {redirected ? null : (
          <Box className="blogs_section">
            <Grid
              container
              className="row"
              columnSpacing={3}
              marginBottom={"32px"}
            >
              <Grid xs={12}>
                {/* <h4
              style={{
                color: "#595959",
              }}
            >
              Resource Hub
            </h4> */}
                <h2>Blogs</h2>
                <h5
                  style={{
                    color: "#595959",
                    maxWidth: "824px",
                  }}
                >
                  It is imperative to keep certain factors in mind while
                  choosing a car insurance. Read on to know more about the
                  things to keep in mind while doing the same.
                </h5>
              </Grid>
              <Grid xs={12}>
                <ul>
                  {blogCardsArray?.map((data, index: any) => (
                    <BlogCard data={data} />
                  ))}
                </ul>
              </Grid>
              <Grid xs={12} display={"flex"} justifyContent={"center"}>
                <Box maxWidth={"384px"} width={"100%"}>
                  <JDSButtons
                    text="Read more blogs"
                    variant={"text"}
                    className={"secondaryBtn large"}
                    onClick={() => navigate(COMMON_ROUTES.BLOGS)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {/* Testimonial */}
        <Box className="testimonial_section">
          <Grid container className="row" columnSpacing={3}>
            <Grid xs={12}>
              <h2 style={{ maxWidth: "520px", margin: "0px auto" }}>
                Meet some of our happy customers
              </h2>
              <h5 className="mt-2" style={{ color: COLORS.lightgrey }}>
                Words from our satisfied customers
              </h5>
            </Grid>
          </Grid>
          <Grid container className="row" columnSpacing={0}>
            <Grid xs={12} className="testimonial-carousel">
              <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {testimonialsSlider.map((data, index) => (
                  <TestimonialCard data={data} />
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        {/* Partners */}
        <Box className="partners_section">
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 6, md: 3 }}
            marginBottom={"42px"}
          >
            <Grid xs={12}>
              <h2 style={{ color: "#001E2B" }}>Our Partners</h2>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 6, md: 3 }}
            justifyContent={"center"}
          >
            <Grid xs={12} className="partners-carousel">
              <Carousel
                responsive={partersLogos}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {partnersSlider.map((data, index) => {
                  return (
                    <Box className="partner_box">
                      <img src={data.imgUrl} alt="" />
                    </Box>
                  );
                })}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        {/* compare and select sec */}
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 6, md: 3 }}
          justifyContent={"center"}
        >
          <Grid xs={12} className="textCenter">
            <h2>Compare and select best car insurance</h2>
          </Grid>
          <Grid xs={12}>
            <Box className={styles.tableWrapper}>
              <table
                className={styles.prefferedTable}
                cellSpacing={0}
                cellPadding={0}
              >
                {/* table heading */}
                <tr>
                  <th>Benefits</th>
                  <th>Comprehensive cover</th>
                  <th>Third party liability only cover</th>
                </tr>

                {/* table heading */}
                <tr>
                  <td>Damage due to natural calamity - Earthquakes, etc</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.failed} />
                  </td>
                </tr>
                <tr>
                  <td>
                    Damage due to events like- Fire, theft, vandalism, etc
                  </td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.failed} />
                  </td>
                </tr>
                <tr>
                  <td>Add on choices - Zero depreciation, NCB protect, etc</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.failed} />
                  </td>
                </tr>
                <tr>
                  <td>Customisation of car value</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                </tr>
                <tr>
                  <td>Personal accident cover of ₹15 lakhs*</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                </tr>
                <tr>
                  <td>No hefty fines levied if valid policy found</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                </tr>
                <tr>
                  <td>Injury to a third person</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                </tr>
                <tr>
                  <td>Damage to third party vehicle/property</td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                  <td>
                    <Box className={styles.success} />
                  </td>
                </tr>
              </table>
            </Box>
            <Box maxWidth="384px" marginLeft="auto">
              <JDSButtons
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                className="primaryBtn large mt-8"
                text="Buy now"
                variant="outlined"
                fullWidth={true}
              />
            </Box>
          </Grid>
        </Grid>

        <Box
          className="why_jio_section"
          sx={{
            background: COLORS.lightMariGold20,
            padding: "64px 12px",
          }}
        >
          <Grid container className="row" columnSpacing={3}>
            <Grid xs={12} textAlign={"center"}>
              <h2 className="mb-6">
                How to choose a car insurance policy online?
              </h2>
            </Grid>
            <Grid xs={12}>
              <ul>
                {chooseInsuranceArray?.map((data, index) => (
                  <li key={index}>
                    <img src={data.iconUrl} alt="icon" />
                    <h4
                      className="mb-2 mt-4"
                      style={{
                        color: COLORS.darkgrey,
                        fontWeight: "900",
                        lineHeight: "28px",
                        textAlign: "left",
                      }}
                    >
                      {data.heading}
                    </h4>
                    <p
                      style={{
                        color: COLORS.lightgrey,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      {data.desc}
                    </p>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        {/* Add-ons */}
        <Box className="addonSection">
          <Grid container columnSpacing={{ xs: 6, md: 3 }}>
            <Grid xs={12} textAlign={"center"}>
              <h2>Add-ons in a car insurance policy</h2>
              <h5
                style={{
                  color: "#595959",
                  maxWidth: "1016px",
                  margin: "24px auto 0px",
                }}
              >
                You can purchase add-on coverage by paying an extra premium on
                your car insurance. Additional coverage options are available
                under comprehensive car insurance packages. Here are some common
                examples of add-ons
              </h5>
            </Grid>

            <Grid xs={12} className="mt-12">
              <Carousel
                responsive={addonCards}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={true}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {addonCardsArray.map((data, index) => (
                  <AddonCard
                    data={data}
                    setOpenAddonPopup={setOpenAddonPopup}
                    setDataAddonPopup={setDataAddonPopup}
                  />
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        {/* faqs */}
        <Grid
          container
          className="row"
          rowSpacing={3}
          columnSpacing={6}
          justifyContent={"center"}
        >
          <Grid xs={12} className="textCenter">
            <h2>Frequently Asked Questions</h2>
          </Grid>
          <Grid xs={12}>
            <FAQ data={showAll ? faqs : faqs.slice(0, defaultShow)} />
          </Grid>
          <Grid xs={12}>
            <JDSButtons
              onClick={() => setShowAll(!showAll)}
              className="secondaryBtn large"
              text={showAll ? "View less" : "View more"}
              variant="outlined"
              fullWidth={false}
              // loader="true"
            />
          </Grid>
        </Grid>

        {isMobile ? <MFooter /> : <Footer />}
      </Box>
    </>
  );
}

export default CarProductPage;
