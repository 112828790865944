import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import {
  blog_data,
  blogsResponsive,
} from "../../../../Component/Desktop/Common/BlogArray/BLogArray";
import Footer from "../../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../BlogsPage.scss";

const BlogHealthInsurance = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const current_blog_data = {
    id: "health-insurance-top-up-plans",
    blogtitle: "Health Insurance Top-Up Plans",
    img_url: "/images/blog7.webp",
    main_desc: (
      <>
        <p className="mb-4">
          Health insurance covers the needs of you and your family during a
          medical emergency. Imagine you are in a situation where the hospital
          bills exceed the health insurance cover you have, then you must pay
          the extra amount from your own funds which can be a huge financial
          burden for you and your family. Is there a way out of this
          predicament? There, sure is. You can secure you and your family’s
          health further by buying top-up plans.
        </p>
        <p>
          Let us deep dive and try to understand what really are these top-up
          plans in health insurance policies? How do they work? And what are
          their advantages and disadvantages? Why should you buy a Health
          Insurance Top-up Plan? In this blog, we will talk about everything
          related to top-up plans.
        </p>
      </>
    ),
    blog_full_desc: (
      <>
        <h6 className="mb-3">First, let us understand what top-up plans are</h6>
        <p className="mb-5">
          If you have a health insurance policy and if you feel that the cover
          is not enough as per your current needs and you have recently gone
          through a hospitalization and realized that the current cover fell
          short of total hospitalization bills, you can buy a top-up plan to
          increase your coverage amount. In the case of hospitalization, if your
          claim amount is more than the cover amount of your base health plan,
          you can claim the extra amount from the top-up plan. There are two
          types of Top-up plans:
        </p>

        <h6 className="mb-3">Top-up plan: </h6>
        <p className="mb-5">
          A top-up policy is applicable on a per claim basis and kicks in only
          if the claim amount is more than the cover of your health insurance
          plan. For example, if you have a top-up plan, then in case of
          hospitalization if the bill amount exceeds the cover of your health
          insurance plan, then you can claim the differential amount from the
          top-up plan for that particular claim.{" "}
        </p>

        <h6 className="mb-3">Super top-up plan: </h6>
        <p className="mb-5">
          Super top-up pays the amount if due to repeated claims you have
          exhausted the yearly cover provided by your base health insurance. It
          works at an aggregate level and kicks in as in when you have used your
          base policy amount. So, unlike a top-up plan, there is no threshold at
          a particular claim level.{" "}
        </p>
        <p className="mb-3">Let us understand the plans with an example: </p>
        <p className="mb-3">
          Policyholders A and B are hospitalized and have a top-up and super
          top-up policy respectively of ₹ 5 lakh over a basic health policy of ₹
          3 lakh. Say the claims that happen to both for a particular year are ₹
          3 lakhs, ₹ 1 lakh and ₹ 4 lakh.{" "}
        </p>
        <table className="tabledata mb-5 planExplanation">
          <thead>
            <tr>
              <th> Policyholder</th>
              <th>A</th>
              <th>B</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Claim 1 – ₹ 3 lakh </td>
              <td>Covered by the base health plan</td>
              <td>Covered by the base health plan</td>
            </tr>
            <tr>
              <td>Claim 2 – ₹ 1 lakh </td>
              <td>
                The entire amount needs to be paid by the policyholder A. Top-up
                plan will not cover it as this claim does not exceed the cover
                amount of the health insurance plan. A top-up plan is applicable
                on a per claim basis and kicks in only if the claim amount is
                more than the cover of your health insurance plan. Meanwhile, he
                already exhausted his health insurance coverage in the previous
                claim.{" "}
              </td>
              <td>
                It will be covered by the super top-up plan. Super top-up pays
                the extra amount when the aggregate amount exceeds the cover
                amount of the basic health plan in case of multiple claims.
              </td>
            </tr>
            <tr>
              <td>Claim 3 – ₹ 4 lakh </td>
              <td>
                ₹ 1 lakh will be covered by the top-up plan, which is the extra
                amount over his base health insurance coverage amount. Rest,
                i.e. ₹3 lakh has to be paid by the policyholder A since he has
                already exhausted his cover in the previous claim.
              </td>
              <td>₹ 4 lakh is paid by the super top-up.</td>
            </tr>
          </tbody>
        </table>

        <h6 className="mb-3">
          Advantages & disadvantages of health insurance top-up plans{" "}
        </h6>
        <p className="mb-3">
          The <span style={{ fontWeight: 700 }}>two advantages</span> of top-up
          policies over increasing the coverage amount for the existing health
          insurance policy are –
        </p>
        <ul className="mb-5">
          <li>
            <span>Top-up plans are more cost-effective: </span>Buying a top-up
            plan is much more cost-effective than increasing the base cover of
            the existing health insurance plan. And that is the primary reason
            for some people preferring to buy it instead of increasing the base
            cover for the existing health insurance plan.
            <br />
            <br />
            Say, you have a health insurance policy of ₹ 3 lakh for which you
            are paying a premium amount of ₹ 6,000/- every year. But, you feel
            that the coverage amount is not enough and you should have at least
            ₹ 5 lakh as the coverage amount. Now, if you decide to increase the
            coverage of your existing plan from ₹ 3 lakh to ₹ 5 lakh, the
            premium amount for the policy would be ₹ 10,000/- annually.
            Meanwhile, in case you already have a health insurance policy, then
            the premium amount for top-up plans is usually ₹ 1,000/- per lakh
            cover. Hence, for an extra ₹ 2 lakh cover, you will have to pay an
            additional premium of ₹ 2,000. The total premium for the policy and
            the top-up plan combined would be ₹ 8,000 per year and cheaper than
            the earlier option
          </li>
          <li>
            <span>Limited increase of coverage amount:</span> If you want to
            increase the sum assured for your health insurance policy, you can
            only increase that to some extent. Each health insurance company
            determines up to what limit it will allow its policyholders to
            increase their coverage amount. But, if you buy top-up or a super
            top-up plan, you can decide what cover amount you want to buy.
          </li>
        </ul>

        <p className="mb-3">
          The <span style={{ fontWeight: 700 }}>two disadvantages</span> of
          top-up policies over increasing the coverage amount for the existing
          health insurance policy are –
        </p>
        <ul className="mb-5">
          <li>
            <span>Top-up plans have a waiting period: </span> Since top-up plans
            are bought as a separate plan so they have a waiting period during
            which you cannot make any claims. For example, like a health
            insurance policy, top-up plans have an initial waiting period of one
            month, during which you cannot make any claim. Then some top-up
            plans come with a one-year waiting period for specific diseases like
            tonsils, hernia, cataract, etc., during which you cannot make any
            claim for those specific illnesses mentioned in the policy. Also, if
            you have any pre-existing ailment, there is a waiting period of two
            to three years.
          </li>
          <li>
            <span>
              You can’t avail the ‘no-claim benefit’ for a top-up plan:
            </span>{" "}
            For every claim-free year, your health insurance company rewards you
            by increasing your coverage amount or decreasing your yearly premium
            amount. This benefit is called a no-claim bonus. But, the top-up
            plans do not come with the no-claim bonus benefit. If you want to
            increase your cover amount for top-up plans, then you will have to
            buy more cover.
          </li>
        </ul>

        <h6 className="mb-3">
          Jio Insurance Broking point of view on Health Insurance Top-up Plans:
        </h6>
        <p>
          Health Insurance Top-up plans are an excellent way to increase your
          health insurance coverage amount. But before buying a top-up plan, it
          is imperative that you read the policy papers carefully to understand
          the term and conditions so that you do not face any difficulty during
          the claim process.{" "}
        </p>
      </>
    ),
  };

  return (
    <Box className="blogsWrapper">
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="blogsdetail-sec">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12}>
            <h2>{current_blog_data?.blogtitle}</h2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              marginBottom={"24px"}>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}>
                July 18, 2023
              </p>
              <Box display={"flex"} gap={"8px"}>
                <span className="tag">Insurance</span>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid md={6} marginBottom={"24px"}>
            <Box className="img-overlay">
              <Box className="img-bg">
                <img src={current_blog_data?.img_url} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid md={6} marginBottom={"24px"}>
            {current_blog_data?.main_desc}
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            {current_blog_data?.blog_full_desc}
          </Grid>
        </Grid>
      </Box>
      <Box className="blogCard-sec mb-pr-0">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}>
          <Grid xs={12} marginBottom={"24px"} className="mb-pr-0">
            <h4>You May Also Like</h4>
          </Grid>
          <Grid xs={12} className="mb-pr-0">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={blogsResponsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              customTransition="transform 0.7s ease-in-out"
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              partialVisible={true}>
              {blog_data.map((item, index) => (
                <Box>
                  <Box
                    className="blog-card"
                    sx={{
                      boxShadow: "0px 4px 16px rgba(0,0,0,0.08)",
                      borderRadius: "12px",
                      marginRight: "16px",
                    }}>
                    <img src={item?.img_url} alt="" />
                    <Box className="card-info">
                      <h5 className="mb-1">{item.blogtitle}</h5>
                      <p className="mb-3">{item.blog_desc}</p>
                      <Link
                        className="cont-read-link"
                        onClick={() => {
                          navigate(item?.id);
                          window.scrollTo(0, 0);
                        }}>
                        Continue reading
                      </Link>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default BlogHealthInsurance;
