import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import styles from "../../../SCSS/CMSPages.module.scss";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";
import { COLORS } from "../../../SupportingFiles/colors";

function Aboutus() {
  return (
    <>
      <HelmetComponent
        title="Jio Insurance Broking Limited - JIBL | Jio General Insurance"
        description="Jio Insurance Broking Limited (JIBL) offers life, car, bike, and health insurance solutions, focusing on compliance and customer-centric broking services. JioInsure"
      />
      <Box className={styles.cmsWrapper}>
        <NavBar />

        {/* Hero Banner */}
        <Box
          sx={{ marginTop: "-40px", marginBottom: "64px" }}
          className="heroBanner">
          <Box
            sx={{ width: "100%", position: "relative", marginBottom: "12px" }}>
            <img
              alt=""
              className="bgimg"
              src="./images/aboutus-banner.webp"
              width="100%"
              style={{
                minHeight: "650px",
                maxHeight: "62vh",
                height: "62vh",
                objectPosition: "center top",
                objectFit: "cover",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                width: "100%",
                color: "#fff",
                transform: "translateY(-50%)",
                zIndex: "9",
              }}>
              <Grid container spacing={3}>
                <Grid
                  xs={12}
                  md={8}
                  display="flex"
                  gap="16px"
                  flexDirection="column">
                  <h2 style={{ fontSize: "64px", lineHeight: "64px" }}>
                    Empowering your peace of mind; discover our story at Jio
                    Insurance Broking Ltd.
                  </h2>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        {/* Hero Banner End */}

        <Grid container className="row" columnSpacing={3} marginBottom={"40px"}>
          {/* <Grid xs={12}>
          <h2>About us</h2>
          <Box
            sx={{
              borderRadius: "24px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              marginTop: "24px",
              marginBottom: "24px",
            }}
          >
            <img src="./images/about-header-lg.jpg" width="100%" alt="img" />
          </Box>
        </Grid> */}
          <Grid xs={12} textAlign={"center"}>
            <h2 style={{ marginBottom: "24px" }}>
              Finding you the best insurance solutions since 2007
            </h2>
            <p style={{ color: COLORS.lightgrey, marginBottom: "24px" }}>
              Jio Insurance Broking Limited - JIBL (formerly known as - Reliance
              Retail Insurance Broking Limited-RRIBL) established in 2006 is a
              fully owned subsidiary of Jio Financial Services Limited. JIBL was
              licensed as a Direct Broker by the Insurance Regulatory
              Development Authority of India in the year 2007 and since then we
              have been distributing Life, General and Health Insurance products
              of most of the Insurance companies across the country.
            </p>
            <p style={{ color: COLORS.lightgrey, marginBottom: "24px" }}>
              We specialize in providing customized and need based Insurance
              solutions to our retail and corporate customers. We operate within
              a very strong compliance and governance framework. The company has
              a customer centric approach and provides broking services across
              the country through a strong network of well-trained Insurance
              Guides supporting customers with their insurance needs.
            </p>
          </Grid>
          {/* <Grid xs={12} textAlign="center" marginBottom="40px" marginTop="80px">
          <p
            style={{
              color: COLORS.primary,
              textTransform: "uppercase",
            }}
          >
            <small>protection meets convenience</small>
          </p>
          <h2>For your relaxed future</h2>
        </Grid>
        <Grid xs={12} md={4} alignSelf="center">
          <img src="./images/relaxed-man.jpg" width="100%" alt="img" />
        </Grid>
        <Grid xs={12} md={8}>
          <ul
            style={{
              display: "flex",
              gap: "24px",
              flexDirection: "column",
              marginBottom: "24px",
            }}
          >
            {itemsData.map((item, index) => (
              <li
                key={index} // Use index as the key, but ideally, each item should have a unique identifier
                style={{
                  position: "relative",
                  paddingLeft: "56px",
                }}
              >
                {index === itemsData.length - 1 ? null : (
                  <span
                    style={{
                      backgroundColor: "#B5B5B5",
                      position: "absolute",
                      left: "20px",
                      top: "40px",
                      height: "100%",
                      width: "1px",
                    }}
                  ></span>
                )}
                <Box
                  sx={{
                    backgroundColor: COLORS.lightMariGold20,
                    height: "40px",
                    width: "40px",
                    borderRadius: "40px",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    padding: "6px",
                  }}
                >
                  <img src={item.image} width="100%" alt="icon" />
                </Box>
                <h5>{item.title}</h5>
                <p
                  style={{
                    color: COLORS.lightgrey,
                    marginTop: "8px",
                  }}
                >
                  {item.description}
                </p>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid xs={12} textAlign="center" marginBottom="24px" marginTop="80px">
          <p
            style={{
              color: COLORS.primary,
              textTransform: "uppercase",
            }}
          >
            <small>The Jio Advantage</small>
          </p>
          <h2>Why Jio Insurance?</h2>
          <h6
            style={{
              color: COLORS.lightgrey,
              margin: "24px auto 0px",
              maxWidth: "738px",
            }}
          >
            At Jio Insurance Broking, we understand that finding the right
            insurance can be a complex and time-consuming process. That's why
            we've designed the insurance-buying experience, and created a
            seamless marketplace where protection meets convenience.
          </h6>
        </Grid>
        <Grid xs={12} className={styles.why_jio_section}>
          <ul>
            {whySectionArray?.map((data, index) => (
              <li key={index}>
                <Box className={styles.innerBox}>
                  <img src={data.iconUrl} alt="icon" />
                  <p
                    className="mb-1"
                    style={{
                      color: "#141414",
                      fontWeight: 700,
                      lineHeight: "20px",
                      letterSpacing: "-0.08px",
                    }}
                  >
                    {data.heading}
                  </p>
                  <p>
                    <small
                      style={{
                        color: "#595959",
                        letterSpacing: "-0.08px",
                      }}
                    >
                      {data.desc}
                    </small>
                  </p>
                </Box>
              </li>
            ))}
          </ul>
        </Grid> */}
        </Grid>

        <Footer />
      </Box>
    </>
  );
}

export default Aboutus;
