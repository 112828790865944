import React, { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import NonTermProposal from "../../../../Page/Desktop/NonTerm/NonTermProposal/NonTermProposal";
import { TNonTermProposerData } from "../../../../types/TNonTermSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../../SupportingFiles/HelpingFunction";
import { NonTermSlice } from "../../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { NON_TERM_SERVICES } from "../../../../Services/NonTerm/NonTermFormService";
import MNonTermProposal from "../../../../Page/Mobile/NonTerm/MNonTermProposal/MNonTermProposal";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { Box } from "@mui/system";

const NonTermBasicDetailsContainer = () => {
  const isMobile = useIsMobile();
  const {
    PROPOSER_DATA,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    RIDERS_BENEFITS,
    QUOTE_FIELDS_DATA,
    UPDATE_RESPONSE,
    SELECTED_QUOTE_DATA,
    QUOTE_LOADER
  } = useAppSelector((state) => state.NonTerm);
  const { NonTerm } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);
  console.log("PROPOSER_DATA", PROPOSER_DATA);
  const dispatch = useAppDispatch();
  const [proposerFields, setProposerFields] =
    useState<TNonTermProposerData>(PROPOSER_DATA);
    useEffect(()=>{
      GET_RIDERS_PREMIUM()
    },[ADD_FORM_RESPONSE.quote_no])

  const GET_RIDERS_PREMIUM = () => {
    const onSuccess = (data: any) => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      const error = data.response?.premiumDetails?.error;
      const message = data.message;
      const response: any =
        data.response.premiumDetails;
      if (!error) {
        dispatch(NonTermSlice.actions.SET_RIDERS_BENEFITS_DATA(response));
      } else {
        dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      }
    };

    const onError = (err: any) => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
     
    };

    const data:any = {
      product_ids: [SELECTED_QUOTE_DATA?.productDetails.id],
      quote_no: ADD_FORM_RESPONSE.quote_no,
    };
    dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));

    NON_TERM_SERVICES.NON_TERM_UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };

  const fieldsUpdateState = (attr: any, value: any) => {
    console.log("attr", attr, value);
    setProposerFields({
      ...proposerFields,
      [attr[0]]: {
        value: value,
        warning:
          attr[0] === "full_name"
            ? !validateFullName(value)
            : attr[0] === "mobile"
            ? !validateMobileNumber(value)
            : attr[0] === "email"
            ? !validateEmail(value)
            : attr[0] === "emergency_number"
            ? !validateMobileNumber(value)
            : isEmpty(value),
      },
    });
  };
  console.log("attr", proposerFields);
  const UPDATE_PROPOSER_DETAILS = () => {
    let params = {
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      // quote_no: "SkkxNTQxNDEwODUz",
      section: "proposer_details",
      details: {
        proposer_annual_income: ADD_FORM.annualIncome.value,
        proposer_dob: ADD_FORM.age.value,
        proposer_email: proposerFields?.email?.value,
        proposer_emergency_no: proposerFields.emergency_number.value,
        proposer_gender: proposerFields?.gender?.value,
        proposer_mobile: ADD_FORM.mobile.value,
        proposer_name: proposerFields?.full_name?.value,
        proposer_occupation: proposerFields.occupation.value,
        proposer_qualification: proposerFields.qualification.value,
        address_pincode: ADD_FORM.pincode.value,
      },
    };

    const onSuccess = (res: any) => {
      GET_REDIRECTION_URL();
      console.log("res", res);
      setLoader(false);
    };
    const onError = () => {
      setLoader(false);
    };
    setLoader(true);
    NON_TERM_SERVICES.UPDATE_MAX_LIFE_GET_PROPOSAL(onSuccess, onError, params);
  };

  const GET_REDIRECTION_URL = () => {
    dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    const onSuccess = (res: any) => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      // console.log("res", res);
      setLoader(false);
      const error = res?.error;

      if (!error) {
        const response = res?.response;
        const redirectionUrl = response.redirectionUrl;
        window.location.href = redirectionUrl;
      }
    };
    const onError = () => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      setLoader(false);
    };
    const params = {
      product_ids: [SELECTED_QUOTE_DATA?.productDetails?.id],
      // quote_no: "QB1717591215435672699",
      quote_no: ADD_FORM_RESPONSE.quote_no,
    };
    NON_TERM_SERVICES.GET_REDIRECTION_URL(onSuccess, onError, params);
  };

  const validateForm = () => {
    let data = { ...proposerFields };
    console.log("datata", data);
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: {
        ...data.dob,
        warning: isEmpty(data.dob.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      emergency_number: {
        ...data.emergency_number,
        warning: !validateMobileNumber(data.emergency_number.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      gender: {
        ...data.gender,
        warning: isEmpty(data.gender.value),
      },
      occupation: {
        ...data.occupation,
        warning: isEmpty(data.occupation.value),
      },
      qualification: {
        ...data.qualification,
        warning: isEmpty(data.qualification.value),
      },
    };
    setProposerFields({ ...data });

    if (
      !data.full_name.warning &&
      !data.emergency_number.warning &&
      !data.email.warning &&
      !data.occupation.warning &&
      !data.qualification.warning
    ) {
      dispatch(NonTermSlice.actions.UPDATE_PROPOSER_DATA(data));
      UPDATE_PROPOSER_DETAILS();
    }
  };

  const NON_TERM_PROPOSER_OCCUPATION_DETAILS = () => {
    let params = {
      product_type_code: "NPAR",
      product_code: `${SELECTED_QUOTE_DATA.productDetails.product_code}`,
    };

    const onSuccess = (res: any) => {
      console.log("res", res);

      const results = res.results;
      const error = results.error;
      const response = results.response;

      let data: TDropdown[] = response.map((item: any) => ({
        label: item.code,
        value: item.title,
      }));

      dispatch(
        NonTermSlice.actions.SET_DROPDOWN_DATA({
          key: "OCCUPATION_DATA",
          value: data,
        })
      );
    };

    const onError = () => {};

    NON_TERM_SERVICES.NON_TERM_PROPOSER_OCCUPATION_DETAILS(
      onSuccess,
      onError,
      params
    );
  };
  const NON_TERM_PROPOSER_QUALIFICATION_DETAILS = () => {
    let params = {
      product_type_code: "NPAR",
      product_code: `${SELECTED_QUOTE_DATA.productDetails.product_code}`,
    };
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      const response = results.response;

      let data: TDropdown[] = response.map((item: any) => ({
        label: item.code,
        value: item.title,
      }));

      dispatch(
        NonTermSlice.actions.SET_DROPDOWN_DATA({
          key: "QUALIFICATION_DATA",
          value: data,
        })
      );
    };
    const onError = () => {};
    NON_TERM_SERVICES.NON_TERM_PROPOSER_QUALIFICATION_DETAILS(
      onSuccess,
      onError,
      params
    );
  };

  useEffect(() => {
    NON_TERM_PROPOSER_OCCUPATION_DETAILS();
    NON_TERM_PROPOSER_QUALIFICATION_DETAILS();
  }, []);
  return (
    <>
    {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        <MNonTermProposal
          proposerFields={proposerFields}
          fieldsUpdateState={fieldsUpdateState}
          validateForm={validateForm}
          loader={loader}
        />
      ) : (
        <NonTermProposal
          proposerFields={proposerFields}
          fieldsUpdateState={fieldsUpdateState}
          validateForm={validateForm}
          loader={loader}
        />
      )}
    </>
  );
};

export default NonTermBasicDetailsContainer;
