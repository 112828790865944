import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useEffect, useState } from "react";
import GenderRadio from "../../../../../Component/Global/GenderRadio/GenderRadio";
import DatePicker from "../../../../../Component/InputFields/DatePicker/DatePicker";
import JDSButtons from "../../../../../Component/InputFields/JDSButtons/JDSButtons";
import "../../../../../SCSS/ModalPopup.scss";
import { NON_TERM_SERVICES } from "../../../../../Services/NonTerm/NonTermFormService";
import { NonTermSlice } from "../../../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";

const NonTermModifyDetails = ({
  open,
  setOpen,
  setQuoteLoader,
  GET_PRODUCT_LIST,
}: {
  open: boolean;
  setOpen: Function;
  setQuoteLoader: Function;
  GET_PRODUCT_LIST: Function;
}) => {
  const dispatch = useAppDispatch();
  const { ADD_FORM, ADD_FORM_RESPONSE, QUOTE_FIELDS_DATA, UPDATE_RESPONSE } =
    useAppSelector((state) => state.NonTerm);

  const { NonTerm } = useAppSelector((state) => state);
  const [gender, setGender] = useState(ADD_FORM.gender.value);
  const [dob, setDob] = useState(ADD_FORM.age.value);

  const updatePlanDetails = () => {
    setQuoteLoader(true);
    dispatch(
      NonTermSlice.actions.NON_TERM_UPDATE_ADD_FORM_DATA({
        ...ADD_FORM,
        age: {
          value: dob,
          warning: false,
        },
        gender: {
          value: gender,
          warning: false,
        },
      })
    );
    let params = {
      quote_no: `${ADD_FORM_RESPONSE?.quote_no}`,
      policy_term: `${QUOTE_FIELDS_DATA?.policyTerm}`,
      policy_paying_term: `${QUOTE_FIELDS_DATA?.payUpto}`,
      payment_mode: `${ADD_FORM.investmentMode.value}`,
      dob: dob,
      gender: gender,
      pincode: `${ADD_FORM_RESPONSE?.pincode}`,
    };

    dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    setOpen(false);
    const onSuccess = (res: any) => {
      GET_PRODUCT_LIST();
      if (!res.error) {
        GET_PRODUCT_LIST();
        // dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        dispatch(
          NonTermSlice.actions.UPDATE_RESPONSE_DATA({
            UPDATE_RESPONSE: { ...res?.response },
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };
    NON_TERM_SERVICES.updateNonTermDetails(onSuccess, onError, params);
  };

  const dob_date = UPDATE_RESPONSE.dob_date;
  const dob_month =  UPDATE_RESPONSE.dob_month
  const dob_year = UPDATE_RESPONSE.dob_year

  const formattedDate = `${dob_date}-${dob_month}-${dob_year}`;
  const closeModifyDetailsModel = () => {
    setGender(
      UPDATE_RESPONSE.gender === ""
        ? ADD_FORM.gender.value
        : UPDATE_RESPONSE.gender
    );
    setDob(
      UPDATE_RESPONSE.dob_date === "" ? ADD_FORM.age.value : formattedDate
    );
  };
  useEffect(() => {
    if (UPDATE_RESPONSE) {
      setDob(
        UPDATE_RESPONSE.dob_date === "" ? ADD_FORM.age.value : formattedDate
      );
      if (UPDATE_RESPONSE.gender) {
        setGender(UPDATE_RESPONSE.gender);
      }
    }
  }, [UPDATE_RESPONSE]);

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box
          sx={{
            transform: "translate(0,0)",
            transition: "trapnsform 0.3s",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box className="modalContent xsWidth">
            <Grid className="topbar" container alignItems="center">
              <Grid xs={12} textAlign={"right"}>
                <Button
                  disableRipple
                  className="closePopup"
                  onClick={() => {
                    setOpen(false);
                    closeModifyDetailsModel();
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignContent="flex-start">
              <Grid xs={12}>
                <h3>Personal details</h3>
              </Grid>
              <Grid xs={12}>
                <h5 style={{ marginBottom: "8px" }}>Select Gender</h5>
                <GenderRadio
                  value={gender}
                  value_update={(a: string, v: string) => {
                    setGender(v);
                  }}
                />
              </Grid>
              <Grid xs={12}>
                <DatePicker
                  title={"Date of Birth"}
                  value={dob}
                  attrName={"age"}
                  onChange={(a: any, v: any) => {
                    setDob(v);
                  }}
                  warn_status={ADD_FORM.age.warning}
                  error_message="Select DOB"
                  min_date={65}
                  max_date={18}
                  default_date={new Date("1990-01-01")}
                />
              </Grid>
            </Grid>

            <Box
              className="mt-6"
              sx={{
                maxWidth: "336px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <JDSButtons
                className="primaryBtn large"
                text="Update Plan"
                variant="text"
                loader={false}
                onClick={() => {
                  updatePlanDetails();
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default NonTermModifyDetails;
