import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CarForm from "../../../Page/Desktop/Car/Form/CarForm";
import MCarForm from "../../../Page/Mobile/Car/Form/MCarForm";
import { CAR_ROUTES } from "../../../Router/Path/CarRoutes";
import { CAR_ADD_FORM_SERVICES } from "../../../Services/Car/CarAddFormServices";
import { UserProfileSlice } from "../../../Store/Slice_Reducer/Account/UserProfileSlice";
import { CarSlice } from "../../../Store/Slice_Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  FORMAT_YYYY_MM_DD,
  isEmpty,
  validateMobileNumber,
  validateRegNoBharat,
  validateRegno,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import {
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../../utils/analytics";

function CarFormContainer({
  open,
  setOpen,
  pageStatus,
  setPageStatus,
}: {
  open: boolean;
  setOpen: Function;
  pageStatus: number;
  setPageStatus: Function;
}) {
  const isMobile = useIsMobile();
  const { Car } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);
  const [vehicleInfoStatus, setVehicleInfoStatus] = useState<boolean>(false);
  const { ADD_FORM, REDIRECTED } = useAppSelector((state) => state.Car);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (open) {
      GET_RTO_LIST();
      GET_MAKE_LIST();
      GET_PREV_INSURER_LIST();
    }
  }, [open]);

  useEffect(() => {
    GET_MODEL_LIST();
  }, [ADD_FORM.make.value]);

  useEffect(() => {
    GET_FUEL_TYPE_LIST();
  }, [ADD_FORM.make.value, ADD_FORM.model.value]);

  useEffect(() => {
    GET_VARIANT_LIST();
  }, [ADD_FORM.make.value, ADD_FORM.model.value, ADD_FORM.fuel_type.value]);

  const GET_PREV_INSURER_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          short_name?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.short_name === "string") {
            data.push({
              label: `${value.short_name}`,
              value: `${value.short_name}`,
            });
          }
        });

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "PREVIOUS_INSURER_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_PREVIOUS_INSURER_LIST(onError, onSuccess);
  };

  const GET_RTO_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        const data: Array<{ label: string; value: string }> = [];

        Object.entries(response).map(([brand, value]) =>
          data.push({ label: `${brand} - ${value}`, value: brand })
        );

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "RTO_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_RTO_LIST(onError, onSuccess);
  };

  const GET_MAKE_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          make?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.make === "string") {
            data.push({ label: `${value.make}`, value: `${value.make}` });
          }
        });

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "MAKE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_MAKE_LIST(onError, onSuccess);
  };

  const GET_MODEL_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          make_model?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.make_model === "string") {
            data.push({
              label: `${value.make_model}`,
              value: `${value.make_model}`,
            });
          }
        });

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "MODEL_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_MODEL_LIST(
      onError,
      onSuccess,
      `${ADD_FORM.make.value}`
    );
  };

  const GET_FUEL_TYPE_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          fuel_type?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.fuel_type === "string") {
            data.push({
              label: `${value.fuel_type}`,
              value: `${value.fuel_type}`,
            });
          }
        });

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "FUEL_TYPE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(
      onError,
      onSuccess,
      `${ADD_FORM.make.value} ${ADD_FORM.model.value}`
    );
  };

  const GET_VARIANT_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          variant_cc?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.variant_cc === "string") {
            data.push({
              label: `${value.variant_cc}`,
              value: `${value.variant_cc}`,
            });
          }
        });

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "VARIANT_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_VARIANT_LIST(
      onError,
      onSuccess,
      `${ADD_FORM.make.value} ${ADD_FORM.model.value}`,
      `${ADD_FORM.fuel_type.value}`
    );
  };

  const updateMasterState = (attrName: string, value: any) => {
    switch (attrName) {
      case "business_type":
        dispatch(
          CarSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            [attrName]: value,
          })
        );
        break;
      case "reg_no":
        dispatch(
          CarSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            [attrName]: { value: value, warning: !validateRegno(value) },
          })
        );
        break;
      case "make":
        pushMotorDataLayer(
          "jioinsure_flow",
          "vehicle brand",
          `${value}`,
          "car",
          REDIRECTED ? "JFS app" : "Insure website"
        );
        sendEventToNativeApp("jioinsure_flow", {
          action: "vehicle brand",
          click: `${value}`,
          type: "car",
          EntrySource: REDIRECTED ? "JFS app" : "Insure website",
        });
        dispatch(
          CarSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            make: { value: value, warning: isEmpty(value) },
            model: { value: "", warning: false },
            make_model: { value: "", warning: false },
            fuel_type: { value: "", warning: false },
            variant_cc: { value: "", warning: false },
          })
        );
        if (!isEmpty(value)) move_page("FORWORD");
        break;

      case "rto":
        pushMotorDataLayer(
          "jioinsure_flow",
          "RTO details",
          "proceed",
          "car",
          REDIRECTED ? "JFS app" : "Insure website"
        );
        sendEventToNativeApp("jioinsure_flow", {
          action: "RTO details",
          click: "proceed",
          type: "car",
          EntrySource: REDIRECTED ? "JFS app" : "Insure website",
        });
        dispatch(
          CarSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            [attrName]: { value: value, warning: isEmpty(value) },
          })
        );

        if (!isEmpty(value)) move_page("FORWORD");
        break;

      case "model":
        pushMotorDataLayer(
          "jioinsure_flow",
          "vehicle model",
          `${value}`,
          "car",
          REDIRECTED ? "JFS app" : "Insure website"
        );

        sendEventToNativeApp("jioinsure_flow", {
          action: "vehicle model",
          click: `${value}`,
          type: "car",
          EntrySource: REDIRECTED ? "JFS app" : "Insure website",
        });
        dispatch(
          CarSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            model: { value: value, warning: isEmpty(value) },
            make_model: { value: value, warning: isEmpty(value) },
            fuel_type: { value: "", warning: false },
            variant_cc: { value: "", warning: false },
          })
        );
        if (!isEmpty(value)) move_page("FORWORD");
        break;
      case "fuel_type":
        pushMotorDataLayer(
          "jioinsure_flow",
          "fuel type",
          `${value}`,
          "car",
          REDIRECTED ? "JFS app" : "Insure website"
        );
        sendEventToNativeApp("jioinsure_flow", {
          action: "fuel type",
          click: `${value}`,
          type: "car",
          EntrySource: REDIRECTED ? "JFS app" : "Insure website",
        });
        dispatch(
          CarSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            fuel_type: { value: value, warning: isEmpty(value) },
            variant_cc: { value: "", warning: false },
          })
        );
        if (!isEmpty(value)) move_page("FORWORD");
        break;
      case "variant_cc":
        dispatch(
          CarSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            variant_cc: { value: value, warning: false },
          })
        );
        if (!isEmpty(value)) move_page("FORWORD");
        break;
      case "claimed":
        if (value === "Yes") {
          dispatch(
            CarSlice.actions.ADD_FORM_DATA({
              ...ADD_FORM,
              [attrName]: value,
              prev_ncb: "0",
            })
          );
        } else {
          dispatch(
            CarSlice.actions.ADD_FORM_DATA({
              ...ADD_FORM,
              [attrName]: value,
            })
          );
        }
        break;
      case "previous_insurer":
        dispatch(
          CarSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            previous_insurer: { value: value, warning: isEmpty(value) },
          })
        );
        break;
      case "prev_ncb":
        dispatch(
          CarSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            prev_ncb: value,
          })
        );
        break;

      case "reg_date":
        dispatch(
          CarSlice.actions.BULK_UPDATE({
            ...Car,
            ADD_FORM: {
              ...ADD_FORM,
              reg_date: { value: value, warning: isEmpty(value) },
              policy_expiry_date: { value: "", warning: false },
            },
            // DROPDOWN_DATA:{...Car.DROPDOWN_DATA,PREVIOUS_POLICY_TYPE:DETERMINE_PLAN_TYPE_OPTIONS()}
          })
        );

        break;

      case "mobile":
        dispatch(
          CarSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            mobile: { value: value, warning: !validateMobileNumber(value) },
          })
        );
        break;

      default:
        dispatch(
          CarSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            [attrName]: { value: value, warning: isEmpty(value) },
          })
        );
        break;
    }
  };

  const move_page = (status: "FORWORD" | "BACKWORD") => {
    if (status === "BACKWORD") {
      if (ADD_FORM.business_type === "New") {
        if (pageStatus === 8) {
          setPageStatus(5);
        } else {
          setPageStatus((prev: number) => {
            return prev - 1;
          });
        }
      } else {
        setPageStatus((prev: number) => {
          return prev - 1;
        });
      }
    } else {
      setPageStatus((prev: number) => {
        if (status === "FORWORD" && prev < 8) {
          if (ADD_FORM.business_type === "New") {
            if (pageStatus === 5) {
              return 8;
            }
            return prev + 1;
          } else {
            return prev + 1;
          }
        } else {
          return prev;
        }
      });
    }
  };

  const validateForm = () => {
    let data = { ...ADD_FORM };
    if (pageStatus === 1) {
      if (ADD_FORM.business_type === "Rollover") {
        data = {
          ...data,
          reg_no: {
            ...data.reg_no,
            warning: !validateRegno(`${data.reg_no.value}`),
          },
          reg_date: { ...data.reg_date, warning: isEmpty(data.reg_date.value) },
          rto: { ...data.rto, warning: isEmpty(data.rto.value) },
        };

        if (validateRegNoBharat(`${data.reg_no.value}`)) {
          if (
            !data.reg_no.warning &&
            !data.reg_date.warning &&
            !data.rto.warning
          ) {
            // GET_VEHICLE_INFO();
          }
        } else if (!data.reg_no.warning && !data.reg_date.warning) {
          // GET_VEHICLE_INFO();
        }
      } else {
        data = {
          ...data,
          rto: {
            ...data.rto,
            warning: isEmpty(`${data.rto.value}`),
          },
        };

        if (!data.rto.warning) {
          move_page("FORWORD");
        }
      }
    } else if (pageStatus === 2) {
      data = {
        ...data,
        make: {
          ...data.make,
          warning: isEmpty(data.make.value),
        },
        make_model: {
          value: "",
          warning: false,
        },
      };

      if (!data.make.warning) {
        move_page("FORWORD");
      }
    } else if (pageStatus === 3) {
      data = {
        ...data,
        model: {
          ...data.model,
          warning: isEmpty(data.model.value),
        },
        make_model: {
          value: "",
          warning: false,
        },
      };

      if (!data.model.warning) {
        move_page("FORWORD");
      }
    } else if (pageStatus === 4) {
      data = {
        ...data,
        fuel_type: {
          ...data.fuel_type,
          warning: isEmpty(data.fuel_type.value),
        },
      };

      if (!data.fuel_type.warning) {
        move_page("FORWORD");
      }
    } else if (pageStatus === 5) {
      data = {
        ...data,
        variant_cc: {
          ...data.variant_cc,
          warning: isEmpty(data.variant_cc.value),
        },
      };

      if (!data.variant_cc.warning) {
        move_page("FORWORD");
      }
    } else if (pageStatus === 6) {
      if (data.business_type === "Rollover") {
        data = {
          ...data,
          reg_date: {
            ...data.reg_date,
            warning: isEmpty(data.reg_date.value),
          },
          policy_expiry_date: {
            ...data.policy_expiry_date,
            warning: isEmpty(data.policy_expiry_date.value),
          },
          previous_insurer: {
            ...data.previous_insurer,
            warning: isEmpty(data.previous_insurer.value),
          },
        };

        if (
          !data.reg_date.warning &&
          !data.policy_expiry_date.warning &&
          !data.previous_insurer.warning
        ) {
          pushMotorDataLayer(
            "jioinsure_flow",
            "previous insurance details",
            "proceed",
            "car",
            REDIRECTED ? "JFS app" : "Insure website"
          );
          const eventProps = {
            action: "previous insurance details",
            click: "proceed",
            type: "car",
            EntrySource: REDIRECTED ? "JFS app" : "Insure website",
          };
          sendEventToNativeApp("jioinsure_flow", eventProps);
          move_page("FORWORD");
        }
      } else {
        data = {
          ...data,
          reg_date: {
            ...data.reg_date,
            warning: isEmpty(data.reg_date.value),
          },
          policy_expiry_date: {
            ...data.policy_expiry_date,
            warning: isEmpty(data.policy_expiry_date.value),
          },
          previous_insurer: {
            ...data.previous_insurer,
            warning: isEmpty(data.previous_insurer.value),
          },
        };
        if (
          !data.reg_date.warning &&
          !data.policy_expiry_date.warning &&
          !data.previous_insurer.warning
        ) {
          pushMotorDataLayer(
            "jioinsure_flow",
            "previous insurance details",
            "proceed",
            "car",
            REDIRECTED ? "JFS app" : "Insure website"
          );
          const eventProps = {
            action: "previous insurance details",
            click: "proceed",
            type: "car",
            EntrySource: REDIRECTED ? "JFS app" : "Insure website",
          };
          sendEventToNativeApp("jioinsure_flow", eventProps);
          move_page("FORWORD");
        }
      }
    } else if (pageStatus === 7) {
      pushMotorDataLayer(
        "jioinsure_flow",
        "claim history",
        "proceed",
        "car",
        REDIRECTED ? "JFS app" : "Insure website"
      );
      const eventProps = {
        action: "claim history",
        click: "proceed",
        type: "car",
        EntrySource: REDIRECTED ? "JFS app" : "Insure website",
      };
      sendEventToNativeApp("jioinsure_flow", eventProps);
      POST_CAR_FORM();
    } else if (pageStatus === 8) {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
      };

      if (!data.mobile.warning) {
        pushMotorDataLayer(
          "jioinsure_flow",
          "contact details",
          "submit",
          "car",
          REDIRECTED ? "JFS app" : "Insure website"
        );
        const eventProps = {
          action: "contact details",
          click: "submit",
          type: "car",
          EntrySource: REDIRECTED ? "JFS app" : "Insure website",
        };
        sendEventToNativeApp("jioinsure_flow", eventProps);
        POST_CAR_FORM();
      }
    }

    dispatch(CarSlice.actions.ADD_FORM_DATA(data));
  };

  const backFunction = () => {
    if (ADD_FORM.business_type === "New" && pageStatus === 1) {
      dispatch(
        CarSlice.actions.ADD_FORM_DATA({
          ...ADD_FORM,
          business_type: "Rollover",
          mobile: ADD_FORM.mobile,
        })
      );
    } else if (vehicleInfoStatus) {
      setPageStatus(2);
      setVehicleInfoStatus(false);
      dispatch(
        CarSlice.actions.ADD_FORM_DATA({
          ...ADD_FORM,
          make: { value: "", warning: false },
          model: { value: "", warning: false },
          make_model: { value: "", warning: false },
          fuel_type: { value: "", warning: false },
          variant_cc: { value: "", warning: false },
        })
      );
    } else {
      move_page("BACKWORD");
    }
  };

  const POST_CAR_FORM = () => {
    const onSuccess = (res: any) => {
      const quote_no = res?.response;
      const error = res?.error;
      const message = res?.message;
      setLoader(false);
      if (!error) {
        window.location.href = `${FRONTEND_DOMAIN}${CAR_ROUTES.QUOTE}?type=CAR&quote_no=${quote_no}&redirected=${Car.REDIRECTED}`;
      } else {
        toast.error(`${message}`);
      }
    };

    const onError = (err: any) => {
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    };

    const data = {
      carBusinessType: ADD_FORM.business_type,
      carClaimMade: ADD_FORM.claimed,
      carFuelType: ADD_FORM.fuel_type.value,
      carMakeModel: `${ADD_FORM.make.value} ${ADD_FORM.model.value}`,
      carMobile: ADD_FORM.mobile.value,
      carPExpiryDate: isEmpty(
        FORMAT_YYYY_MM_DD(`${ADD_FORM.policy_expiry_date.value}`)
      )
        ? ""
        : FORMAT_YYYY_MM_DD(`${ADD_FORM.policy_expiry_date.value}`),
      carPreviousInsurer: ADD_FORM.previous_insurer.value,
      carPreviousNCB: ADD_FORM.prev_ncb,
      carRegDate: isEmpty(FORMAT_YYYY_MM_DD(`${ADD_FORM.reg_date.value}`))
        ? ""
        : FORMAT_YYYY_MM_DD(`${ADD_FORM.reg_date.value}`),
      carRegNo: ADD_FORM.reg_no.value,
      carRtoCode:
        ADD_FORM.business_type === "New"
          ? `${ADD_FORM.rto.value}`.split("-")[0]
          : "",
      carVariantCC: ADD_FORM.variant_cc.value,
      carPincode: "",
      utm_source: REDIRECTED ? "JFS" : "DIRECT",
    };
    setLoader(true);
    CAR_ADD_FORM_SERVICES.GENERATE_QUOTE_NO(onSuccess, onError, data);
  };

  return (
    <>
      {isMobile ? (
        <MCarForm
          backFunction={backFunction}
          pageStatus={pageStatus}
          setPageStatus={setPageStatus}
          setVehicleInfoStatus={setVehicleInfoStatus}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          vehicleInfoStatus={vehicleInfoStatus}
          open={open}
          setOpen={setOpen}
          loader={loader}
        />
      ) : (
        <CarForm
          backFunction={backFunction}
          pageStatus={pageStatus}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
}

export default CarFormContainer;
