import moment from "moment";
import { useState } from "react";
import { AddTermDTO } from "../../../DTO/TermDTO/AddTermFormDTO";
import TermForm from "../../../Page/Desktop/Term/Form/TermForm";
import { TERM_ROUTES } from "../../../Router/Path/TermRoutes";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { UserProfileSlice } from "../../../Store/Slice_Reducer/Account/UserProfileSlice";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  FORMAT_DD_MM_YYYY,
  isEmpty,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import { TTermAddForm } from "../../../types/Term/TTermAddForm";
import { toast } from "react-toastify";
import MTermForm from "../../../Page/Mobile/Term/MForm/MTermForm";

function TermFormContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) {
  const isMobile = useIsMobile();
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { Term } = useAppSelector((state) => state);

  const validateForm = () => {
    let data: TTermAddForm = { ...Term.ADD_FORM };

    data = {
      ...data,
      annualIncome: {
        ...data.annualIncome,
        warning: isEmpty(data.annualIncome.value),
      },
      pincode: {
        ...data.pincode,
        warning: !validatePincode(data.pincode.value),
      },
    };

    if (!data.annualIncome.warning && !data.pincode.warning) {
      add_form(data);
    }
    dispatch(TermSlice.actions.UPDATE_ADD_FORM_DATA({ ...data }));
  };

  const add_form = (data: TTermAddForm) => {
    const onSuccess = (res: any) => {
      const results = res;
      const response = results?.response;
      const error = results?.error;
      if (!error) {
        const quote_no = response?.quote_no;
        window.location.href = `${FRONTEND_DOMAIN}${TERM_ROUTES.QUOTE}?type=TM&quote_no=${quote_no}&redirected=${Term.REDIRECTED}`;
      } else {
        toast.error(`${results?.message}`);
      }

      setLoader(false);
    };
    const onError = (err: any) => {
      console.log("err", err);
      setLoader(false);
    };

    let params = {
      lifeInsurance_module: "TermPlan",
      lifeInsurance_type: "Term Plan",
      termSmoker: data.smoke,
      termAnnualIncome: `${data.annualIncome.value}`,
      termAnnualIncomeOther: `${data.annualIncome.value}`,
      termGender: data.gender.value,
      termDob: `${FORMAT_DD_MM_YYYY(data.age.value)}`,
      termName: data.name.value,
      termMobile: data.mobile.value,
      termPincode: data.pincode.value,
    };

    setLoader(true);
    TERM_SERVICES.addTermFormData(onSuccess, onError, params);
  };

  return (
    <>
      {isMobile ? (
        <MTermForm
          open={open}
          setOpen={setOpen}
          validateForm={validateForm}
          loading={loader}
        />
      ) : (
        <TermForm
          open={open}
          setOpen={setOpen}
          validateForm={validateForm}
          loading={loader}
        />
      )}
    </>
  );
}

export default TermFormContainer;
