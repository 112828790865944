import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import styles from "../../../SCSS/CMSPages.module.scss";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

function GrievanceRedressal() {
  const isMobile = useIsMobile();
  return (
    <Box className={styles.cmsWrapper}>
      {isMobile ? <MNavBar /> : <NavBar />}

      <Grid container spacing={3} className={styles.cmsPages}>
        <Grid xs={12}>
          <h2>Grievance Redressal</h2>
          <p>
            Jio Insurance Broking Limited, hereinafter referred as the
            “Company”, is concerned about the complaint/grievance of the users
            and/or clients.
          </p>
          <p>
            This grievance redressal aims at minimizing the instances of
            customer complaints and grievances through proper service delivery
            and review mechanism to ensure prompt redressal of customer
            complaints and grievances. The review mechanism is aimed at helping
            in identification of shortcomings in service delivery, if any, and
            compliance with the stipulations of IRDAI Regulations.
          </p>
          <p>
            To ensure that the company's grievance redressal mechanism is more
            meaningful and effective, a system/ process has been designed. Such
            system would ensure that the redressal provided is just and fair and
            is permissible within the given framework of the rules and
            regulations. This grievance redressal would be made available at all
            regional offices/branch offices of the company. The concerned
            employees in the respective branch offices shall be made aware about
            the complaints/ grievance handling process.
          </p>
          <h3>Process </h3>
          <p>The process followed for addressing queries shall be: </p>
          <ul>
            <li>
              For any grievance, please contact us with the details by sending
              an email to the Grievance team, on{" "}
              <Link href="mailto:customer.care@jioinsure.in">
                customer.care@jioinsure.in
              </Link>
              .
            </li>
            <li>
              You can also write to Jio Insurance Broking Ltd. Reliance
              Corporate Park, Building No. 5A, 1<sup>st</sup> Floor, Thane
              Belapur Road, Navi Mumbai - 400 701.
            </li>
            <li>
              The grievance will be acknowledged within 2 working days of its
              receipt.
            </li>
            <li>
              The company shall exercise all efforts to address it, including
              intimation to the respective Insurance company for a quick
              redressal.
            </li>
            <li>
              If the decision/resolution provided by the Grievance Team is not
              acceptable, please approach the Principal Officer of the Company
              at{" "}
              <Link href="mailto:principal.officer@jioinsure.in">
                principal.officer@jioinsure.in
              </Link>
            </li>
            <li>
              If the decision/resolution provided by the Principal Officer is
              not acceptable, the grievance may be further escalated as per the
              process specified by Insurance Regulatory Development Authority of
              India (IRDAI) in the below mentioned link:
            </li>
            <Link href="https://www.policyholder.gov.in/report.aspx#">
              https://www.policyholder.gov.in/report.aspx#
            </Link>
          </ul>
          <p>
            In case of no reply from the Complainant, within 2 weeks from the
            date any clarification was provided, the Company shall treat the
            Complaint as Closed.
          </p>
          <h3>Disclaimer:</h3>
          <p>
            Jioinsure.in is an insurance domain owned by Jio Insurance Broking
            Ltd.
          </p>
          <p>
            <b>Registered Office:</b> 3<sup>rd</sup> Floor, Court House,
            Lokmanya Tilak Marg, Dhobi Talao, Mumbai - 400 002
          </p>
          <p>
            <b>Corporate Office:</b> 1<sup>st</sup> Floor, B wing, Building No.
            25, DAKC, Thane Belapur Road, Koparkhairane, Mumbai
          </p>
          <p>
            <b>CIN No.:</b> U67200MH2006PLC165651
          </p>
          <p>
            <b>IRDA License No.:</b> 347, Direct Broker (Life & General), valid
            up to 11/03/2025 (Renews every 3 years)
          </p>
        </Grid>
      </Grid>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default GrievanceRedressal;
