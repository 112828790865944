import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import styles from "../../../SCSS/CMSPages.module.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

function PrivacyPolicy() {
  const isMobile = useIsMobile();
  return (
    <Box className={styles.cmsWrapper}>
      {isMobile ? <MNavBar /> : <NavBar />}

      <Grid container spacing={3} className={styles.cmsPages}>
        <Grid xs={12}>
          <h2>Privacy Policy</h2>
          <h4 style={{ marginBottom: "20px" }}>
            PRIVACY POLICY FOR JIO INSURANCE BROKING LIMITED (Formerly known as
            Reliance Retail Insurance Broking Limited)
          </h4>
          <ol>
            <li>INTRODUCTION</li>
            <p>
              This Privacy Policy informs you of the manner in which Jio
              Insurance Broking Ltd. <b>("JIBL", "we", "us" or "our")</b>{" "}
              collects, uses, accesses, maintains, processes, discloses,
              transfers and stores Personal Data (defined below) collected from
              you <b>("you, your or "User")</b>, on our Platform, (i.e.,
              website/mobile application) with the URL:{" "}
              <Link href="https://jioinsure.in/">www.jioinsure.in</Link> website{" "}
              <b>("Site")</b> or the mobile application "JioFinance"
              (hereinafter referred to as the <b>"Platform"</b>). Please take a
              moment to familiarize yourself with the <b>"Privacy Policy"</b> or
              "Policy".
            </p>

            <p>
              JIBL is committed to maintaining the confidentiality, integrity
              and security of all information of our Users. This Privacy Policy
              describes how JIBL collects and handles certain information it may
              collect and/or receive from you via the use of the Platform.
              Please see below for details on what type of information we may
              collect from you, how that information is used in connection with
              the services offered through our Platform and relevant information
              shared with our authorized business partners only for the purpose
              of insurance policy solicitation process, pre/post-sale servicing,
              claim servicing and providing you any other services as
              permissible under applicable laws. This Privacy Policy applies to
              current and former visitors to our Platform and our clients. By
              visiting and/or using our Platform, you agree to this Privacy
              Policy.
            </p>

            <p>
              During the insurance lifecycle, JIBL may receive Personal Data
              relating to potential or actual policyholders, beneficiaries under
              an insurance policy, their family members, claimants, and other
              parties involved in a claim. Therefore, references to "you" in
              this Privacy Policy include any natural person from the preceding
              list, whose Personal Data JIBL receives in connection with the
              services it provides under its engagements with its clients/Users.
            </p>

            <p>
              By using and registering at the Platform, you authorize JIBL
              (including its authorized representatives and Partners) to contact
              you via email or phone call or SMS or WhatsApp in case you show
              your interest in any of the products and offer you our services
              for the product you have opted for, imparting product knowledge
              for which reasons your information may be collected in the manner
              as detailed under this Policy. You hereby agree that you authorize
              JIBL to contact you on your mobile number for the above-mentioned
              purposes even if you have registered yourself under DND or DNC or
              NCPR service(s). You also authorize us to contact you on the
              WhatsApp number. Your authorization, in this regard, shall be
              valid as long as your account is not deactivated by either you or
              us.
            </p>

            <li>DEFINITIONS</li>

            <p>
              <b>Applicable Law</b>
              means, and includes the Information Technology Act, 2000,
              Information Technology (Reasonable Security Practices and
              Procedures and Sensitive Personal Data or Information) Rules 2011,
              the Digital Personal Data Protection Act 2023 as and when
              enforced, each as amended from time to time, as well as the
              Insurance Act 1938 and rules, regulations, circulars and
              guidelines of the Insurance Regulatory and Development Authority
              of India;
            </p>

            <p>
              <b>Cookies</b>
              means a small file placed on your device by our website or mobile
              application when you either visit or use certain features of our
              website or mobile application, which allows a website or
              application to remember your actions or preference for a certain
              period of time;
            </p>

            <p>
              <b>Service(s)</b>
              means any insurance intermediation services or any other service
              ancillary to the insurance business including displaying
              information from the customer’s loan accounts, deposits, bank
              accounts, demat accounts, e-insurance accounts, etc. for the
              purpose of giving the customer a holistic view of their financial
              position with analytical insights and data offered by us on the
              Platform, subject to compliance with the Insurance Regulatory
              Development Authority (Insurance Broker) Regulations 2018 (as
              amended from time to time).
            </p>

            <p>
              <b>Partners</b>
              means JIBL’s affiliates and group companies, including Jio
              Payments Bank Limited, as well as our business partners, with whom
              we may have contracts for the businesses described in this Privacy
              Policy;
            </p>

            <p>
              <b>Platform</b>
              means the website, mobile application, page, interface, and any
              other technology platform owned and operated by JIBL.
            </p>

            <p>
              <b>Personal Data</b>
              shall have the same meaning as that under Applicable Law;
            </p>

            <p>
              <b>Representatives</b>
              means the personnel employed or otherwise engaged by JIBL for the
              purpose of their respective businesses.
            </p>

            <li>WHAT DATA DO WE COLLECT FROM YOU AND FOR WHAT PURPOSES?</li>

            <p>
              We may collect your Personal Data including but not limited to
              your (a) contact details like name, email address, proof of
              address, mailing address, gender, job title, employment history,
              phone number, IP address, as well as relationship to the policy
              holder, insured, beneficiary or claimant, for the purposes of (I)
              creating your account with us and onboarding you as a User, (II)
              administering your account on a continued basis, (III) informing
              you about important details like JIBL digital platforms, change in
              terms, conditions and policies and/or other administrative
              information important to meet your interests, (IV) addressing your
              requests in relation to our Services from time to time, (V)
              informing you about loyalty programs or other offers as may be
              operated by us, and (VI) responding to your feedback and queries
              and manage your relationship with JIBL.
            </p>

            <p>
              We may also collect Personal Data in the course of exchanging
              claim forms, KYC documents, client mandate and proposal forms, for
              the purpose of processing insurance claims, in the course of
              providing you with our Services.
            </p>

            <p>
              To complying with Applicable Laws in the course of providing our
              Services, such as carrying out know-your-customer <b>("KYC")</b>{" "}
              requirements, we may require access to your devices’ camera,
              microphone, location or any other facility necessary for the
              purpose of on-boarding you as our customer, as well as copies of
              government issued identity cards like PAN, Passport, Aadhaar,
              drivers’ license, voter ID card, etc. which you may provide
              voluntarily as proof of address and/or identity for our third
              party customers. We may also collect details of Aadhaar number for
              verifying, authenticating and/or updating your Aadhaar number in
              accordance with the Aadhaar (Targeted Delivery of Financial and
              Other Subsidies, Benefits and Services) Act, 2016. Collection of
              your Aadhaar details is not a mandatory requirement for availing
              our Services unless required under any Applicable Law. We ensure
              that your Aadhaar number shall be disclosed only with your
              consent, and in a manner specified under Applicable Law.
            </p>

            <p>
              Insurance premiums are calculated by benchmarking your and your
              beneficiary’s as well as propensities for insured events to occur.
              This benchmarking enables JIBL and its Partners to analyse and
              compile information received from all insureds, beneficiaries, or
              claimants to model such propensities. To assess your eligibility
              for our Services in relation to which you may have shown interest
              in or requested for, including but not limited to offering group
              insurance cover and wellness corner offerings / health and
              wellness related information to your employer organization, we
              will require your health information, medical records,
              physiological, physical or psychological information, postal
              address, nationality, payment instrument information,
              transactions, transaction history, financial statements, income
              tax returns, credit information report, Permanent Account Number{" "}
              <b>("PAN")</b>, insurance policies, spending pattern or trends,
              and other similar data, age, sex, date of birth, marital status,
              occupation and travel history, as well as your current claims,
              which may include health data, criminal records data, or other
              information relevant for us to assess the risk with providing you
              our Services. JIBL may also use predictive techniques to assess
              information about you to understand fraud patterns, the
              probability of future losses or actually occurring in claim
              scenarios.
            </p>

            <p>
              We may collect your financial information, including your payment
              instrument details, such as bank account number, income, or other
              financial information for the purpose of facilitating your
              payments and transactions towards receiving our Services.
            </p>

            <p>
              Towards better understanding the risks associated with offering
              you our Services, including those of fraud, or other criminal
              activity, so as to prevent the occurrence of any offence on our
              Platform, we may also collect your credit history, credit score,
              information about alleged crimes and sanctions from relevant
              anti-fraud and sanctions databases, regulators or law enforcement
              agencies.
            </p>

            <p>
              To the extent permitted under Applicable Law, we may analyze the
              Personal Data inferred from your profile and behaviour through our
              data analytics on our Platform, to (I) personalize and improve
              your experience in availing our Services, (II) tailor any
              communications that we may send you that you may consent to
              receive from us, and (III) perform activities such as data
              analysis, audits, usage trends to determine the effectiveness of
              JIBL’s campaigns and which marketing activity led to your taking
              specific action on our Platform (for example, downloading the
              application). This includes the use of Cookies, pixels and similar
              technologies for marketing and/or other purposes such as
              identifying your preferences regarding marketing messages from us
              and third parties, as well as your communication preferences.
            </p>

            <p>
              <b>
                We may process your Personal Data as a financial information
                user with your consent collected through recognised account
                aggregator(s), in accordance with Applicable Law to provide you
                with our Services.
              </b>
            </p>

            <p>
              We may also collect your Personal Data as may be required under
              Applicable Law to comply with our legal, regulatory, or judicial
              obligations.
            </p>

            <li>SOURCES OF PERSONAL DATA</li>
            <p>
              We may collect Personal Data from you in a variety of ways,
              including, but not limited to, when Users visit our Platform,
              register on the Platform, place an order, subscribe to the
              newsletter, respond to a survey, fill out a form, and in
              connection with other activities, services, features, or resources
              we make available on our Platform. Personal Data would include
              name, email address, mailing address, or phone number. If a User
              transacts on the Site, we may collect additional Personal Data
              such as billing address, a credit / debit card number, expiration
              date, and / or other payment instrument details and tracking
              information from cheques or money orders.
            </p>

            <p>
              We collect Personal Data from various sources, including
              (depending on the country you are in):
            </p>

            <p>(a) Our Partners;</p>

            <p>
              (b) You and your family members, shared online or by telephone, or
              in written correspondence;
            </p>

            <p>(c) Your employers, where applicable;</p>

            <p>
              (d) In the event of a claim, third parties including the other
              party to the claim (claimant/ defendant);
            </p>

            <p>
              (e) Other insurance market participants, such as insurers,
              reinsurers and other intermediaries;
            </p>

            <p>
              (f) Anti-fraud databases and other third-party databases,
              including sanctions lists; and
            </p>

            <p>
              (g) Government agencies, such as vehicle registration authorities
              and tax authorities.
            </p>

            <p>
              We process the Personal Data that we collect either on our own, or
              through other sources, in accordance with, and for the purposes
              specified in this Privacy Policy.
            </p>

            <li>
              WHOM DO WE SHARE YOUR PERSONAL DATA WITH AND FOR WHAT PURPOSES?
            </li>

            <p>
              We may share, disclose, or transfer your Personal Data described
              above strictly in accordance with Applicable Law, for the
              following purposes:
            </p>

            <p>
              (a) To our affiliate entities, employees, consultants,
              contractors, independent service providers and other third-parties
              for providing our Services;
            </p>

            <p>
              (b) To our Partners to allow them to provide support services and
              technical services to us and to receive some of these services
              from them and to provide support services and technical services
              to them and to offer their products and services to you including
              offering combined products and services.
            </p>

            <p>
              (c) For administering activities such as detection of identity
              theft, fraud and other potentially illegal acts, to facilitate
              joint or co-branded services that you request where such services
              are provided by more than one corporate entity. We may share your
              information with these third parties for those limited purposes
              provided that you have given us your consent.
            </p>

            <p>
              (d) We may disclose Personal Data if required to do so by law or
              in the good faith belief that such disclosure is reasonably
              necessary to comply with our obligations under any Applicable Law
              or enforce our legal rights, pursue our legal remedies, respond to
              subpoenas, court orders, or any other legal process.
            </p>

            <p>
              (e) We may carry out research, development and analytics to better
              understand your preferences to personalise your experience on our
              Platform, and to better curate offerings on our Platform, from us,
              as well as from our Partners.
            </p>

            <p>
              (f) In the event we buy or acquire other businesses from third
              parties, or sell our business (or any verticals thereunder, by
              whatever name called) to a third party, or otherwise take actions
              to develop and grow our business, the Personal Data collected by
              us may be shared with third parties and/or statutory authorities
              in such instances towards organizing such acquisition, sale, or
              action.
            </p>

            <p>
              (g) If you choose to utilize the products and services offered by
              us and/or our Partners, we may share your Personal Data with such
              Partners. The collection and processing of your Personal Data by
              such Partners will be governed separately in their respective
              privacy policies.
            </p>

            <p>
              (h) We may share Personal Data with our Partners and affiliates to
              help us operate our business and the site or administer activities
              such as detection of identity theft, fraud and other potentially
              illegal acts, to facilitate joint or co-branded services that you
              request where such services are provided by more than one
              corporate entity. We may share your information with these third
              parties for those limited purposes only, where you voluntarily opt
              to avail such co-branded services.
            </p>

            <p>
              Other than as prescribed under this Privacy Policy, we will not
              disclose any User Personal Data to any external organization
              unless (i) we have obtained the consent of the User; or (ii) we
              are required by law to provide such information. We may use
              third-party advertising companies to serve ads when you visit our
              website. These companies may use information about your visits to
              this Site and other websites to provide advertisements about goods
              and services of interest to you. We do NOT share personally
              identifiable information with such third-party advertising
              companies.
            </p>

            <p>
              We only transfer your Personal Data outside India to the extent
              permitted, and strictly in accordance with Applicable Law. Users
              may find advertising or other content on our Site that link to the
              sites and services of our Partners and other third parties. We do
              not control the content or links that appear on these sites and
              are not responsible for the practices employed by websites linked
              to or from our Site. Browsing and interaction on any other
              website, including websites which have a link to our Site, is
              subject to that website’s own terms and policies.
            </p>

            <p>
              WE DO NOT SELL YOUR PERSONAL DATA WITH ANY THIRD PARTY FOR ANY
              PURPOSE WHATSOEVER.
            </p>

            <li>HOW LONG DO WE RETAIN YOUR PERSONAL DATA.</li>

            <p>
              (a) JIBL shall not retain any Personal Data longer than is
              required for the purposes specified in this Privacy Policy, unless
              required to be retained for a longer period under Applicable Law.
            </p>

            <p>
              (b) Your Personal Data may in certain cases have to be retained
              for legal, regulatory, accounting purposes which may include but
              not be limited to filing / addressing complaints, responding to
              any queries raised by any of our regulators / customers /
              governmental bodies and defending any legal claims.
            </p>

            <p>
              (c) In some circumstances, JIBL may anonymize your Personal Data
              so that it can no longer be associated with you and use such
              information.
            </p>

            <li>MEASURES WE UNDERTAKE TO SAFEGUARD YOUR PERSONAL DATA</li>

            <p>
              (a) We implement appropriate technical and organizational measures
              and implement reasonable security safeguards to protect your
              Personal Data against a breach, in accordance with Applicable Law.
            </p>

            <p>
              (b) We require the third parties with whom we share your Personal
              Data where necessary towards providing you with our Services to
              perform services on our behalf, in compliance with Applicable
              Laws.
            </p>

            <p>
              (c) Access to your Personal Data is limited to only those
              Representatives, agents, contractors and other third parties on a
              need-to-know basis. They will only process your Personal Data on
              JIBL’s instructions.
            </p>

            <p>
              (d) If you suspect any misuse or loss or unauthorized access to
              your Personal Data, please let us know immediately by contacting
              us by e-mail on the IDs set out below. If we decide to change our
              privacy policy, we will post those changes on this page so that
              you are always aware of what information we collect, how we use
              it, and under what circumstances we disclose it. We encourage you
              to frequently check for any change in these terms.
            </p>

            <li>YOUR RIGHTS UNDER DATA PROTECTION LAW</li>

            <p>
              (a) <b>Your Rights:</b> We provide you with the right to access
              and review your Personal Data, request for the correction,
              updation or completion of your Personal Data, or the erasure of
              your Personal Data, in accordance with Applicable Laws. When we
              process your Personal Data, we do so with your consent.
            </p>

            <p>
              (b) <b>Right to Access:</b> As part of your right to access, you
              also have the right to seek the following information: <br />
              (i) a summary of Personal Data which is being processed by us and
              the processing activities undertaken by us with respect to such
              Personal Data; <br />
              (ii) the identities of all other entities with whom the Personal
              Data has been shared by us, along with a description of the
              Personal Data so shared; and <br />
              (iii) any other information as prescribed under Applicable Laws.
            </p>

            <p>
              (c) <b>Right to Correction:</b> Your right to correction,
              encompasses your right to: <br />
              (i) Correct inaccurate or misleading Personal Data; <br />
              (ii) Complete incomplete Personal Data; and <br />
              (iii) Update your Personal Data.
            </p>

            <p>
              (d) <b>Right to Erasure:</b> You have the right to seek the
              erasure of your Personal Data, unless the retention of such data
              is necessary for the purposes specified in this Privacy Policy, or
              for compliance with any law for the time being in force.
            </p>

            <p>
              (e) <b>Right to Withdraw Consent:</b> You may withdraw or modify
              your consent to collect / provide / share / process the
              information (including your Personal Data) in accordance with
              Applicable Law, by communicating to the email ID of our Nodal
              Officer given below in writing. However, in such instance, if your
              Personal Data is necessary for us to provide you with the Services{" "}
              <br />
              (i) you may not be able to access some or all of the features or
              pages of the digital platform / applications; <br />
              (ii) you may not be able to avail some or all of our products or
              services; or <br />
              (iii) if you have already availed our products and services, such
              products or services may be withdrawn, curtailed or cancelled as
              per the terms thereof upon such withdrawal or modification of
              consent. Where you withdraw your consent, JIBL promptly
              communicates such withdrawal of consent to its Partners, so we can
              delete such data held by us on the basis of your consent, within a
              reasonable time period. For the purpose of ensuring that you are
              able to withdraw your consent which was previously provided to us,
              and for demonstrating compliance with Applicable Law, JIBL
              maintains an auditable record of such consents granted, modified
              or withdrawn.
            </p>

            <p>
              (f) <b>Right to Nominate:</b> You may also nominate any other
              individual, who shall, in the event of your death or incapacity,
              exercise your rights in accordance with Applicable Laws. This
              right may be exercised by contacting us at the email address
              provided below.
            </p>

            <p>
              (g) <b>Right to Grievance Redressal:</b> If you have any concerns
              or grievances, you may write to our grievance redressal officer,
              identified at the end of this Privacy Policy.
            </p>

            <p>
              (h) Right to File a Complaint. You also have the right to file
              complaints to the relevant regulatory authorities in connection
              with the protection of your Personal Data as notified on our
              Platform from time to time.
            </p>

            <li>YOUR REPRESENTATIONS TO US</li>
            <p>
              You represent to us that you are above the age of 18 and are in
              contracting capacity to access our Platform and share your
              Personal Data with us.
            </p>
            <p>
              In circumstances where you provide us with Personal Data relating
              to other individuals on their behalf (such as Personal Data
              relating to your family members, spouse, friends, or other
              parties), you represent and warrant that you have obtained such
              individual’s consent for, and hereby consent on behalf of such
              individual to, the collection, use, disclosure, and processing of
              their Personal Data by us. You confirm that the Personal Data that
              you provide to us of other individuals mentioned in this clause
              are complete, accurate and consistent.
            </p>
            <p>
              You also represent that any Personal Data that you share with us,
              relating to your nominees or co-applicants towards availing our
              Services, would be provided to us voluntarily by such persons, for
              the purposes specified in this Privacy Policy. You confirm that
              you have obtained the requisite consent of such person(s) for
              sharing their Personal Data with us. You also confirm that the
              Personal Data that you provide to us of other individuals
              mentioned in this clause are complete, accurate and consistent.
            </p>
            <li>COMPLAINTS AND GRIEVANCE REDRESSAL</li>
            <p>
              For any complaints, services related issues or policy, claim
              related queries / customer can mail to Customer.care@jioinsure.in
            </p>
            <p>
              For escalations, customers can write to us at
              principal.officer@jioinsure.in
            </p>
            <p>
              For more details on complaints and grievances, customers are
              requested to visit http://www.policyholder.gov.in/{" "}
            </p>
          </ol>
        </Grid>
      </Grid>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default PrivacyPolicy;
