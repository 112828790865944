import { NODE_DOMAIN, PHP_DOMAIN, STRAPI_DOMAIN } from "./Domain";
export const URL_CONSTANTS = {
  GENERATE_TOKEN: `${PHP_DOMAIN}users/generate-token.json`,

  // TWO WHEELER URL
  ADD_TWO_WHEELER: `${PHP_DOMAIN}tw-insurances/add.json`,
  MASTER_DATA_TWO_WHEELER_MAKE_AND_MODEL: `${PHP_DOMAIN}tw-master-datas/get-lists.json`,
  MASTER_DATA_TWO_WHEELER_FUEL_TYPE: `${PHP_DOMAIN}tw-master-datas/get-fuels.json`,
  MASTER_DATA_TWO_WHEELER_VARIANT: `${PHP_DOMAIN}tw-master-datas/get-variant-lists.json`,
  TWO_WHEELER_PRODUCT_LIST: `${PHP_DOMAIN}tw-insurances/get-product-lists.json`,
  TWO_WHEELER_PREVIOUS_INSURER_LIST: `${PHP_DOMAIN}previous-insurers/get-lists.json`,
  TWO_WHEELER_UPDATE_TWO_WHEELER_DETAIL: `${PHP_DOMAIN}tw-insurances/update-policy-details.json`,
  TWO_WHEELER_SINGLE_QUOTATION: `${PHP_DOMAIN}tw-insurances/get-single-quote.json`,
  TW_EMAIL_QUOTES: `${PHP_DOMAIN}tw-insurances/send-email-quote-page.json`,

  // CAR URL
  MASTER_DATA_CAR_MAKE_AND_MODEL: `${PHP_DOMAIN}car-master-datas/get-lists.json`,
  ADD_CAR_DATA: `${PHP_DOMAIN}car-insurances/add.json`,
  MASTER_DATA_CAR_VARIANT: `${PHP_DOMAIN}car-master-datas/get-variant-lists.json`,
  MASTER_DATA_CAR_FUEL_TYPE: `${PHP_DOMAIN}car-master-datas/get-fuels.json`,
  CAR_UPDATE_DETAIL: `${PHP_DOMAIN}car-insurances/update-policy-details.json`,
  CAR_SINGLE_QUOTATION: `${NODE_DOMAIN}api/v1/premium/global2`,
  CAR_QUOTE_ADDONS: `${PHP_DOMAIN}car-insurances/update-addon.json`,
  CAR_QUOTE_IDV: `${PHP_DOMAIN}car-insurances/update-idv.json`,
  UPDATE_CAR_ACCESSORIES: `${NODE_DOMAIN}/api/v1/premium/update-accessories`,
  UPDATE_CAR_DISCOUNT: `${NODE_DOMAIN}/api/v1/premium/update-discount-details`,
  CAR_GET_PRODUCT_LIST: `${PHP_DOMAIN}car-insurances/get-product-lists.json`,

  // Home
  LOAD_HOMEPAGE_DATA: `${STRAPI_DOMAIN}/api/homepage?populate=deep`,

  // term
  ADD_TERM_FORM: `${NODE_DOMAIN}/api/v1/term-plans/add.json`,
  TERM_QUOTES: `${PHP_DOMAIN}/api/term-plans/get-quotes.json`,
  TERM_QUOTES_PRODUCT: `${PHP_DOMAIN}/api/products/get-term-product-lists.json`,
  TERM_UPDATE_DETAILS: `${PHP_DOMAIN}/api/term-plans/update-record.json`,
  BUY_ACTION: `${PHP_DOMAIN}/api/term-plans/add-buy-now.json`,
  TERM_RIDERS_DETAILS: `${PHP_DOMAIN}/api/iprus-term-v1/get-rider-details.json`,
  PERPOSAL_TERM_DETAILS: `${PHP_DOMAIN}/api/term-plans/add-proposal-data.json`,
  PROPOSER_OCCUPATION_DETAILS: `${PHP_DOMAIN}/api/term-plans/get-occupation-list.json`,
  PROPOSER_QUALIFICATION_DETAILS: `${PHP_DOMAIN}/api/term-plans/get-education-list.json`,
  GET_REDIRECTION_URL: `${PHP_DOMAIN}/api/term-plans/get-proposal.json`,
  UPDATE_RIDERS_STATUS: `${PHP_DOMAIN}/api/term-plans/get-riders.json`,

  // non term
  // ADD_NON_TERM_FORM: `${PHP_DOMAIN}/api/investment-plans/add-investment.json`,
  // NON_TERM_QUOTES: `${PHP_DOMAIN}/api/investment-plans/get-quotes.json`,
  // NON_TERM_UPDATE_DETAILS: `${PHP_DOMAIN}/api/investment-plans/update-record.json`,
  // NON_TERM_BUY_ACTION: `${PHP_DOMAIN}/api/investment-plans/add-buy-now.json`,
  NON_TERM_PERPOSAL_TERM_DETAILS: `${PHP_DOMAIN}/api/investment-plans/add-proposal-data.json`,
  NON_TERM_GET_PROPOSAL_MAX_LIFE: `${NODE_DOMAIN}/api/v1/investment/get-proposal-journey`,
  // NON_TERM_UPDATE_RIDERS_STATUS: `${PHP_DOMAIN}/api/investment-plans/get-riders.json`,
  NON_TERM_GET_REDIRECTION_URL: `${NODE_DOMAIN}/api/v1/investment/get-proposal`,
  NON_TERM_PROPOSER_OCCUPATION_DETAILS: `${PHP_DOMAIN}/api/term-plans/get-occupation-list.json`,
  NON_TERM_PROPOSER_QUALIFICATION_DETAILS: `${PHP_DOMAIN}/api/term-plans/get-education-list.json`,

  // non term
  // ADD_TERM_FORM: `${PHP_DOMAIN}/api/term-plans/add.json`,
  // TERM_RIDERS_DETAILS: `${PHP_DOMAIN}/api/iprus-term-v1/get-rider-details.json`,
  // GET_IPRU_REDIRECTION_URL: `${PHP_DOMAIN}/api/iprus-term-v1/proposal.json`,
  // SEND_QUOTE_VIA_EMAIL: `${NODE_DOMAIN}/api/v1/premium/email`,
  // SEND_SINGLE_QUOTE_VIA_EMAIL: `${NODE_DOMAIN}/api/v1/premium/single-email`,
  // SEND_SINGLE_QUOTE_VIA_SMS: `${NODE_DOMAIN}/api/v1/premium/sms`,
  // GET_SINGLE_QUOTE: `${NODE_DOMAIN}/api/v1/term-plans/term-premium`,
  // TERM_PRODUCT_LIST: `${NODE_DOMAIN}/api/v1/term-plans/get-term-productlists`,
  // TERM_UPDATE_DETAILS: `${NODE_DOMAIN}/api/v1/term-plans/updatee-form`,
  // BUY_ACTION_LARAVEL: `${NODE_DOMAIN}/api/v1/term-plans/add-Buy-Now`,

  // non term
  ADD_NON_TERM_FORM: `${NODE_DOMAIN}/api/v1/term-plans/addform`,
  NON_TERM_UPDATE_DETAILS: `${NODE_DOMAIN}/api/v1/term-plans/update-investment-form`,
  NON_TERM_PRODUCT_LIST: `${NODE_DOMAIN}/api/v1/investment/get-investment-product-list`,
  GET_SINGLE_QUOTE: `${NODE_DOMAIN}/api/v1/investment/get-quotes`,
  NON_TERM_BUY_ACTION: `${NODE_DOMAIN}/api/v1/investment/add-buy-now`,
  NON_TERM_UPDATE_RIDERS_STATUS: `${NODE_DOMAIN}/api/v1/investment/get-riders`,

  //Account
  ACCOUNT_PROFILE: `${PHP_DOMAIN}/api/users/capture-customer-details.json`,
  GET_PROFILE: `${PHP_DOMAIN}/api/users/get-lists.json`,

  // ACCOUNT_VERIFY_MOBILE: `${PHP_DOMAIN}/api/users/send-otp-account-section.json`,
  // ACCOUNT_VERIFY_RESEND_OTP: `${PHP_DOMAIN}/api/users/resend-otp-account-section.json`,
  // ACCOUNT_VERIFY_OTP: `${PHP_DOMAIN}/api/users/verify-otp-account-section.json`,

  ACCOUNT_VERIFY_MOBILE: `${NODE_DOMAIN}/api/v1/premium/send-otp`,
  ACCOUNT_VERIFY_RESEND_OTP: `${NODE_DOMAIN}/api/v1/premium/otp-resend`,
  ACCOUNT_VERIFY_OTP: `${NODE_DOMAIN}/api/v1/premium/otp-verify`,
};
