import canUseDOM from "can-use-dom";

function isIOS(): boolean {
  return /iPad|iPhone|iPod/.test(window.navigator.userAgent);
}

export const SendEventToApp = (payload: any) => {
  if (canUseDOM) {
    const stringifiedData = JSON.stringify(payload);
    const encodedData = window.btoa(stringifiedData);
    console.log(
      `message being sent to native - ${stringifiedData} and encoded as - ${encodedData}`
    );
    if (window.WebViewInterface) {
      window.WebViewInterface.receiveString(stringifiedData);
    }
    if (window.android) {
      window.android.__externalCall(encodedData);
    }
    if (window.__externalCall) {
      window.__externalCall(encodedData);
    }
    if (isIOS()) {
      if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.callback
      ) {
        window.webkit.messageHandlers.callback.postMessage(encodedData);
      }
    }
  }
};

export const changeHeaderFooterVisibility = (visibility: number) => {
  SendEventToApp({ type: "header_VISIBILITY", value: visibility });
  SendEventToApp({ type: "footer_VISIBILITY", value: visibility });
};
