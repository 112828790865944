import React, { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import NonTermRidersPage from "../../../../Page/Desktop/NonTerm/NonTermProposal/NonTermRidersPage";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { useNavigate } from "react-router-dom";
import {
  formatAmountToCurrency,
  formatNumberToLakhOrCrores,
} from "../../../../SupportingFiles/HelpingFunction";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { NON_TERM_SERVICES } from "../../../../Services/NonTerm/NonTermFormService";
import { DTONonTermRiderStatus } from "../../../../types/NonTerm/DTONonTermRidersStatus";
import { TNonTermRidersBenefitsResponse } from "../../../../types/NonTerm/TNonTermRidersBenefitsResponse";
import { NonTermSlice } from "../../../../Store/Slice_Reducer/NonTerm/NonTermSlice";
import { toast } from "react-toastify";
import { NON_TERM_ROUTES } from "../../../../Router/Path/NonTermRoute";
import MNonTermRidersBenefits from "../../../../Page/Mobile/NonTerm/MNonTermProposal/MNonTermRidersBenefits";
import { TRidersBenefitsResponse } from "../../../../types/NonTerm/TNonTermRidersBenifits";

const NonTermRidersContainer = () => {
  const dispatch = useAppDispatch();
  const {
    RIDERS_BENEFITS,
    SELECTED_QUOTE_DATA,
    QUOTE_FIELDS_DATA,
    ADD_FORM,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.NonTerm);
  const { NonTerm } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [tf002Data, setTf002Data] = useState<TDropdown[]>([]);
  const [tf003Data, setTf003Data] = useState<TDropdown[]>([]);
  const [tf004Data, setTf004Data] = useState<TDropdown[]>([]);
  const [loaderTf002, setLoaderTf002] = useState<boolean>(false);
  const [loaderTf003, setLoaderTf003] = useState<boolean>(false);
  const [loaderTf004, setLoaderTf004] = useState<boolean>(false);

  useEffect(() => {
    const start_tf002 = 1000000;
    const end_tf002 = 30000000;

    let data_tf002: TDropdown[] = [];
    for (let i = start_tf002; i <= end_tf002; i += 1000000) {
      data_tf002.push({
        label: `${i}`,
        value: formatNumberToLakhOrCrores(i),
      });
    }

    setTf002Data(data_tf002);

    const start_tf003 = 1000000;
    const end_tf003 = 30000000;

    let data_tf003: TDropdown[] = [];
    for (let i = start_tf003; i <= end_tf003; i += 1000000) {
      data_tf003.push({
        label: `${i}`,
        value: `${formatNumberToLakhOrCrores(i)}`,
      });
    }

    setTf003Data(data_tf003);

    const start_tf004 = 1000000;
    const end_tf004 = 30000000;

    let data_tf004: TDropdown[] = [];
    for (let i = start_tf004; i <= end_tf004; i += 100000) {
      data_tf004.push({
        label: `${i}`,
        value: formatNumberToLakhOrCrores(i),
      });
    }

    setTf004Data(data_tf004);
  }, [RIDERS_BENEFITS]);

  useEffect(() => {
    setLoaderTf002(true);
    setLoaderTf003(true);
    setLoaderTf004(true);
    GET_RIDERS_PREMIUM();
  }, [
    RIDERS_BENEFITS.local_value.tf002Status,
    RIDERS_BENEFITS.local_value.tf002Value,
    RIDERS_BENEFITS.local_value.tf003Status,
    RIDERS_BENEFITS.local_value.tf003Value,
    RIDERS_BENEFITS.local_value.tf004Status,
    RIDERS_BENEFITS.local_value.tf004Value,
  ]);

  const GET_RIDERS_PREMIUM = () => {
    dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));

    const onSuccess = (data: any) => {
      setLoaderTf004(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response: TRidersBenefitsResponse = results.response;
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      if (!error) {
        // dispatch(NonTermSlice.actions.SET_RIDERS_BENEFITS_DATA(response));
      } else {
        toast.error("There are some technical error. Please contact to admin.");
      }
    };
    const onError = (err: any) => {
      setLoaderTf004(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      toast.error("There are some technical error. Please contact to admin.");
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };

    const data:any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_id: `${SELECTED_QUOTE_DATA.productDetails.id}`,
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      company_code: SELECTED_QUOTE_DATA.companyDetails.company_code,
      term: `${SELECTED_QUOTE_DATA.premiumDetails.term}`,
      pay_term: `${SELECTED_QUOTE_DATA.premiumDetails.pay_term}`,
      frequency: `${ADD_FORM.investmentMode.value}`,
      gender: ADD_FORM.gender.value,
      dob: ADD_FORM.age.value,
      pincode: ADD_FORM.pincode.value,
      accidentalDeathSelected: RIDERS_BENEFITS.local_value.tf003Status
        ? "Yes"
        : "No",
      accidentalDisSelected: RIDERS_BENEFITS.local_value.tf002Status
        ? "Yes"
        : "No",
      sum_assured: ADD_FORM.investAmount.value,
      wopSelected: RIDERS_BENEFITS.local_value.tf004Status ? "Yes" : "No",
      mobile: ADD_FORM.mobile.value,
      income_period: QUOTE_FIELDS_DATA.incomePeriod,
    };

    NON_TERM_SERVICES.NON_TERM_UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };

  const continue_function = () => {
    navigate(NON_TERM_ROUTES.BASIC_DETAILS);
  };

  return (
    <>
      {isMobile ? (
        <MNonTermRidersBenefits
          forward={continue_function}
          tf002Data={tf002Data}
          tf003Data={tf003Data}
          tf004Data={tf004Data}
          loaderTf002={loaderTf002}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
        />
      ) : (
        <NonTermRidersPage
          forward={continue_function}
          tf002Data={tf002Data}
          tf003Data={tf003Data}
          tf004Data={tf004Data}
          loaderTf002={loaderTf002}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
        />
      )}
    </>
  );
};

export default NonTermRidersContainer;
