import { PostAPI } from "../../Axios/AxiosInstanceToken";
import { TERM_URLS } from "../../URLCollection/Term/TernURLs";

const addTermFormData = (onSuccess: Function, onError: Function, dto: any) => {
  PostAPI.call(
    TERM_URLS.ADD_TERM_FORM,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const TERM_SERVICES = {
  addTermFormData,
};
